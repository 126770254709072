import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, notification, Select, Divider } from 'antd';
import { LaptopOutlined, FieldNumberOutlined } from '@ant-design/icons';
import { getAllSalesDocumentsApi } from '../../../../api/salesdocument';
import { getAccessTokenApi } from '../../../../api/auth';

import './AddSalesDocumentForm.scss';

export default function AddSalesDocumentForm(props) {
    const { setIsVisibleModalDocument, setSalesDocumentsSelected } = props;
    const [salesDocumentData, setSalesDocumentData] = useState({});
    const [documents, setDocuments] = useState({});

    const token = getAccessTokenApi();

    useEffect(() => {
        getAllSalesDocumentsApi(token)
            .then((response) => {
                if (response.salesdocuments) {
                    setDocuments(response.salesdocuments);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });
    }, [token]);

    useEffect(() => {
        setSalesDocumentData({ ...salesDocumentData, number: '0' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onlyNumbers(e) {
        const regex = /^[0-9]*$/;
        const r = regex.test(e);
        return r;
    }

    const addSalesDocument = (e) => {
        if (!salesDocumentData.sales_document_id) {
            notification['error']({ message: 'Seleccione documento de venta' });
            return;
        }
        if (!salesDocumentData.serie || salesDocumentData.serie.length !== 4) {
            notification['error']({ message: 'Ingrese una serie válida' });
            return;
        }
        if (
            !salesDocumentData.number ||
            salesDocumentData.number.length > 10 ||
            !onlyNumbers(salesDocumentData.number)
        ) {
            notification['error']({ message: 'Ingrese un número válida' });
            return;
        }

        setSalesDocumentsSelected(salesDocumentData);
        setIsVisibleModalDocument(false);
        setSalesDocumentData({});
    };

    return (
        <div className="add-sales-document-form">
            <AddForm
                salesDocumentData={salesDocumentData}
                setSalesDocumentData={setSalesDocumentData}
                addSalesDocument={addSalesDocument}
                documents={documents}
            />
        </div>
    );
}

function AddForm(props) {
    const { salesDocumentData, setSalesDocumentData, addSalesDocument, documents } = props;
    const { Option } = Select;

    function onChangeDocument(e) {
        for (let i = 0; i < documents.length; i++) {
            if (e === documents[i]._id) {
                setSalesDocumentData({
                    ...salesDocumentData,
                    sales_document_id: e,
                    sales_document_code: documents[i].code,
                    sales_document_name: documents[i].name,
                });
            }
        }
    }

    const childrenUser = [];
    for (let i = 0; documents && i < documents.length; i++) {
        childrenUser.push(<Option key={documents[i]._id}>{documents[i].name}</Option>);
    }

    return (
        <Form className="form-add" onFinish={addSalesDocument}>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item>
                        <Select
                            placeholder="Documento de venta"
                            onChange={onChangeDocument}
                            value={salesDocumentData.sales_document_id}
                            className="form-add_select"
                        >
                            {childrenUser}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={8} md={8} lg={8}>
                    <Form.Item>
                        <Input
                            className="form-add_input"
                            prefix={<LaptopOutlined />}
                            placeholder="Serie"
                            value={salesDocumentData.serie}
                            maxLength={4}
                            onChange={(e) => setSalesDocumentData({ ...salesDocumentData, serie: e.target.value })}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={16} md={16} lg={16}>
                    <Form.Item>
                        <Input
                            className="form-add_number"
                            prefix={<FieldNumberOutlined />}
                            placeholder="Número"
                            value={salesDocumentData.number}
                            maxLength={10}
                            onChange={(e) => setSalesDocumentData({ ...salesDocumentData, number: e.target.value })}
                            type="number"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <Form.Item>
                <Button type="primary" htmlType="submit" className="btn-submit">
                    Agregar documento de venta
                </Button>
            </Form.Item>
        </Form>
    );
}
