import React, { useState, useEffect } from 'react';
import { Row, Col, notification, Statistic } from 'antd';
import { getAccessTokenApi } from '../../../api/auth';
import { getSalesSummaryByDatesApi, getTotalProfitProductsByDatesApi } from '../../../api/sale';

import './SummarySales.scss';

export default function SummarySales(props) {
    const { searchType, dateSince, dateTo, reloadSales } = props;
    const [totals, setTotals] = useState({});

    const token = getAccessTokenApi();

    useEffect(() => {
        if (searchType === 1) {
            getSalesSummaryByDatesApi(token, formatDateSearch(dateSince), formatDateSearch(dateTo))
                .then((response) => {
                    if (response.success) {
                        setTotals(response.totals);
                    } else {
                        notification['error']({ message: response.message });
                    }
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });

            getTotalProfitProductsByDatesApi(token, formatDateSearch(dateSince), formatDateSearch(dateTo))
                .then((response) => {
                    if (response.success) {
                        //setTotals(response.totals);
                    } else {
                        notification['error']({ message: response.message });
                    }
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });
        } /*else if (searchType === 2) {
            getSalesByDocumentSaleApi(token, pagePagination, serieSaleDocument, numberSaleDocument)
                .then((response) => {
                    chargeSalesDocuments(response);
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });
        } else if (searchType === 3) {
            getSalesByClientApi(token, pagePagination, clientSelected._id)
                .then((response) => {
                    chargeSalesDocuments(response);
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });
        }*/
        //setReloadSales(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchType, reloadSales]);

    function formatDateSearch(date) {
        const args = date.split('/');
        return args[2] + args[1] + args[0];
    }

    return (
        <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={24} lg={24}>
                <div className="background_block">
                    <Row gutter={[16, 16]} style={{ textAlign: 'center' }}>
                        <Col xs={24} sm={12} md={6} lg={4}>
                            <Statistic
                                title="Boletas"
                                value={totals.total3?.toFixed(2)}
                                valueStyle={{ color: '#3f8600' }}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={4}>
                            <Statistic
                                title="Facturas"
                                value={totals.total1?.toFixed(2)}
                                valueStyle={{ color: '#3f8600' }}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={4}>
                            <Statistic
                                title="Proformas"
                                value={totals.total0?.toFixed(2)}
                                valueStyle={{ color: '#3f8600' }}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={4}>
                            <Statistic
                                title="Notas de crédito"
                                value={totals.total7?.toFixed(2)}
                                valueStyle={{ color: '#cf1322' }}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={4}>
                            <Statistic
                                title="Anulaciones"
                                value={totals.totalA?.toFixed(2)}
                                valueStyle={{ color: '#cf1322' }}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={4}>
                            <Statistic
                                title="Total"
                                value={totals.totalT?.toFixed(2)}
                                valueStyle={{ color: '#3f8600' }}
                            />
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    );
}
