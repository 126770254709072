import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, notification, Divider } from 'antd';
import { UnderlineOutlined } from '@ant-design/icons';
import { updateBrandApi } from '../../../../api/brand';
import { getAccessTokenApi } from '../../../../api/auth';

import './EditBrandForm.scss';

export default function EditBrandForm(props) {
    const { brand, setIsVisibleModal, setReloadBrands } = props;
    const [brandData, setBrandData] = useState({});
    const [disabledButton, setDisabledButton] = useState(false);

    useEffect(() => {
        setBrandData({
            name: brand.name,
        });
    }, [brand]);

    const updateBrand = (e) => {
        const token = getAccessTokenApi();
        let brandUpdate = brandData;

        if (!brandUpdate.name) {
            notification['error']({ message: 'El nombre obligatorio.' });
            return;
        }

        setDisabledButton(true);

        updateBrandApi(token, brandUpdate, brand._id)
            .then((response) => {
                if (response.ok) {
                    notification['success']({ message: response.message });
                    setIsVisibleModal(false);
                    setReloadBrands(true);
                    setBrandData({});
                } else {
                    notification['error']({ message: response.message });
                    setBrandData({});
                }
                setDisabledButton(false);
            })
            .catch((err) => {
                setDisabledButton(false);
                notification['error']({ message: err });
            });
    };

    return (
        <div className="edit-brand-form">
            <EditForm
                brandData={brandData}
                setBrandData={setBrandData}
                updateBrand={updateBrand}
                disabledButton={disabledButton}
            />
        </div>
    );
}

function EditForm(props) {
    const { brandData, setBrandData, updateBrand, disabledButton } = props;

    return (
        <Form className="form-edit" onFinish={updateBrand}>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item>
                        <Input
                            className="form-edit_input"
                            prefix={<UnderlineOutlined />}
                            placeholder="Nombre"
                            value={brandData.name}
                            onChange={(e) => setBrandData({ ...brandData, name: e.target.value })}
                            ref={(input) => input && input.focus()}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <Form.Item>
                <Button type="primary" htmlType="submit" disabled={disabledButton} className="btn-submit">
                    Modificar marca
                </Button>
            </Form.Item>
        </Form>
    );
}
