import React, { useState, useEffect } from 'react';
import { notification } from 'antd';
import { getAccessTokenApi } from '../../../api/auth';
import { getAllBrandsApi } from '../../../api/brand';
import ListBrands from '../../../components/Settings/Brands/ListBrands';

import './Brands.scss';

export default function Brands() {
    const [brands, setBrands] = useState([]);
    const [reloadBrands, setReloadBrands] = useState(false);
    const token = getAccessTokenApi();

    useEffect(() => {
        getAllBrandsApi(token)
            .then((response) => {
                if (response.brands) {
                    setBrands(response.brands);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });

        setReloadBrands(false);
    }, [token, reloadBrands]);

    return (
        <div className="brands">
            <ListBrands brands={brands} setReloadBrands={setReloadBrands} />
        </div>
    );
}
