import React, { useState, useEffect } from "react";
import { notification } from "antd";
import { getAccessTokenApi } from "../../../api/auth";
import { getAllBranchOfficesApi } from "../../../api/branchoffice";
import ListBranchOffices from "../../../components/Settings/BranchOffices/ListBranchOffices";

import "./BranchOffices.scss";

export default function BranchOffices() {
    const [branchOffices, setBranchOffices] = useState([]);
    const [reloadBranchOffices, setReloadBranchOffices] = useState(false);
    const token = getAccessTokenApi();

    useEffect(() => {
        getAllBranchOfficesApi(token)
            .then((response) => {
                if (response.success) {
                    setBranchOffices(response.branch_offices);
                } else {
                    notification["error"]({ message: response.message });
                }
            })
            .catch((err) => {
                notification["error"]({ message: err });
            });

        setReloadBranchOffices(false);
    }, [token, reloadBranchOffices]);

    return (
        <div className="branch-offices">
            <ListBranchOffices branchOffices={branchOffices} setReloadBranchOffices={setReloadBranchOffices} />
        </div>
    );
}
