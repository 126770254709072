import React from 'react';
import { Row, Col, DatePicker, Tooltip, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

import './SearchSalesStatistics.scss';

export default function SearchSalesStatistics(props) {
    const { dateSince, setDateSince, dateTo, setDateTo, setReloadSales } = props;

    const { RangePicker } = DatePicker;
    const dateFormat = 'DD/MM/YYYY';

    function getSalesByDates() {
        setReloadSales(true);
    }

    function onChnagrerangeDate(e) {
        setDateSince(e[0].format('DD/MM/YYYY'));
        setDateTo(e[1].format('DD/MM/YYYY'));
    }

    return (
        <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={24} lg={24}>
                <div className="background_block">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={24} lg={5}>
                            <h1>
                                <b>Buscar por Fecha de emisión:</b>
                            </h1>
                        </Col>
                        <Col xs={24} sm={20} md={20} lg={15}>
                            <RangePicker
                                format={dateFormat}
                                defaultValue={[moment(dateSince, dateFormat), moment(dateTo, dateFormat)]}
                                onChange={onChnagrerangeDate}
                                style={{ width: '100%' }}
                            />
                        </Col>
                        <Col xs={24} sm={4} md={4} lg={4}>
                            <Tooltip title="Buscar por fechas">
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<SearchOutlined />}
                                    onClick={getSalesByDates}
                                    style={{ float: 'right' }}
                                />
                            </Tooltip>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    );
}
