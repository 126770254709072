import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, notification, Divider } from 'antd';
import { UnderlineOutlined } from '@ant-design/icons';
import { updateUnitApi } from '../../../../api/unit';
import { getAccessTokenApi } from '../../../../api/auth';

import './EditUnitForm.scss';

export default function EditUnitForm(props) {
    const { unit, setIsVisibleModal, setReloadUnits } = props;
    const [unitData, setUnitData] = useState({});
    const [disabledButton, setDisabledButton] = useState(false);

    useEffect(() => {
        setUnitData({
            name: unit.name,
        });
    }, [unit]);

    const updateUnit = (e) => {
        const token = getAccessTokenApi();
        let unitUpdate = unitData;

        if (!unitUpdate.name) {
            notification['error']({ message: 'El nombre obligatorio' });
            return;
        }

        setDisabledButton(true);

        updateUnitApi(token, unitUpdate, unit._id)
            .then((response) => {
                if (response.ok) {
                    notification['success']({ message: response.message });
                    setIsVisibleModal(false);
                    setReloadUnits(true);
                    setUnitData({});
                } else {
                    notification['error']({ message: response.message });
                    setUnitData({});
                }
                setDisabledButton(false);
            })
            .catch((err) => {
                setDisabledButton(false);
                notification['error']({ message: err });
            });
    };

    return (
        <div className="edit-unit-form">
            <EditForm
                unitData={unitData}
                setUnitData={setUnitData}
                updateUnit={updateUnit}
                disabledButton={disabledButton}
            />
        </div>
    );
}

function EditForm(props) {
    const { unitData, setUnitData, updateUnit, disabledButton } = props;

    return (
        <Form className="form-edit" onFinish={updateUnit}>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item>
                        <Input
                            className="form-edit_input"
                            prefix={<UnderlineOutlined />}
                            placeholder="Nombre"
                            value={unitData.name}
                            onChange={(e) => setUnitData({ ...unitData, name: e.target.value })}
                            ref={(input) => input && input.focus()}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <Form.Item>
                <Button type="primary" htmlType="submit" disabled={disabledButton} className="btn-submit">
                    Modificar unidad
                </Button>
            </Form.Item>
        </Form>
    );
}
