import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, notification, Divider } from 'antd';
import { BarsOutlined } from '@ant-design/icons';
import { updateSubCategoryApi } from '../../../../api/subcategory';
import { getAccessTokenApi } from '../../../../api/auth';

import './EditSubCategoryForm.scss';

export default function EditSubCategoryForm(props) {
    const { subcategory, setIsVisibleModal, setReloadSubCategories } = props;
    const [subCategoryData, setSubCategoryData] = useState({});
    const [disabledButton, setDisabledButton] = useState(false);

    useEffect(() => {
        setSubCategoryData({
            name: subcategory.name,
            category_id: subcategory.category_id,
        });
    }, [subcategory]);

    const updateSubCategory = (e) => {
        const token = getAccessTokenApi();
        let subCategoryUpdate = subCategoryData;

        if (!subCategoryUpdate.name) {
            notification['error']({ message: 'El nombre obligatorio' });
            return;
        }

        setDisabledButton(true);

        updateSubCategoryApi(token, subCategoryUpdate, subcategory._id)
            .then((response) => {
                if (response.ok) {
                    notification['success']({ message: response.message });
                    setIsVisibleModal(false);
                    setReloadSubCategories(true);
                    setSubCategoryData({});
                } else {
                    notification['error']({ message: response.message });
                    setSubCategoryData({});
                }
                setDisabledButton(false);
            })
            .catch((err) => {
                setDisabledButton(false);
                notification['error']({ message: err });
            });
    };

    return (
        <div className="edit-subcategory-form">
            <EditForm
                subCategoryData={subCategoryData}
                setSubCategoryData={setSubCategoryData}
                updateSubCategory={updateSubCategory}
                disabledButton={disabledButton}
            />
        </div>
    );
}

function EditForm(props) {
    const { subCategoryData, setSubCategoryData, updateSubCategory, disabledButton } = props;

    return (
        <Form className="form-edit" onFinish={updateSubCategory}>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item>
                        <Input
                            className="form-edit_input"
                            prefix={<BarsOutlined />}
                            placeholder="Nombre"
                            value={subCategoryData.name}
                            onChange={(e) => setSubCategoryData({ ...subCategoryData, name: e.target.value })}
                            ref={(input) => input && input.focus()}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <Form.Item>
                <Button type="primary" htmlType="submit" disabled={disabledButton} className="btn-submit">
                    Modificar sub categoría
                </Button>
            </Form.Item>
        </Form>
    );
}
