import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, notification, Divider } from 'antd';
import { UnderlineOutlined } from '@ant-design/icons';
import { updatePresentationApi } from '../../../../api/presentation';
import { getAccessTokenApi } from '../../../../api/auth';

import './EditPresentationForm.scss';

export default function EditPresentationForm(props) {
    const { presentation, setIsVisibleModal, setReloadPresentations } = props;
    const [presentationData, setPresentationData] = useState({});
    const [disabledButton, setDisabledButton] = useState(false);

    useEffect(() => {
        setPresentationData({
            name: presentation.name,
        });
    }, [presentation]);

    const updatePresentation = (e) => {
        const token = getAccessTokenApi();
        let presentationUpdate = presentationData;

        if (!presentationUpdate.name) {
            notification['error']({ message: 'El nombre obligatorio' });
            return;
        }

        setDisabledButton(true);

        updatePresentationApi(token, presentationUpdate, presentation._id)
            .then((response) => {
                if (response.success) {
                    notification['success']({ message: response.message });
                    setIsVisibleModal(false);
                    setReloadPresentations(true);
                    setPresentationData({});
                } else {
                    notification['error']({ message: response.message });
                    setPresentationData({});
                }
                setDisabledButton(false);
            })
            .catch((err) => {
                setDisabledButton(false);
                notification['error']({ message: err });
            });
    };

    return (
        <div className="edit-presentation-form">
            <EditForm
                presentationData={presentationData}
                setPresentationData={setPresentationData}
                updatePresentation={updatePresentation}
                disabledButton={disabledButton}
            />
        </div>
    );
}

function EditForm(props) {
    const { presentationData, setPresentationData, updatePresentation, disabledButton } = props;

    return (
        <Form className="form-edit" onFinish={updatePresentation}>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item>
                        <Input
                            className="form-edit_input"
                            prefix={<UnderlineOutlined />}
                            placeholder="Nombre"
                            value={presentationData.name}
                            onChange={(e) => setPresentationData({ ...presentationData, name: e.target.value })}
                            ref={(input) => input && input.focus()}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <Form.Item>
                <Button type="primary" htmlType="submit" disabled={disabledButton} className="btn-submit">
                    Modificar presentación
                </Button>
            </Form.Item>
        </Form>
    );
}
