import React from 'react';
import { Layout } from 'antd';
import { Redirect } from 'react-router-dom';
import Logo from '../../assets/img/png/logo-inicio.png';
import LoginForm from '../../components/LoginForm/LoginForm';
import { getAccessTokenApi } from '../../api/auth';

import './SignIn.scss';

export default function SignIn() {
    const { Content } = Layout;

    if (getAccessTokenApi()) {
        return <Redirect to="/" />;
    }

    return (
        <Layout className="sign-in">
            <Content className="sign-in_content">
                <h1 className="sign-in_content-logo">
                    <img src={Logo} alt="logo" />
                </h1>
                <div className="sign-in_content-tabs">
                    <h2 className="sign-in_title"> INICIO DE SESIÓN </h2>
                    <LoginForm />
                </div>
            </Content>
        </Layout>
    );
}
