import React, { useState, useEffect } from 'react';
import { notification, Row, Col, Input, Checkbox, Button, Radio } from 'antd';
import {
    IdcardOutlined,
    FieldNumberOutlined,
    EnvironmentOutlined,
    HomeOutlined,
    MailOutlined,
    PhoneOutlined,
    ShopOutlined,
} from '@ant-design/icons';
import { getAccessTokenApi } from '../../../api/auth';
import { getCompanyApi, updateCompanyApi, updateCompanyConfigurationApi } from '../../../api/company';

import './Companies.scss';

const { TextArea } = Input;

export default function Companies() {
    const [companyData, setCompanyData] = useState({});
    const typePrintOptions = [
        { label: 'Imprimir por defecto en ticketera', value: '80' },
        { label: 'Imprimir por defecto en A4', value: 'A4' },
        { label: 'Imprimir por defecto en A5', value: 'A5' },
    ];

    const token = getAccessTokenApi();

    useEffect(() => {
        getCompanyApi(token)
            .then((response) => {
                if (response.success) {
                    setCompanyData(response.company);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });
    }, [token]);

    function onSaveDataCompany() {
        updateCompanyApi(token, companyData)
            .then((response) => {
                if (response.success) {
                    setCompanyData(response.company);
                    notification['success']({ message: 'Datos actualizados correctamente' });
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });
    }

    function onSaveDataConfiguration() {
        updateCompanyConfigurationApi(token, companyData)
            .then((response) => {
                if (response.success) {
                    setCompanyData(response.company);
                    notification['success']({ message: 'Configuración actualizada correctamente' });
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });
    }

    return (
        <div>
            <div className="background_block ">
                <Row gutter={[16, 8]}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <h1>
                            <b>Datos de mi negocio</b>
                        </h1>
                    </Col>
                </Row>
                <br />
                <Row gutter={[16, 8]}>
                    <Col xs={24} sm={8} md={6} lg={3}>
                        <span>Nombre comercial: *</span>
                    </Col>
                    <Col xs={24} sm={16} md={18} lg={8}>
                        <Input
                            prefix={<ShopOutlined />}
                            placeholder="Nombre comercial"
                            value={companyData.trade_name}
                            onChange={(e) =>
                                setCompanyData({ ...companyData, trade_name: e.target.value.toUpperCase() })
                            }
                            className="form-add_input"
                            disabled={true}
                        />
                    </Col>
                    <Col xs={24} sm={0} md={0} lg={2} />
                    <Col xs={24} sm={8} md={6} lg={3}>
                        <span>Nombre del negocio: *</span>
                    </Col>
                    <Col xs={24} sm={16} md={18} lg={8}>
                        <Input
                            prefix={<ShopOutlined />}
                            placeholder="Nombre del negocio"
                            value={companyData.business_name}
                            onChange={(e) => setCompanyData({ ...companyData, business_name: e.target.value })}
                            className="form-add_input"
                            disabled={true}
                        />
                    </Col>
                </Row>
                <br />
                <Row gutter={[16, 8]}>
                    <Col xs={24} sm={8} md={6} lg={3}>
                        <span>Tipo documento: *</span>
                    </Col>
                    <Col xs={24} sm={16} md={18} lg={8}>
                        <Input
                            prefix={<IdcardOutlined />}
                            placeholder="Tipo documento"
                            value={'RUC'}
                            className="form-add_input"
                            disabled={true}
                        />
                    </Col>
                    <Col xs={24} sm={0} md={0} lg={2} />
                    <Col xs={24} sm={8} md={6} lg={3}>
                        <span>Número documento: *</span>
                    </Col>
                    <Col xs={24} sm={16} md={18} lg={8}>
                        <Input
                            prefix={<FieldNumberOutlined />}
                            placeholder="Número documento"
                            value={companyData.number_doc}
                            onChange={(e) => setCompanyData({ ...companyData, number_doc: e.target.value })}
                            className="form-add_input"
                            disabled={true}
                        />
                    </Col>
                </Row>
                <br />
                <Row gutter={[16, 8]}>
                    <Col xs={24} sm={8} md={6} lg={3}>
                    <span>Correo electrónico:</span>
                    </Col>
                    <Col xs={24} sm={16} md={18} lg={8}>
                        <Input
                            prefix={<MailOutlined />}
                            placeholder="Correo electrónico"
                            value={companyData.email}
                            onChange={(e) => setCompanyData({ ...companyData, email: e.target.value })}
                            className="form-add_input"
                        />
                    </Col>
                    <Col xs={24} sm={0} md={0} lg={2} />
                    <Col xs={24} sm={8} md={6} lg={3}>
                    </Col>
                    <Col xs={24} sm={16} md={18} lg={8}>
                    </Col>
                </Row>
                <br />
                <Row gutter={[16, 8]}>
                    <Col xs={24} sm={12} md={15} lg={19} />
                    <Col xs={24} sm={12} md={9} lg={5}>
                        <Button type="primary" className="btn-submit" onClick={onSaveDataCompany}>
                            Modificar datos
                        </Button>
                    </Col>
                </Row>
            </div>
            <br />
            <div className="background_block ">
                <Row gutter={[16, 8]}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <h1>
                            <b>Configuración</b>
                        </h1>
                    </Col>
                </Row>
                <br />
                <Row gutter={[16, 8]}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Checkbox
                            onChange={(e) => setCompanyData({ ...companyData, stock_control: e.target.checked })}
                            checked={companyData.stock_control}
                        >
                            ¿Control de stock y almacén?
                        </Checkbox>
                        <Checkbox
                            onChange={(e) => setCompanyData({ ...companyData, use_unit_portion: e.target.checked })}
                            checked={companyData.use_unit_portion}
                        >
                            ¿Usa porción por unidad?
                        </Checkbox>
                        <br />
                        <br />
                        <Checkbox
                            onChange={(e) => setCompanyData({ ...companyData, sell_services: e.target.checked })}
                            checked={companyData.sell_services}
                        >
                            ¿Vende servicios?
                        </Checkbox>
                        <br />
                        <br />
                        <Radio.Group
                            options={typePrintOptions}
                            value={companyData.type_print}
                            onChange={(e) => setCompanyData({ ...companyData, type_print: e.target.value })}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 8]}>
                    <Col xs={24} sm={12} md={15} lg={19} />
                    <Col xs={24} sm={12} md={9} lg={5}>
                        <Button type="primary" className="btn-submit" onClick={onSaveDataConfiguration}>
                            Guardar configuración
                        </Button>
                    </Col>
                </Row>
            </div>
            <br />
            <div className="background_block ">
                <Row gutter={[16, 8]}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <h1>
                            <b>Pago</b>
                        </h1>
                    </Col>
                </Row>
                <br />
                <Row gutter={[16, 8]}>
                    <Col xs={24} sm={8} md={6} lg={3}>
                        <span>Próximo pago: *</span>
                    </Col>
                    <Col xs={24} sm={16} md={18} lg={8}>
                        <Input
                            prefix={<ShopOutlined />}
                            placeholder="Próximo pago"
                            value={companyData.next_pay}
                            className="form-add_input"
                            disabled={true}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
}
