import React from 'react';
import SalesReportDetail from '../../../components/Reports/SalesReportDetail/SalesReportDetail';

import './SalesReport.scss';

export default function SalesReport() {
    return (
        <div className="sales-report">
            <SalesReportDetail />
        </div>
    );
}
