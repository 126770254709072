import React, { useState } from 'react';
import { Form, Input, Button, notification } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { signInApi } from '../../api/user';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../../utils/constants';
import jwtDecode from 'jwt-decode';
import { logOut } from '../../api/auth';

import './LoginForm.scss';

export default function LoginForm() {
    const [inputs, setInputs] = useState({
        email: '',
        password: '',
    });

    const onChangeForm = (e) => {
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value,
        });
    };

    const onLogin = async (e) => {
        const result = await signInApi(inputs);
        if (!result) {
            notification['error']({ message: 'Error. No se pudo iniciar' });
        } else if (result.message) {
            notification['error']({ message: result.message });
        } else {
            const { accessToken, refreshToken } = result;
            localStorage.setItem(ACCESS_TOKEN, accessToken);
            localStorage.setItem(REFRESH_TOKEN, refreshToken);
            notification['success']({ message: 'Inicio correcto' });
            const user = jwtDecode(accessToken);
            if (user.role) {
                localStorage.setItem('title_page', 'Nueva venta');
                window.location.href = '/newsale';
            } else {
                logOut();
                window.location.reload();
            }
        }
    };

    return (
        <Form className="login-form" onChange={onChangeForm} onFinish={onLogin}>
            <Form.Item>
                <Input
                    prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="text"
                    name="account"
                    placeholder="Cuenta"
                    className="form-add_input"
                />
            </Form.Item>
            <Form.Item>
                <Input
                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    name="password"
                    placeholder="Contraseña"
                    className="form-add_input"
                />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" style={{ fontSize: '0em + 20px / $defaultFontSize' }} className="btn-submit">
                    Ingresar
                </Button>
            </Form.Item>
        </Form>
    );
}
