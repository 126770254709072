import React, { useState } from 'react';
import { List, Button, Row, Col } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import Modal from '../../../Modal';
import AddWarehuseForm from '../AddWarehuseForm';
import EditWarehuseForm from '../EditWarehuseForm';

import './ListWarehouses.scss';

export default function ListWarehouses(props) {
    const { warehouses, setReloadWarehouses } = props;
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState(null);

    const addWarehouseModal = () => {
        setIsVisibleModal(true);
        setModalTitle('Nuevo almacén');
        setModalContent(
            <AddWarehuseForm setIsVisibleModal={setIsVisibleModal} setReloadWarehouses={setReloadWarehouses} />
        );
    };

    function onCloseModal() {}

    return (
        <div className="list-warehouses">
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Warehouses
                        warehouses={warehouses}
                        setIsVisibleModal={setIsVisibleModal}
                        setModalTitle={setModalTitle}
                        setModalContent={setModalContent}
                        setReloadWarehouses={setReloadWarehouses}
                        addWarehouseModal={addWarehouseModal}
                    />
                </Col>
            </Row>

            <Modal
                title={modalTitle}
                isVisible={isVisibleModal}
                setIsVisible={setIsVisibleModal}
                onCloseModal={onCloseModal}
            >
                {modalContent}
            </Modal>
        </div>
    );
}

function Warehouses(props) {
    const { warehouses, setIsVisibleModal, setModalTitle, setModalContent, setReloadWarehouses, addWarehouseModal } =
        props;

    const editWarehouse = (warehouse) => {
        setIsVisibleModal(true);
        setModalTitle(`Modificar ${warehouse.name ? warehouse.name : ''}`);
        setModalContent(
            <EditWarehuseForm
                warehouse={warehouse}
                setIsVisibleModal={setIsVisibleModal}
                setReloadWarehouses={setReloadWarehouses}
            />
        );
    };

    return (
        <div>
            <div className="list-warehouses_headers">
                <div>
                    <h1>
                        <b>Almacenes</b>
                    </h1>
                </div>
                <Button className="button-action" type="primary" onClick={addWarehouseModal}>
                    <PlusOutlined />
                </Button>
            </div>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <List
                        className="warehouses"
                        itemLayout="horizontal"
                        dataSource={warehouses}
                        renderItem={(warehouse) => <Warehouse warehouse={warehouse} editWarehouse={editWarehouse} />}
                    />
                </Col>
            </Row>
        </div>
    );
}

function Warehouse(props) {
    const { warehouse, editWarehouse } = props;

    return (
        <List.Item
            actions={[
                <Button className="button-action" type="primary" onClick={() => editWarehouse(warehouse)}>
                    <EditOutlined />
                </Button>,
            ]}
        >
            <List.Item.Meta
                title={
                    <div>
                        <b>{warehouse.name}</b>
                        <br />
                        Sucursal:&emsp;
                        {warehouse.branch_office_name}
                    </div>
                }
            />
        </List.Item>
    );
}
