import React, { useState } from 'react';
import { Row, Col, Space } from 'antd';
import moment from 'moment';
import SearchPurchases from '../../../components/Purchases/SearchPurchases';
import HistoryDetailsPurchases from '../../../components/Purchases/HistoryDetailsPurchases';

import './PurchasesHistory.scss';

export default function PurchasesHistory() {
    const [providerSelected, setProviderSelected] = useState({});
    const [dateSince, setDateSince] = useState(moment().format('DD/MM/YYYY'));
    const [dateTo, setDateTo] = useState(moment().format('DD/MM/YYYY'));
    const [seriePurchaseDocument, setSeriePurchaseDocument] = useState('');
    const [numberPurchaseDocument, setNumberPurchaseDocument] = useState('');
    const [searchType, setSearchType] = useState(1);
    const [reloadPurchases, setReloadPurchases] = useState(false);

    return (
        <div>
            <div>
                <SearchPurchases
                    setProviderSelected={setProviderSelected}
                    dateSince={dateSince}
                    setDateSince={setDateSince}
                    dateTo={dateTo}
                    setDateTo={setDateTo}
                    seriePurchaseDocument={seriePurchaseDocument}
                    setSeriePurchaseDocument={setSeriePurchaseDocument}
                    numberPurchaseDocument={numberPurchaseDocument}
                    setNumberPurchaseDocument={setNumberPurchaseDocument}
                    setSearchType={setSearchType}
                    setReloadPurchases={setReloadPurchases}
                />
            </div>
            <Row gutter={[24, 24]}>
                <Space size={20} direction="vertical">
                    <div></div>
                    <div></div>
                </Space>
            </Row>

            <Row gutter={[24, 24]} className="sales_row-products">
                <Col xs={24} sm={24} md={24} lg={24}>
                    <HistoryDetailsPurchases
                        searchType={searchType}
                        dateSince={dateSince}
                        dateTo={dateTo}
                        seriePurchaseDocument={seriePurchaseDocument}
                        numberPurchaseDocument={numberPurchaseDocument}
                        providerSelected={providerSelected}
                        reloadPurchases={reloadPurchases}
                        setReloadPurchases={setReloadPurchases}
                    />
                </Col>
            </Row>
        </div>
    );
}
