import React, { useState } from 'react';
import { Row, Col, Space } from 'antd';
import moment from 'moment';
import SearchSales from '../../../components/Sales/SearchSales';
import HistoryDetailsSales from '../../../components/Sales/HistoryDetailsSales';

import './SalesHistory.scss';
import SummarySales from '../../../components/Sales/SummarySales/SummarySales';

export default function SalesHistory() {
    const [clientSelected, setClientSelected] = useState({});
    const [dateSince, setDateSince] = useState(moment().format('DD/MM/YYYY'));
    const [dateTo, setDateTo] = useState(moment().format('DD/MM/YYYY'));
    const [serieSaleDocument, setSerieSaleDocument] = useState('');
    const [numberSaleDocument, setNumberSaleDocument] = useState('');
    const [searchType, setSearchType] = useState(1);
    const [reloadSales, setReloadSales] = useState(false);

    return (
        <div>
            <div>
                <SearchSales
                    setClientSelected={setClientSelected}
                    dateSince={dateSince}
                    setDateSince={setDateSince}
                    dateTo={dateTo}
                    setDateTo={setDateTo}
                    serieSaleDocument={serieSaleDocument}
                    setSerieSaleDocument={setSerieSaleDocument}
                    numberSaleDocument={numberSaleDocument}
                    setNumberSaleDocument={setNumberSaleDocument}
                    setSearchType={setSearchType}
                    setReloadSales={setReloadSales}
                />
            </div>
            <Row gutter={[24, 24]}>
                <Space size={20} direction="vertical">
                    <div></div>
                    <div></div>
                </Space>
            </Row>
            <Row gutter={[24, 24]} className="sales_row-products">
                <Col xs={24} sm={24} md={24} lg={24}>
                    <SummarySales
                        searchType={searchType}
                        dateSince={dateSince}
                        dateTo={dateTo}
                        serieSaleDocument={serieSaleDocument}
                        numberSaleDocument={numberSaleDocument}
                        clientSelected={clientSelected}
                        reloadSales={reloadSales}
                        setReloadSales={setReloadSales}
                    />
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Space size={20} direction="vertical">
                    <div></div>
                    <div></div>
                </Space>
            </Row>
            <Row gutter={[24, 24]} className="sales_row-products">
                <Col xs={24} sm={24} md={24} lg={24}>
                    <HistoryDetailsSales
                        searchType={searchType}
                        dateSince={dateSince}
                        dateTo={dateTo}
                        serieSaleDocument={serieSaleDocument}
                        numberSaleDocument={numberSaleDocument}
                        clientSelected={clientSelected}
                        reloadSales={reloadSales}
                        setReloadSales={setReloadSales}
                    />
                </Col>
            </Row>
        </div>
    );
}
