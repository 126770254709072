import React, { useState, useEffect } from 'react';
import { AutoComplete, Row, Col, Button, Tooltip, notification, Input } from 'antd';
import {
    PlusOutlined,
    UserOutlined,
    EditOutlined,
    IdcardOutlined,
    FieldNumberOutlined,
    HomeOutlined,
    MailOutlined,
} from '@ant-design/icons';
import { getAccessTokenApi } from '../../../api/auth';
import { getClientsByNameApi, getClientById, getClientsByNumberDocApi } from '../../../api/client';
import AddClientForm from '../../Clients/AddClientForm';
import EditCLientForm from '../../Clients/EditClientForm';

import './ClientForm.scss';

export default function ClientForm(props) {
    const { setModalTitle, setIsVisibleModal, setModalContent, clientSelected, setClientSelected, reloadSale } = props;
    const [optionsClients, setOptionsClients] = useState([]);
    const [clientsResultSearch, setClientsResultSearch] = useState([]);
    const [clientFound, setClientFound] = useState({});
    const [textSearchClient, setTextSearchClient] = useState('');

    const token = getAccessTokenApi();

    useEffect(() => {
        setClientSelected({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadSale]);

    useEffect(() => {
        if (clientFound._id) {
            getClientById(token, clientFound._id)
                .then((response) => {
                    if (response && response.client) {
                        setClientSelected(response.client);
                    }
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, clientFound]);

    const onSelectClient = (data) => {
        setClientFound(
            clientsResultSearch.find(
                (client) =>
                    `${client.first_name} ${client.last_name} | ${
                        client.document_number ? client.document_number : ''
                    }` === data ||
                    `${client.entity_name} | ${client.document_number ? client.document_number : ''}` === data
            )
        );
        setOptionsClients([]);
        setTextSearchClient('');
    };

    const onSearchClient = (text) => {
        setTextSearchClient(text);
        if (text.length > 1) {
            const resultSearch = [];
            if (onlyNumbers(text) && (text.length === 8 || text.length === 11)) {
                getClientsByNumberDocApi(token, text)
                    .then((response) => {
                        setClientsResultSearch(response.clients);
                        for (let i = 0; response.clients && i < response.clients.length; i++) {
                            if (response.clients[i].first_name) {
                                resultSearch.push({
                                    value: `${response.clients[i].first_name} ${response.clients[i].last_name} | ${
                                        response.clients[i].document_number ? response.clients[i].document_number : ''
                                    }`,
                                });
                            } else if (response.clients[i].entity_name) {
                                resultSearch.push({
                                    value: `${response.clients[i].entity_name} | ${
                                        response.clients[i].document_number ? response.clients[i].document_number : ''
                                    }`,
                                });
                            }
                        }
                        setOptionsClients(!text ? [] : resultSearch);
                    })
                    .catch((err) => {
                        notification['error']({ message: err });
                    });
            } else {
                getClientsByNameApi(token, text)
                    .then((response) => {
                        setClientsResultSearch(response.clients);
                        for (let i = 0; response.clients && i < response.clients.length; i++) {
                            if (response.clients[i].first_name) {
                                resultSearch.push({
                                    value: `${response.clients[i].first_name} ${response.clients[i].last_name} | ${
                                        response.clients[i].document_number ? response.clients[i].document_number : ''
                                    }`,
                                });
                            } else if (response.clients[i].entity_name) {
                                resultSearch.push({
                                    value: `${response.clients[i].entity_name} | ${
                                        response.clients[i].document_number ? response.clients[i].document_number : ''
                                    }`,
                                });
                            }
                        }
                        setOptionsClients(!text ? [] : resultSearch);
                    })
                    .catch((err) => {
                        notification['error']({ message: err });
                    });
            }
        } else {
            setOptionsClients([]);
        }
    };

    const addClientModal = () => {
        setIsVisibleModal(true);
        setModalTitle('Nuevo cliente');
        setModalContent(
            <AddClientForm
                setIsVisibleModal={setIsVisibleModal}
                setClientSelected={setClientSelected}
                textSearchClient={textSearchClient}
            />
        );
    };

    const editClientModal = () => {
        setIsVisibleModal(true);
        setModalTitle('Modifica cliente');
        setModalContent(
            <EditCLientForm
                setIsVisibleModal={setIsVisibleModal}
                clientSelected={clientSelected}
                setClientSelected={setClientSelected}
            />
        );
    };

    const onFocusClientSearch = (e) => {
        e.target.select();
    };

    function onlyNumbers(e) {
        const regex = /^[0-9]*$/;
        const r = regex.test(e);
        return r;
    }

    return (
        <Col span={24}>
            <div className="background_block ">
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <h1>
                            <b>Cliente</b>
                        </h1>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={22} lg={8}>
                        <AutoComplete
                            prefix={<IdcardOutlined />}
                            options={optionsClients}
                            onSelect={onSelectClient}
                            onSearch={onSearchClient}
                            placeholder="Ingresar nombre, RUC o DNI de cliente"
                            className="search_input"
                            onFocus={onFocusClientSearch}
                            value={textSearchClient}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={14}>
                        <Input
                            prefix={<UserOutlined />}
                            placeholder="Nombre"
                            value={
                                clientSelected.entity_name
                                    ? clientSelected.entity_name
                                    : clientSelected.first_name
                                    ? `${clientSelected.first_name} ${clientSelected.last_name}`
                                    : ''
                            }
                            className="form-add_input"
                            disabled={true}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={2} lg={2}>
                        <Tooltip title="Nuevo cliente">
                            <Button
                                type="primary"
                                shape="circle"
                                icon={<PlusOutlined />}
                                onClick={addClientModal}
                                style={{ float: 'right' }}
                            />
                        </Tooltip>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12} md={12} lg={4}>
                        <Input
                            prefix={<IdcardOutlined />}
                            placeholder="Documento"
                            value={clientSelected.document_name}
                            className="form-add_input"
                            disabled={true}
                        />
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={4}>
                        <Input
                            prefix={<FieldNumberOutlined />}
                            placeholder="Número documento"
                            value={clientSelected.document_number}
                            className="form-add_input"
                            disabled={true}
                        />
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8}>
                        <Input
                            prefix={<HomeOutlined />}
                            placeholder="Dirección"
                            value={clientSelected.address}
                            className="form-add_input"
                            disabled={true}
                        />
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6}>
                        <Input
                            prefix={<MailOutlined />}
                            placeholder="Correo electrónico"
                            value={clientSelected.email}
                            className="form-add_input"
                            disabled={true}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={2}>
                        <Tooltip title="Nuevo cliente">
                            <Button
                                type="primary"
                                shape="circle"
                                icon={<EditOutlined />}
                                onClick={editClientModal}
                                style={{ float: 'right' }}
                            />
                        </Tooltip>
                    </Col>
                </Row>
            </div>
        </Col>
    );
}
