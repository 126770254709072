import React, { useState, useEffect } from 'react';
import { notification } from 'antd';
import { getAccessTokenApi } from '../../../api/auth';
import { getAllUnitsApi } from '../../../api/unit';
import ListUnits from '../../../components/Settings/Units/ListUnits';

import './Units.scss';

export default function Units() {
    const [units, setUnits] = useState([]);
    const [reloadUnits, setReloadUnits] = useState(false);
    const token = getAccessTokenApi();

    useEffect(() => {
        getAllUnitsApi(token)
            .then((response) => {
                if (response.units) {
                    setUnits(response.units);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });

        setReloadUnits(false);
    }, [token, reloadUnits]);

    return (
        <div className="units">
            <ListUnits units={units} setReloadUnits={setReloadUnits} />
        </div>
    );
}
