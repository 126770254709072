import React, { useState } from 'react';
import { notification, Row, Col, DatePicker, Button } from 'antd';
import { getAccessTokenApi } from '../../../api/auth';
import { getSalesReportExcelApi, getSalesReportExcelFileApi } from '../../../api/reports';
import { saveAs } from 'file-saver';

import './SalesReportDatail.scss';

export default function SalesReportDetail() {
    const [dateReport, setDateReport] = useState(null);

    const token = getAccessTokenApi();

    function onGenerateReport() {
        const date = dateReport.format('MM-YYYY');

        getSalesReportExcelApi(token, date)
            .then((response) => {
                if (response.success) {
                    const fileName = response.fileName;
                    getSalesReportExcelFileApi(fileName)
                        .then((response) => {
                            if (response.error) {
                                notification['error']({ message: response.message });
                            } else {
                                saveAs(response, fileName);
                            }
                        })
                        .catch((err) => {
                            notification['error']({ message: err });
                        });
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });
    }

    return (
        <div>
            <div className="background_block ">
                <Row gutter={[16, 8]}>
                    <Col xs={24} sm={8} md={6} lg={3}>
                        <span>Seleccionar periodo: *</span>
                    </Col>
                    <Col xs={24} sm={16} md={18} lg={8}>
                        <DatePicker
                            placeholder="MM-AAAA"
                            picker="month"
                            format={'MM-YYYY'}
                            value={dateReport}
                            onChange={(e) => setDateReport(e)}
                            style={{ textAlign: 'right', width: '100%' }}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 8]}>
                    <Col xs={24} sm={12} md={15} lg={19} />
                    <Col xs={24} sm={12} md={9} lg={5}>
                        <Button type="primary" className="btn-submit" onClick={onGenerateReport}>
                            Generar reporte
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
