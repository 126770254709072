import React, { useState } from 'react';
import { List, Button, Row, Col } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import Modal from '../../../Modal';
import AddCashRegisterForm from '../AddCashRegisterForm';
import EditCashRegisterForm from '../EditCashRegisterForm';

import './ListCashRegisters.scss';

export default function ListCashRegisters(props) {
    const { cashRegisters, setReloadCashRegisters } = props;

    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState(null);

    const addCashRegisterModal = () => {
        setIsVisibleModal(true);
        setModalTitle('Nueva caja');
        setModalContent(
            <AddCashRegisterForm
                setIsVisibleModal={setIsVisibleModal}
                setReloadCashRegisters={setReloadCashRegisters}
            />
        );
    };

    function onCloseModal() {}

    return (
        <div className="list-cash-registers">
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <CashRegisters
                        cashRegisters={cashRegisters}
                        setIsVisibleModal={setIsVisibleModal}
                        setModalTitle={setModalTitle}
                        setModalContent={setModalContent}
                        setReloadCashRegisters={setReloadCashRegisters}
                        addCashRegisterModal={addCashRegisterModal}
                    />
                </Col>
            </Row>

            <Modal
                title={modalTitle}
                isVisible={isVisibleModal}
                setIsVisible={setIsVisibleModal}
                onCloseModal={onCloseModal}
            >
                {modalContent}
            </Modal>
        </div>
    );
}

function CashRegisters(props) {
    const {
        cashRegisters,
        setIsVisibleModal,
        setModalTitle,
        setModalContent,
        setReloadCashRegisters,
        addCashRegisterModal,
    } = props;

    const editCashRegisterModal = (cashRegister) => {
        setIsVisibleModal(true);
        setModalTitle('Edita caja');
        setModalContent(
            <EditCashRegisterForm
                setIsVisibleModal={setIsVisibleModal}
                setReloadCashRegisters={setReloadCashRegisters}
                cashRegister={cashRegister}
            />
        );
    };

    return (
        <div>
            <div className="list-cash-registers_headers">
                <div>
                    <h1>
                        <b>Cajas y documentos actuales</b>
                    </h1>
                </div>
                <Button className="button-action" type="primary" onClick={addCashRegisterModal}>
                    <PlusOutlined />
                </Button>
            </div>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <List
                        className="cash-registers"
                        itemLayout="horizontal"
                        dataSource={cashRegisters}
                        renderItem={(cashRegister) => (
                            <CashRegister cashRegister={cashRegister} editCashRegisterModal={editCashRegisterModal} />
                        )}
                    />
                </Col>
            </Row>
        </div>
    );
}

function CashRegister(props) {
    const { cashRegister, editCashRegisterModal } = props;

    function leftFill(data, width) {
        var number = Math.abs(data);
        var length = number.toString().length;
        var zero = '0';

        return zero.repeat(width - length) + number.toString();
    }

    return (
        <List.Item
            actions={[
                <Button className="button-action" type="primary" onClick={() => editCashRegisterModal(cashRegister)}>
                    <EditOutlined />
                </Button>,
            ]}
        >
            <List.Item.Meta
                title={
                    <div>
                        <b>Caja:</b>&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {cashRegister.newCashRegister.name}
                        <br />
                        <b>Usuario:</b>&emsp;&emsp;&nbsp;&nbsp;
                        {cashRegister.newCashRegister.user_name}
                        <br />
                        <b>Sucursal:</b>&emsp;&emsp;&nbsp;
                        {cashRegister.newCashRegister.branch_office_name}
                        <br />
                        <br />
                        {cashRegister.documents.map((document) => {
                            return (
                                <div key={document._id}>
                                    <b>{document.sales_document_name}</b>
                                    <br />
                                    {`Serie:`}&emsp;&emsp;&ensp;
                                    {document.serie.toUpperCase()}
                                    <br />
                                    {`Número:`}&emsp;
                                    {leftFill(document.number, 10)}
                                </div>
                            );
                        })}
                    </div>
                }
            />
        </List.Item>
    );
}
