import React, { useState, useEffect } from 'react';
import { Row, Col, AutoComplete, notification, DatePicker, Tooltip, Button, Input } from 'antd';
import { SearchOutlined, LaptopOutlined, FieldNumberOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getAccessTokenApi } from '../../../api/auth';
import { getClientsByNameApi, getClientById } from '../../../api/client';

import './SearchSales.scss';

export default function SearchSales(props) {
    const {
        setClientSelected,
        dateSince,
        setDateSince,
        dateTo,
        setDateTo,
        serieSaleDocument,
        setSerieSaleDocument,
        numberSaleDocument,
        setNumberSaleDocument,
        setSearchType,
        setReloadSales,
    } = props;
    const [optionsClients, setOptionsClients] = useState([]);
    const [clientsResultSearch, setClientsResultSearch] = useState([]);
    const [clientFound, setClientFound] = useState({});
    const [textSearchClient, setTextSearchClient] = useState('');

    const token = getAccessTokenApi();
    const { RangePicker } = DatePicker;
    const dateFormat = 'DD/MM/YYYY';

    useEffect(() => {
        if (clientFound._id) {
            getClientById(token, clientFound._id)
                .then((response) => {
                    if (response && response.client) {
                        setClientSelected(response.client);
                        setTextSearchClient(
                            response.client.client_type === '1'
                                ? `${response.client.first_name} ${response.client.last_name}`
                                : `${response.client.entity_name}`
                        );
                        setSearchType(3);
                    }
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, clientFound]);

    const onSelectClient = (data) => {
        setClientFound(
            clientsResultSearch.find(
                (client) => `${client.first_name} ${client.last_name}` === data || `${client.entity_name}` === data
            )
        );
        setOptionsClients([]);
    };

    const onSearchClient = (text) => {
        setTextSearchClient(text);
        if (text.length > 1) {
            const resultSearch = [];
            getClientsByNameApi(token, text)
                .then((response) => {
                    setClientsResultSearch(response.clients);
                    for (let i = 0; response.clients && i < response.clients.length; i++) {
                        if (response.clients[i].first_name) {
                            resultSearch.push({
                                value: `${response.clients[i].first_name} ${response.clients[i].last_name}`,
                            });
                        } else if (response.clients[i].entity_name) {
                            resultSearch.push({ value: `${response.clients[i].entity_name}` });
                        }
                    }
                    setOptionsClients(!text ? [] : resultSearch);
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });
        } else {
            setOptionsClients([]);
        }
    };

    const onFocusClientSearch = (e) => {
        e.target.select();
    };

    function getSalesByDates() {
        setSearchType(1);
        setReloadSales(true);
    }

    function getSalesByDocument() {
        setSearchType(2);
        setReloadSales(true);
    }

    function getSalesByClient() {
        setSearchType(3);
        setReloadSales(true);
    }

    function onChnagrerangeDate(e) {
        setDateSince(e[0].format('DD/MM/YYYY'));
        setDateTo(e[1].format('DD/MM/YYYY'));
        setSearchType(1);
    }

    return (
        <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={12} lg={7}>
                <div className="background_block">
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <h1>
                                <b>Buscar por Fecha de emisión</b>
                            </h1>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={21} md={20} lg={20}>
                            <RangePicker
                                format={dateFormat}
                                defaultValue={[moment(dateSince, dateFormat), moment(dateTo, dateFormat)]}
                                onChange={onChnagrerangeDate}
                                style={{ width: '100%' }}
                            />
                        </Col>
                        <Col xs={24} sm={3} md={4} lg={4}>
                            <Tooltip title="Buscar por fechas">
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<SearchOutlined />}
                                    onClick={getSalesByDates}
                                    style={{ float: 'right' }}
                                />
                            </Tooltip>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col xs={0} sm={0} md={0} lg={0}></Col>
            <Col xs={24} sm={24} md={12} lg={8}>
                <div className="background_block">
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <h1>
                                <b>Buscar por Documento de venta</b>
                            </h1>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={8} sm={9} md={9} lg={9}>
                            <Input
                                className="form-add_input"
                                prefix={<LaptopOutlined />}
                                placeholder="Serie"
                                value={serieSaleDocument}
                                maxLength={4}
                                onChange={(e) => setSerieSaleDocument(e.target.value)}
                            />
                        </Col>
                        <Col xs={16} sm={12} md={12} lg={12}>
                            <Input
                                className="form-add_number"
                                prefix={<FieldNumberOutlined />}
                                placeholder="Número"
                                value={numberSaleDocument}
                                maxLength={10}
                                onChange={(e) => setNumberSaleDocument(e.target.value)}
                            />
                        </Col>
                        <Col xs={24} sm={3} md={3} lg={3}>
                            <Tooltip title="Buscar por documento">
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<SearchOutlined />}
                                    onClick={getSalesByDocument}
                                    style={{ float: 'right' }}
                                />
                            </Tooltip>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col xs={0} sm={0} md={0} lg={0}></Col>
            <Col xs={24} sm={24} md={24} lg={9}>
                <div className="background_block">
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <h1>
                                <b>Buscar por Cliente</b>
                            </h1>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={21} md={22} lg={21}>
                            <AutoComplete
                                options={optionsClients}
                                onSelect={onSelectClient}
                                onSearch={onSearchClient}
                                placeholder="Ingresar nombre, RUC o DNI de cliente"
                                className="search_input"
                                autoFocus
                                onFocus={onFocusClientSearch}
                                value={textSearchClient}
                            />
                        </Col>
                        <Col xs={24} sm={3} md={2} lg={3}>
                            <Tooltip title="Buscar por cliente">
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<SearchOutlined />}
                                    onClick={getSalesByClient}
                                    style={{ float: 'right' }}
                                />
                            </Tooltip>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    );
}
