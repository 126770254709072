import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, notification, Divider } from "antd";
import { ShopOutlined, FieldBinaryOutlined, EnvironmentOutlined, HomeOutlined, PhoneOutlined } from "@ant-design/icons";
import { registerBranchOfficeApi } from "../../../../api/branchoffice";
import { getAccessTokenApi } from "../../../../api/auth";

import "./AddBranchOfficeForm.scss";

const { TextArea } = Input;

export default function AddBranchOfficeForm(props) {
    const { setIsVisibleModal, setReloadBranchOffices } = props;
    const [branchOfficeData, setBranchOfficeData] = useState({});
    const [disabledButton, setDisabledButton] = useState(false);

    useEffect(() => {
        setBranchOfficeData({ ...branchOfficeData, name: "" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addBranchOffice = (e) => {
        const accessToken = getAccessTokenApi();

        if (!branchOfficeData.name) {
            notification["error"]({ message: "El nombre es obligatorio" });
            return;
        }

        if (!branchOfficeData.trade_name) {
            notification["error"]({ message: "El nombre comercial es obligatorio" });
            return;
        }

        if (!branchOfficeData.code) {
            notification["error"]({ message: "El código de sucursal es obligatorio" });
            return;
        }

        if (!branchOfficeData.location) {
            notification["error"]({ message: "La locación es obligatoria" });
            return;
        }

        if (!branchOfficeData.address) {
            notification["error"]({ message: "La dirección es obligatoria" });
            return;
        }

        setDisabledButton(true);

        registerBranchOfficeApi(accessToken, branchOfficeData)
            .then((response) => {
                if (response.success) {
                    notification["success"]({ message: response.message });
                    setIsVisibleModal(false);
                    setReloadBranchOffices(true);
                    setBranchOfficeData({});
                } else {
                    notification["error"]({ message: response.message });
                    setBranchOfficeData({});
                }
                setDisabledButton(false);
            })
            .catch((err) => {
                setDisabledButton(false);
                notification["error"]({ message: err });
            });
    };

    return (
        <div className="add-branch-office-form">
            <AddForm
                branchOfficeData={branchOfficeData}
                setBranchOfficeData={setBranchOfficeData}
                addBranchOffice={addBranchOffice}
                disabledButton={disabledButton}
            />
        </div>
    );
}

function AddForm(props) {
    const { branchOfficeData, setBranchOfficeData, addBranchOffice, disabledButton } = props;

    return (
        <Form className="form-add" onFinish={addBranchOffice}>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item>
                        <Input
                            className="form-add_input"
                            prefix={<ShopOutlined />}
                            placeholder="Nombre *"
                            value={branchOfficeData.name}
                            onChange={(e) => setBranchOfficeData({ ...branchOfficeData, name: e.target.value })}
                            // ref={(input) => input && input.focus()}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item>
                        <Input
                            className="form-add_input"
                            prefix={<ShopOutlined />}
                            placeholder="Nombre comercial *"
                            value={branchOfficeData.trade_name}
                            onChange={(e) => setBranchOfficeData({ ...branchOfficeData, trade_name: e.target.value })}
                            // ref={(input) => input && input.focus()}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item>
                        <Input
                            maxLength={4}
                            className="form-add_input"
                            prefix={<FieldBinaryOutlined />}
                            placeholder="Código sucursal *"
                            value={branchOfficeData.code}
                            onChange={(e) => setBranchOfficeData({ ...branchOfficeData, code: e.target.value })}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item>
                        <Input
                            prefix={<EnvironmentOutlined />}
                            placeholder="Locación *"
                            value={branchOfficeData.location}
                            onChange={(e) => setBranchOfficeData({ ...branchOfficeData, location: e.target.value })}
                            className="form-add_input"
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item>
                        <Input
                            prefix={<HomeOutlined />}
                            placeholder="Dirección *"
                            value={branchOfficeData.address}
                            onChange={(e) => setBranchOfficeData({ ...branchOfficeData, address: e.target.value })}
                            className="form-add_input"
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item>
                        <Input
                            prefix={<PhoneOutlined />}
                            placeholder="Teléfono"
                            value={branchOfficeData.cellphone}
                            onChange={(e) => setBranchOfficeData({ ...branchOfficeData, cellphone: e.target.value })}
                            className="form-add_input"
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item>
                        <TextArea
                            showCount
                            maxLength={250}
                            autoSize={{ minRows: 3, maxRows: 4 }}
                            placeholder="Descripción del negocio"
                            value={branchOfficeData.description}
                            onChange={(e) => setBranchOfficeData({ ...branchOfficeData, description: e.target.value })}
                            className="form-add_input"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <Form.Item>
                <Button type="primary" htmlType="submit" disabled={disabledButton} className="btn-submit">
                    Crear sucursal
                </Button>
            </Form.Item>
        </Form>
    );
}
