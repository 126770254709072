import { basePath } from './config';

export function getSalesReportExcelApi(token, date) {
    const url = `${basePath}/sales-report/${date}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}

export function getSalesReportExcelFileApi(file) {
    const url = `${basePath}/get-sales-report-excel/${file}`;

    console.log('url', url);

    return fetch(url)
        .then((response) => {
            return response.url;
        })
        .catch((err) => {
            return err.message;
        });
}
