import { basePath } from './config';

export function getMovementsByProductWarehouseDates(token, productId, warehouseId, dateSince, dateTo, page) {
    const url = `${basePath}/movements-by-product-warehouse-dates?product_id=${productId}&warehouse_id=${warehouseId}&date_since=${dateSince}&date_to=${dateTo}&page=${page}`;
    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}
