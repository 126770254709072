import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, notification, Divider, Checkbox } from 'antd';
import { UnderlineOutlined } from '@ant-design/icons';
import { updateWarehouseApi } from '../../../../api/warehouse';
import { getAccessTokenApi } from '../../../../api/auth';

import './EditWarehouseForm.scss';

export default function EditWarehouseForm(props) {
    const { warehouse, setIsVisibleModal, setReloadWarehouses } = props;
    const [warehouseData, setWarehouseData] = useState({});
    const [disabledButton, setDisabledButton] = useState(false);

    useEffect(() => {
        setWarehouseData({
            name: warehouse.name,
            is_sale: warehouse.is_sale,
            is_purchase: warehouse.is_purchase,
            branch_office_id: warehouse.branch_office_id,
            branch_office_name: warehouse.branch_office_name,
        });
    }, [warehouse]);

    const updateWarehouse = (e) => {
        const token = getAccessTokenApi();
        let warehouseUpdate = warehouseData;

        if (!warehouseUpdate.name) {
            notification['error']({ message: 'El nombre obligatorio' });
            return;
        }

        setDisabledButton(true);

        updateWarehouseApi(token, warehouseUpdate, warehouse._id)
            .then((response) => {
                if (response.success) {
                    notification['success']({ message: response.message });
                    setIsVisibleModal(false);
                    setReloadWarehouses(true);
                    setWarehouseData({});
                } else {
                    notification['error']({ message: response.message });
                    setWarehouseData({});
                }
                setDisabledButton(false);
            })
            .catch((err) => {
                setDisabledButton(false);
                notification['error']({ message: err });
            });
    };

    return (
        <div className="edit-warehouse-form">
            <EditForm
                warehouseData={warehouseData}
                setWarehouseData={setWarehouseData}
                updateWarehouse={updateWarehouse}
                disabledButton={disabledButton}
            />
        </div>
    );
}

function EditForm(props) {
    const { warehouseData, setWarehouseData, updateWarehouse, disabledButton } = props;

    return (
        <Form className="form-edit" onFinish={updateWarehouse}>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item>
                        <b>Sucursal:</b>&emsp;
                        {warehouseData.branch_office_name}
                        <br />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item>
                        <Input
                            className="form-edit_input"
                            prefix={<UnderlineOutlined />}
                            placeholder="Nombre"
                            value={warehouseData.name}
                            onChange={(e) => setWarehouseData({ ...warehouseData, name: e.target.value })}
                            ref={(input) => input && input.focus()}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item>
                        <Row style={{ textAlign: 'center' }}>
                            <Col span={24}>
                                <Checkbox
                                    checked={warehouseData.is_sale}
                                    onChange={(e) => setWarehouseData({ ...warehouseData, is_sale: e.target.checked })}
                                >
                                    ¿Es de ventas?
                                </Checkbox>
                                <Checkbox
                                    checked={warehouseData.is_purchase}
                                    onChange={(e) =>
                                        setWarehouseData({ ...warehouseData, is_purchase: e.target.checked })
                                    }
                                >
                                    ¿Es de compras?
                                </Checkbox>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <Form.Item>
                <Button type="primary" htmlType="submit" disabled={disabledButton} className="btn-submit">
                    Modificar almacén
                </Button>
            </Form.Item>
        </Form>
    );
}
