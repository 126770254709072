import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Button, Row, Col, notification, Divider, Radio, Tooltip } from 'antd';
import {
    BarcodeOutlined,
    HomeOutlined,
    UserOutlined,
    PhoneOutlined,
    MailOutlined,
    FieldNumberOutlined,
    InsertRowRightOutlined,
    GlobalOutlined,
    SearchOutlined,
} from '@ant-design/icons';
import { addClientApi, getDataPersonByDocumentApi, getDataEntityByDocumentApi } from '../../../api/client';
import { getAllDocumentsClientApi } from '../../../api/documentclient';
import { getAccessTokenApi } from '../../../api/auth';
import { onlyNumbers } from '../../../utils/generalValidation';

import './AddClientForm.scss';

export default function AddClientForm(props) {
    const { setIsVisibleModal, setClientSelected, textSearchClient } = props;
    const [clientData, setClientData] = useState({});
    const [clientDocuments, setClientDocuments] = useState([]);
    const [disabledButton, setDisabledButton] = useState(false);

    const token = getAccessTokenApi();

    useEffect(() => {
        getAllDocumentsClientApi(token)
            .then((response) => {
                if (response.documentsclient) {
                    setClientDocuments(response.documentsclient);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });
    }, [token]);

    const registerClient = (e) => {
        if (clientData.client_type === '1') {
            if (!clientData.first_name || clientData.first_name.length <= 0) {
                notification['error']({ message: 'Debe ingresar nombres de persona' });
                return;
            }
        } else if (clientData.client_type === '2') {
            if (!clientData.entity_name || clientData.entity_name.length <= 0) {
                notification['error']({ message: 'Debe ingresar nombre de entidad' });
                return;
            }
            if (clientData.document_name !== 'RUC' && clientData.document_name !== 'Otros') {
                notification['error']({ message: 'Documento no válido para entidad' });
                return;
            }
        }

        if (!clientData.document_id) {
            notification['error']({ message: 'Debe seleccionar tipo documento' });
            return;
        }

        if (
            clientData.document_name === 'DNI' &&
            (!clientData.document_number || clientData.document_number.length !== 8)
        ) {
            notification['error']({ message: 'Ingresar número de DNI válido' });
            return;
        }

        if (
            clientData.document_name === 'RUC' &&
            (!clientData.document_number || clientData.document_number.length !== 11)
        ) {
            notification['error']({ message: 'Ingresar número de RUC válido' });
            return;
        }

        if (clientData.document_name === 'RUC' && (!clientData.address || clientData.address === '')) {
            notification['error']({ message: 'Debe ingresar dirección de entidad' });
            return;
        }

        if (
            clientData.document_name === 'DNI' &&
            clientData.document_number &&
            clientData.document_number.length > 0 &&
            clientData.document_number.length !== 8
        ) {
            notification['error']({ message: 'Ingresar número de DNI válido' });
            return;
        }

        setDisabledButton(true);

        addClientApi(token, clientData)
            .then((response) => {
                if (response.success) {
                    notification['success']({ message: response.message });
                    setClientSelected(response.client);
                    setIsVisibleModal(false);
                    setClientData({});
                } else if (response.warning) {
                    notification['warning']({ message: response.message });
                    setClientSelected(response.client);
                    setIsVisibleModal(false);
                    setClientData({});
                } else {
                    notification['error']({ message: response.message });
                }
                setDisabledButton(false);
            })
            .catch((err) => {
                setDisabledButton(false);
                notification['error']({ message: err.message });
            });
    };

    return (
        <div className="add-client">
            <AddForm
                clientData={clientData}
                setClientData={setClientData}
                clientDocuments={clientDocuments}
                registerClient={registerClient}
                disabledButton={disabledButton}
                textSearchClient={textSearchClient}
                token={token}
            />
        </div>
    );
}

function AddForm(props) {
    const { clientData, setClientData, clientDocuments, registerClient, disabledButton, textSearchClient, token } =
        props;
    const [clientTypeValue, setClientTypeValue] = useState(!clientData.client_type ? '1' : clientData.client_type);
    const [documentSelected, setDocumentSelected] = useState('');
    const [numberClientSeach, setNumberClientSeach] = useState('');

    const { Option } = Select;

    useEffect(() => {
        setClientData({ ...clientData, client_type: clientTypeValue, country_code: 'PE' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textSearchClient]);

    useEffect(() => {
        if (onlyNumbers(textSearchClient)) {
            setNumberClientSeach(textSearchClient);
            getDataClient(token, textSearchClient);
        } else {
            setNumberClientSeach('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, textSearchClient]);

    useEffect(() => {
        setClientTypeValue(!clientData.client_type ? '1' : clientData.client_type);
    }, [clientData.client_type]);

    useEffect(() => {
        for (let i = 0; clientDocuments && i < clientDocuments.length; i++) {
            if (clientTypeValue === '1') {
                if (clientDocuments[i].name === 'DNI') {
                    setClientData({
                        ...clientData,
                        document_id: clientDocuments[i]._id,
                        document_name: clientDocuments[i].name,
                    });
                    setDocumentSelected(clientDocuments[i].name);
                }
            } else if (clientTypeValue === '2') {
                if (clientDocuments[i].name === 'RUC') {
                    setClientData({
                        ...clientData,
                        document_id: clientDocuments[i]._id,
                        document_name: clientDocuments[i].name,
                    });
                    setDocumentSelected(clientDocuments[i].name);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientDocuments, clientTypeValue]);

    function searchByDocument() {
        if (onlyNumbers(numberClientSeach)) {
            setNumberClientSeach(numberClientSeach);
            getDataClient(token, numberClientSeach);
        }
    }

    function getDataClient(token, textSearchClient) {
        if (textSearchClient.length > 0 && textSearchClient.length !== 8 && textSearchClient.length !== 11) {
            notification['warning']({ message: 'Número documento no es DNI o RUC' });
        }
        if (textSearchClient.length === 8) {
            getDataPersonByDocumentApi(token, textSearchClient)
                .then((response) => {
                    if (response.success) {
                        setClientData({
                            ...clientData,
                            client_type: '1',
                            first_name: response.data.nombre1 + ' ' + response.data.nombre2,
                            last_name: response.data.apPrimero + ' ' + response.data.apSegundo,
                            document_id: '612a598cbc0eb4af78588347',
                            document_number: response.data.nroDocumento,
                            document_type: '1',
                            document_name: 'DNI',
                            address: '',
                            ubigee: '',
                            country_code: 'PE',
                        });
                    } else {
                        setClientData({
                            ...clientData,
                            client_type: '1',
                            first_name: '',
                            last_name: '',
                            document_id: '612a598cbc0eb4af78588347',
                            document_number: '',
                            document_type: '1',
                            document_name: 'DNI',
                            address: '',
                            ubigee: '',
                            country_code: 'PE',
                        });
                        notification['error']({ message: response.message });
                    }
                })
                .catch((err) => {
                    notification['error']({ message: err.message });
                });
        } else if (textSearchClient.length === 11) {
            getDataEntityByDocumentApi(token, textSearchClient)
                .then((response) => {
                    if (response.success) {
                        setClientData({
                            ...clientData,
                            client_type: '2',
                            entity_name: response.data.razonSocial,
                            document_id: '612a59ccbc0eb4af7858834b',
                            document_number: response.data.nroDocumento,
                            document_type: '6',
                            document_name: 'RUC',
                            address: response.data.direccion,
                            ubigee: response.data.codUbigeo,
                            country_code: 'PE',
                        });
                        if (response.data.dscEstado !== 'ACTIVO') {
                            notification['warning']({ message: 'Entidad no activa' });
                        }
                    } else {
                        setClientData({
                            ...clientData,
                            client_type: '2',
                            entity_name: '',
                            document_id: '612a59ccbc0eb4af7858834b',
                            document_number: '',
                            document_type: '6',
                            document_name: 'RUC',
                            address: '',
                            ubigee: '',
                            country_code: 'PE',
                        });
                        notification['error']({ message: response.message });
                    }
                })
                .catch((err) => {
                    notification['error']({ message: err.message });
                });
        }
    }

    function onChangeRadio(e) {
        let type = e.target.value;
        setClientTypeValue(type);

        if (type === '1') {
            setClientData({ ...clientData, entity_name: null, document_number: null, client_type: type });
        } else if (type === '2') {
            setClientData({
                ...clientData,
                first_name: null,
                last_name: null,
                document_number: null,
                client_type: type,
            });
        }
    }

    function onChangePhone(e) {
        let phone = e.target.value;

        if (phone.length === 12) {
            return;
        }

        if (phone.length === 3) {
            setClientData({ ...clientData, phone: phone + ' ' });
        } else if (phone.length === 7) {
            setClientData({ ...clientData, phone: phone + ' ' });
        } else {
            setClientData({ ...clientData, phone: phone });
        }
    }

    function onChangeDocumentNumber(e) {
        let number = e.target.value;

        if (documentSelected === 'DNI') {
            if (number.length > 8) {
                return;
            }
        } else if (documentSelected === 'RUC') {
            if (number.length > 11) {
                return;
            }
        }

        setClientData({ ...clientData, document_number: number });
    }

    function onChangeDocumentTypeNumber(e) {
        let document_name = '';
        for (let i = 0; clientDocuments && i < clientDocuments.length; i++) {
            if (e === clientDocuments[i]._id) {
                document_name = clientDocuments[i].name;
                setDocumentSelected(clientDocuments[i].name);
            }
        }

        setClientData({ ...clientData, document_id: e, document_number: null, document_name: document_name });
    }

    function onChangeUbigee(e) {
        let ubigee = e.target.value;
        if (ubigee.length > 6) {
            return;
        }
        setClientData({ ...clientData, ubigee: e.target.value });
    }

    function onChangeNumberClientSearch(e) {
        if (onlyNumbers(e.target.value)) {
            setNumberClientSeach(e.target.value);
        }
    }

    const childrenClientDocuments = [];
    for (let i = 0; clientDocuments && i < clientDocuments.length; i++) {
        childrenClientDocuments.push(<Option key={clientDocuments[i]._id}>{clientDocuments[i].name}</Option>);
    }

    return (
        <div>
            <Form className="form-add" onFinish={searchByDocument}>
                <Form.Item>
                    <Row gutter={24}>
                        <Col xs={24} sm={22} md={22} lg={22}>
                            <Input
                                prefix={<SearchOutlined />}
                                placeholder="Buscar documento"
                                value={numberClientSeach}
                                onChange={onChangeNumberClientSearch}
                                className="form-add_input"
                            />
                        </Col>
                        <Col xs={24} sm={2} md={2} lg={2}>
                            <Tooltip title="Buscar cliente">
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<SearchOutlined />}
                                    onClick={searchByDocument}
                                    style={{ float: 'right' }}
                                />
                            </Tooltip>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
            <Divider />
            <Form className="form-add" onFinish={registerClient}>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item>
                            <div className="form-add_switch">
                                <Radio.Group onChange={onChangeRadio} value={clientTypeValue}>
                                    <Radio value={'1'}>Persona</Radio>
                                    <Radio value={'2'}>Entidad</Radio>
                                </Radio.Group>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
                {clientTypeValue === '1' ? (
                    <Row gutter={24}>
                        <Col xs={24} sm={12} md={12} lg={12}>
                            <Form.Item>
                                <Input
                                    prefix={<UserOutlined />}
                                    placeholder="Nombres *"
                                    value={clientData.first_name}
                                    onChange={(e) => setClientData({ ...clientData, first_name: e.target.value })}
                                    className="form-add_input"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12}>
                            <Form.Item>
                                <Input
                                    prefix={<UserOutlined />}
                                    placeholder="Apellidos"
                                    value={clientData.last_name}
                                    onChange={(e) => setClientData({ ...clientData, last_name: e.target.value })}
                                    className="form-add_input"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                ) : (
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Item>
                                <Input
                                    prefix={<InsertRowRightOutlined />}
                                    placeholder="Nombre de entidad *"
                                    value={clientData.entity_name}
                                    onChange={(e) => setClientData({ ...clientData, entity_name: e.target.value })}
                                    className="form-add_input"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Row gutter={24}>
                    <Col xs={24} sm={12} md={12} lg={12}>
                        <Form.Item>
                            <Select
                                placeholder="Tipo documento *"
                                onChange={onChangeDocumentTypeNumber}
                                value={clientData.document_id}
                                className="form-add_select"
                            >
                                {childrenClientDocuments}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12}>
                        <Form.Item>
                            <Input
                                prefix={<FieldNumberOutlined />}
                                placeholder="Número documento *"
                                value={clientData.document_number}
                                onChange={onChangeDocumentNumber}
                                className="form-add_input"
                                type="number"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item>
                            <Input
                                prefix={<HomeOutlined />}
                                placeholder="Dirección"
                                value={clientData.address}
                                onChange={(e) => setClientData({ ...clientData, address: e.target.value })}
                                className="form-add_input"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} sm={12} md={12} lg={12}>
                        <Form.Item>
                            <Input
                                prefix={<MailOutlined />}
                                placeholder="Correo electrónico"
                                value={clientData.email}
                                onChange={(e) => setClientData({ ...clientData, email: e.target.value })}
                                className="form-add_input"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12}>
                        <Form.Item>
                            <Input
                                prefix={<PhoneOutlined />}
                                placeholder="Celular"
                                value={clientData.phone}
                                onChange={onChangePhone}
                                className="form-add_number"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} sm={12} md={12} lg={12}>
                        <Form.Item>
                            <Input
                                prefix={<BarcodeOutlined />}
                                placeholder="Ubigeo"
                                value={clientData.ubigee}
                                onChange={onChangeUbigee}
                                className="form-add_input"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12}>
                        <Form.Item>
                            <Input
                                prefix={<GlobalOutlined />}
                                placeholder="Código de país"
                                value={clientData.country_code}
                                onChange={(e) => setClientData({ ...clientData, country_code: e.target.value })}
                                className="form-add_number"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={disabledButton} className="btn-submit">
                        Registrar cliente
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}
