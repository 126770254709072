import React, { useState, useEffect } from 'react';
import { AutoComplete, Row, Col, Button, Tooltip, notification, Input, Table, Space, Divider, Checkbox } from 'antd';
import {
    PlusOutlined,
    IdcardOutlined,
    FieldNumberOutlined,
    DollarOutlined,
    DeleteOutlined,
    DropboxOutlined,
    UnderlineOutlined,
    ShopOutlined,
} from '@ant-design/icons';
import { getAccessTokenApi } from '../../../api/auth';
import { getProductsByNameApi } from '../../../api/sale';
import { getAllWarehousesApi } from '../../../api/warehouse';
import Modal from '../../Modal';
import AddProductForm from '../../Products/AddProductForm';
import { onlyNumbersDecimal } from '../../../utils/generalValidation';
import jwtDecode from 'jwt-decode';
import { useMediaQuery } from 'react-responsive';

import './ProductsSales.scss';

export default function ProductsSales(props) {
    const {
        products,
        setProducts,
        setReloadProducts,
        reloadSale,
        reloadPurchase,
        isSale,
        isSellServices,
        setIsSellServices,
        services,
        setServices,
    } = props;
    const [optionsProducts, setOptionsProducts] = useState([]);
    const [productsResultSearch, setProductsResultSearch] = useState([]);
    const [textSearch, setTextSearch] = useState('');
    const [warehouses, setWarehouses] = useState('');
    const [nameService, setNameService] = useState('');

    const [isVisibleModalProduct, setIsVisibleModalProduct] = useState(false);
    const [modalTitleProduct, setModalTitleProduct] = useState('');
    const [modalContentProduct, setModalContentProduct] = useState(null);

    const isMobile = useMediaQuery({ maxWidth: 425 });

    const token = getAccessTokenApi();
    const user = jwtDecode(token);

    useEffect(() => {
        setProducts([]);
        setServices([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadSale, reloadPurchase]);

    useEffect(() => {
        if (user.stock_control) {
            getAllWarehousesApi(token)
                .then((response) => {
                    if (response.success) {
                        setWarehouses(response.warehouses);
                    } else {
                        notification['error']({ message: response.message });
                    }
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });
        }
    }, [token, user.stock_control]);

    const onSelectProduct = (data) => {
        var productsTmp = products;
        for (let i = 0; i < productsResultSearch.length; i++) {
            var productTmp = {};

            if (
                `${productsResultSearch[i].name} | ${productsResultSearch[i].brand} | ${
                    productsResultSearch[i].stock_control && user.stock_control
                        ? productsResultSearch[i].presentation_form.presentation
                        : productsResultSearch[i].unit
                        ? productsResultSearch[i].unit
                        : ''
                }` === data
            ) {
                if (isSale) {
                    let warehouseSelected = {};
                    if (warehouses?.length > 0) {
                        warehouses.forEach((w1) => {
                            if (w1.is_sale && w1.branch_office_id === user.branch_office_id) {
                                productsResultSearch[i].warehouses?.forEach((w2) => {
                                    if (w1._id === w2.warehouse_id) {
                                        warehouseSelected = w2;
                                    }
                                });
                            }
                        });
                    }

                    productTmp = {
                        _id: productsResultSearch[i]._id,
                        key: productsResultSearch[i]._id,
                        name: productsResultSearch[i].name,
                        name_showed: !user.stock_control
                            ? productsResultSearch[i].name
                            : `${productsResultSearch[i].name} | ${productsResultSearch[i].brand}`,
                        brand: productsResultSearch[i].brand,
                        stock_control: productsResultSearch[i].stock_control,
                        unit: productsResultSearch[i].stock_control
                            ? productsResultSearch[i].presentation_form?.presentation
                            : productsResultSearch[i].unit,
                        price_sale: productsResultSearch[i].stock_control
                            ? productsResultSearch[i].presentation_form?.price_sale_presentation
                            : productsResultSearch[i].price_sale,
                        price_purchase: productsResultSearch[i].stock_control
                            ? productsResultSearch[i].presentation_form?.price_purchase_presentation
                            : productsResultSearch[i].price_purchase,
                        quantity: 1,
                        subtotal: productsResultSearch[i].stock_control
                            ? productsResultSearch[i].presentation_form?.price_sale_presentation
                                ? productsResultSearch[i].presentation_form?.price_sale_presentation
                                : 0
                            : productsResultSearch[i].price_sale
                            ? productsResultSearch[i].price_sale
                            : 0,
                        billeable: productsResultSearch[i].billeable,
                        unit_portion: productsResultSearch[i].presentation_form?.unit_portion,
                        stock_presentation_detail: {
                            stock_presentation: warehouseSelected.stock_presentation
                                ? parseFloat(warehouseSelected.stock_presentation)
                                : 0,
                            presentation_presentation: productsResultSearch[i].stock_control
                                ? productsResultSearch[i].presentation_form?.presentation
                                : productsResultSearch[i].unit,
                        },
                        stock_unit_portion_detail: {
                            stock_unit_portion: warehouseSelected.stock_unit_portion
                                ? parseFloat(warehouseSelected.stock_unit_portion)
                                : 0,
                            unit_presentation: productsResultSearch[i].presentation_form?.unit,
                        },
                        price_sale_presentation: productsResultSearch[i].stock_control
                            ? productsResultSearch[i].presentation_form?.price_sale_presentation
                            : productsResultSearch[i].price_sale,
                        price_sale_unit_portion:
                            productsResultSearch[i].stock_control &&
                            productsResultSearch[i].presentation_form?.price_sale_unit_portion
                                ? productsResultSearch[i].presentation_form?.price_sale_unit_portion
                                : 0,
                        quantity_presentation: productsResultSearch[i].presentation_form?.unit_portion > 1 ? 0 : 1,
                        quantity_unit_portion: 0,
                        warehouse_selected: warehouseSelected,
                    };
                } else {
                    let warehouseSelected = {};
                    if (warehouses?.length > 0) {
                        warehouses.forEach((w1) => {
                            if (w1.is_purchase && w1.branch_office_id === user.branch_office_id) {
                                productsResultSearch[i].warehouses?.forEach((w2) => {
                                    if (w1._id === w2.warehouse_id) {
                                        warehouseSelected = w2;
                                    }
                                });
                            }
                        });
                    }

                    productTmp = {
                        _id: productsResultSearch[i]._id,
                        key: productsResultSearch[i]._id,
                        name: productsResultSearch[i].name,
                        name_showed: !user.stock_control
                            ? productsResultSearch[i].name
                            : `${productsResultSearch[i].name} | ${productsResultSearch[i].brand}`,
                        brand: productsResultSearch[i].brand,
                        stock_control: productsResultSearch[i].stock_control,
                        unit: productsResultSearch[i].stock_control
                            ? productsResultSearch[i].presentation_form?.presentation
                            : productsResultSearch[i].unit,
                        price_sale: productsResultSearch[i].stock_control
                            ? productsResultSearch[i].presentation_form?.price_sale_presentation
                            : productsResultSearch[i].price_sale,
                        price_purchase: productsResultSearch[i].stock_control
                            ? productsResultSearch[i].presentation_form?.price_purchase_presentation
                            : productsResultSearch[i].price_purchase,
                        quantity: 1,
                        subtotal: productsResultSearch[i].stock_control
                            ? productsResultSearch[i].presentation_form?.price_purchase_presentation
                                ? productsResultSearch[i].presentation_form?.price_purchase_presentation
                                : 0
                            : productsResultSearch[i].price_purchase
                            ? productsResultSearch[i].price_purchase
                            : 0,
                        billeable: productsResultSearch[i].billeable,
                        unit_portion: productsResultSearch[i].presentation_form?.unit_portion,
                        stock_presentation_detail: {
                            stock_presentation: warehouseSelected.stock_presentation
                                ? parseFloat(warehouseSelected.stock_presentation)
                                : 0,
                            presentation_presentation: productsResultSearch[i].stock_control
                                ? productsResultSearch[i].presentation_form?.presentation
                                : productsResultSearch[i].unit,
                        },
                        stock_unit_portion_detail: {
                            stock_unit_portion: warehouseSelected.stock_unit_portion
                                ? parseFloat(warehouseSelected.stock_unit_portion)
                                : 0,
                            unit_presentation: productsResultSearch[i].presentation_form?.unit,
                        },
                        price_sale_presentation: productsResultSearch[i].stock_control
                            ? productsResultSearch[i].presentation_form?.price_sale_presentation
                            : productsResultSearch[i].price_sale,
                        price_sale_unit_portion: productsResultSearch[i].presentation_form?.price_sale_unit_portion,
                        price_purchase_presentation: productsResultSearch[i].stock_control
                            ? productsResultSearch[i].presentation_form?.price_purchase_presentation
                            : productsResultSearch[i].price_purchase,
                        price_purchase_unit_portion:
                            productsResultSearch[i].stock_control &&
                            productsResultSearch[i].presentation_form?.price_purchase_unit_portion
                                ? productsResultSearch[i].presentation_form?.price_purchase_unit_portion
                                : 0,
                        quantity_presentation: productsResultSearch[i].presentation_form?.unit_portion > 1 ? 0 : 1,
                        quantity_unit_portion: 0,
                    };
                }

                for (let j = 0; j < productsTmp.length; j++) {
                    if (productsTmp[j]._id === productsResultSearch[i]._id) {
                        notification['error']({ message: 'El producto ya se agregó' });
                        return;
                    }
                }

                productsTmp.push(productTmp);
                setTextSearch('');

                if (!productsResultSearch[i].billeable || !productsResultSearch[i].billeable.includes('Facturable')) {
                    notification['warning']({
                        message: 'El producto no tiene factura: ' + productsResultSearch[i].name,
                    });
                }
            }
        }
        setProducts(productsTmp);
        setReloadProducts(true);
        setOptionsProducts([]);
    };

    const onSearchProduct = (text) => {
        if (text.length > 1) {
            const resultSearch = [];
            getProductsByNameApi(token, text)
                .then((response) => {
                    setProductsResultSearch(response.products);
                    for (let i = 0; response.products && i < response.products.length; i++) {
                        if (response.products[i].stock_control && user.stock_control) {
                            resultSearch.push({
                                value: `${response.products[i].name} | ${response.products[i].brand} | ${response.products[i].presentation_form.presentation}`,
                            });
                        } else {
                            resultSearch.push({
                                value: `${response.products[i].name} | ${response.products[i].brand} | ${
                                    response.products[i].unit ? response.products[i].unit : ''
                                }`,
                            });
                        }
                    }
                    setOptionsProducts(!text ? [] : resultSearch);
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });
        } else {
            setOptionsProducts([]);
        }
        setTextSearch(text);
    };

    const addProductModal = () => {
        setIsVisibleModalProduct(true);
        setModalTitleProduct('Nuevo producto');
        setModalContentProduct(
            <AddProductForm setIsVisibleModalProduct={setIsVisibleModalProduct} setReloadProducts={setReloadProducts} />
        );
    };

    function onCloseModalProduct() {}

    function addService() {
        if (nameService?.trim().length === 0) {
            return;
        }

        var servicesTmp = services;
        var serviceTmp = {};

        if (isSale) {
            serviceTmp = {
                _id: services.length + 1,
                key: services.length + 1,
                name: capitalizeFirstLetter(nameService),
                name_showed: capitalizeFirstLetter(nameService),
                price_sale: 0,
                quantity: 1,
                subtotal: 0,
            };
        }

        servicesTmp.push(serviceTmp);
        setNameService('');

        setServices(servicesTmp);
        setReloadProducts(true);
    }

    function onChangeIsService(e) {
        setIsSellServices(e.target.checked);
        setProducts([]);
        setServices([]);
    }

    function capitalizeFirstLetter(name) {
        return name.charAt(0).toUpperCase() + name.slice(1);
    }

    return (
        <div className="background_block ">
            {user.sell_services && isSale ? (
                <Row gutter={[16, 16]} style={{ alignItems: 'flex-start' }}>
                    <Col xs={12} sm={14} md={16} lg={18}>
                        <h1>
                            <b>{isSellServices ? 'Servicios' : 'Productos'}</b>
                        </h1>
                    </Col>
                    <Col xs={12} sm={10} md={8} lg={6}>
                        <Checkbox
                            onChange={(e) => onChangeIsService(e)}
                            checked={isSellServices}
                            style={{ float: 'right' }}
                        >
                            Es servicio
                        </Checkbox>
                        <br />
                    </Col>
                </Row>
            ) : (
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <h1>
                            <b>Productos</b>
                        </h1>
                    </Col>
                </Row>
            )}
            {isSellServices ? (
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={22} md={22} lg={22}>
                        <Input
                            prefix={<ShopOutlined />}
                            placeholder="Ingresar nombre de servicio"
                            value={nameService}
                            onChange={(e) => setNameService(e.target.value)}
                            className="form-add_input"
                        />
                    </Col>
                    <Col xs={24} sm={2} md={2} lg={2}>
                        <Tooltip title="Agregar servicio">
                            <Button
                                type="primary"
                                shape="circle"
                                icon={<PlusOutlined />}
                                onClick={addService}
                                style={{ float: 'right' }}
                            />
                        </Tooltip>
                    </Col>
                </Row>
            ) : (
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={22} md={22} lg={22}>
                        <AutoComplete
                            prefix={<IdcardOutlined />}
                            options={optionsProducts}
                            onSelect={onSelectProduct}
                            onSearch={onSearchProduct}
                            placeholder="Buscar nombre o código de producto"
                            className="search_input"
                            value={textSearch}
                            autoFocus
                        />
                    </Col>
                    <Col xs={24} sm={2} md={2} lg={2}>
                        <Tooltip title="Nuevo producto">
                            <Button
                                type="primary"
                                shape="circle"
                                icon={<PlusOutlined />}
                                onClick={addProductModal}
                                style={{ float: 'right' }}
                            />
                        </Tooltip>
                    </Col>
                </Row>
            )}

            <Row gutter={[24, 24]}>
                <Space size={20} direction="vertical">
                    <div></div>
                    <div></div>
                </Space>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <EditableTable />
                </Col>
            </Row>
            <Modal
                title={modalTitleProduct}
                isVisible={isVisibleModalProduct}
                setIsVisible={setIsVisibleModalProduct}
                onCloseModal={onCloseModalProduct}
            >
                {modalContentProduct}
            </Modal>
        </div>
    );

    function EditableTable(props) {
        const [tableData, setTableData] = useState(isSellServices ? services : products);

        useEffect(() => {
            const newData = [...tableData];
            for (let index = 0; index < tableData.length; index++) {
                setSubTotal(newData, index);
            }
            setTableData(newData);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const deleteProduc = (id) => {
            var position = -1;
            for (let i = 0; i < tableData.length; i++) {
                if (id === tableData[i]._id) {
                    position = i;
                }
            }

            if (position >= 0) {
                tableData.splice(position, 1);
            }

            if (isSellServices) {
                setServices(tableData);
            } else {
                setProducts(tableData);
            }

            setReloadProducts(true);
        };

        const onInputChange = (key, index) => (e) => {
            if (onlyNumbersDecimal(e.target.value)) {
                const newData = [...tableData];
                newData[index][key] = e.target.value;
                setSubTotal(newData, index);
                setTableData(newData);
            }
        };

        const onBlur = (key, index) => (e) => {
            const newData = [...tableData];
            newData[index][key] = newData[index][key] === '' ? 0 : newData[index][key];
            setSubTotal(newData, index);
            setTableData(newData);
            setReloadProducts(true);
        };

        const setSubTotal = (data, index) => {
            if (user.stock_control) {
                if (isSale) {
                    if (data[index]['quantity_unit_portion'] && data[index]['price_sale_unit_portion']) {
                        data[index]['subtotal'] =
                            data[index]['quantity_presentation'] * data[index]['price_sale_presentation'] +
                            data[index]['quantity_unit_portion'] * data[index]['price_sale_unit_portion'];
                    } else {
                        data[index]['subtotal'] =
                            data[index]['quantity_presentation'] * data[index]['price_sale_presentation'];
                    }
                } else {
                    if (data[index]['quantity_unit_portion'] && data[index]['price_purchase_unit_portion']) {
                        data[index]['subtotal'] =
                            data[index]['quantity_presentation'] * data[index]['price_purchase_presentation'] +
                            data[index]['quantity_unit_portion'] * data[index]['price_purchase_unit_portion'];
                    } else {
                        data[index]['subtotal'] =
                            data[index]['quantity_presentation'] * data[index]['price_purchase_presentation'];
                    }
                }
            } else {
                if (isSale) {
                    data[index]['subtotal'] = data[index]['quantity'] * data[index]['price_sale'];
                } else {
                    data[index]['subtotal'] = data[index]['quantity'] * data[index]['price_purchase'];
                }
            }
        };

        const onFocusQuantity = (e) => {
            e.target.select();
        };

        const onFocusPrice = (e) => {
            e.target.select();
        };

        const columns_without_stock_sale_service = [
            {
                title: 'Nombre',
                width: '50%',
                dataIndex: 'name_showed',
                key: 'name_showed',
                render: (name_showed) => <b>{name_showed}</b>,
            },
            {
                title: 'Cantidad',
                dataIndex: 'quantity',
                key: 'quantity',
                render: (quantity, record, index) => (
                    <div style={{ textAlign: 'center' }}>
                        <Input
                            prefix={<FieldNumberOutlined />}
                            style={{ width: '75px', textAlign: 'center' }}
                            value={quantity}
                            onChange={onInputChange('quantity', index)}
                            onFocus={onFocusQuantity}
                            onBlur={onBlur('quantity', index)}
                            className="form-add_input"
                        />
                    </div>
                ),
            },
            {
                title: 'Precio venta',
                dataIndex: 'price_sale',
                key: 'price_sale',
                render: (price_sale, record, index) => (
                    <div style={{ textAlign: 'center' }}>
                        <Input
                            prefix={<DollarOutlined />}
                            style={{ width: '110px', textAlign: 'right' }}
                            value={price_sale}
                            onChange={onInputChange('price_sale', index)}
                            onFocus={onFocusPrice}
                            onBlur={onBlur('price_sale', index)}
                            className="form-add_number"
                        />
                    </div>
                ),
            },
            {
                title: 'Sub Total',
                dataIndex: 'subtotal',
                key: 'subtotal',
                render: (subtotal) => (
                    <div style={{ textAlign: 'right' }}>
                        <b>{subtotal.toFixed(2)}</b>
                    </div>
                ),
            },
            {
                title: '',
                key: 'action',
                render: (text, record) => (
                    <Button
                        danger
                        type="primary"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => deleteProduc(record._id)}
                        style={{ float: 'right' }}
                    />
                ),
            },
        ];

        const columns_without_stock_purchase = [
            {
                title: 'Nombre',
                dataIndex: 'name_showed',
                key: 'name_showed',
                render: (name_showed) => <b>{name_showed}</b>,
            },
            {
                title: 'Marca',
                dataIndex: 'brand',
                key: 'brand',
            },
            {
                title: 'Unidad',
                dataIndex: 'unit',
                key: 'unit',
            },
            {
                title: 'Cantidad',
                dataIndex: 'quantity',
                key: 'quantity',
                render: (quantity, record, index) => (
                    <div style={{ textAlign: 'center' }}>
                        <Input
                            prefix={<FieldNumberOutlined />}
                            style={{ width: '75px', textAlign: 'center' }}
                            value={quantity}
                            onChange={onInputChange('quantity', index)}
                            onFocus={onFocusQuantity}
                            onBlur={onBlur('quantity', index)}
                            className="form-add_input"
                        />
                    </div>
                ),
            },
            {
                title: 'Precio compra',
                dataIndex: 'price_purchase',
                key: 'price_purchase',
                render: (price_purchase, record, index) => (
                    <div style={{ textAlign: 'center' }}>
                        <Input
                            prefix={<DollarOutlined />}
                            style={{ width: '110px', textAlign: 'right' }}
                            value={price_purchase}
                            onChange={onInputChange('price_purchase', index)}
                            onFocus={onFocusPrice}
                            onBlur={onBlur('price_purchase', index)}
                            className="form-add_number"
                        />
                    </div>
                ),
            },
            {
                title: 'Precio venta',
                dataIndex: 'price_sale',
                key: 'price_sale',
                render: (price_sale, record, index) => (
                    <div style={{ textAlign: 'center' }}>
                        <Input
                            prefix={<DollarOutlined />}
                            style={{ width: '110px', textAlign: 'right' }}
                            value={price_sale}
                            onChange={onInputChange('price_sale', index)}
                            onFocus={onFocusPrice}
                            onBlur={onBlur('price_sale', index)}
                            className="form-add_number"
                        />
                    </div>
                ),
            },
            {
                title: 'Sub Total',
                dataIndex: 'subtotal',
                key: 'subtotal',
                render: (subtotal) => (
                    <div style={{ textAlign: 'right' }}>
                        <b>{subtotal.toFixed(2)}</b>
                    </div>
                ),
            },
            {
                title: '',
                key: 'action',
                render: (text, record) => (
                    <Button
                        danger
                        type="primary"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => deleteProduc(record._id)}
                        style={{ float: 'right' }}
                    />
                ),
            },
        ];

        const columns_without_stock_sale = [
            {
                title: 'Nombre',
                dataIndex: 'name_showed',
                key: 'name_showed',
                render: (name_showed) => <b>{name_showed}</b>,
            },
            {
                title: 'Marca',
                dataIndex: 'brand',
                key: 'brand',
            },
            {
                title: 'Unidad',
                dataIndex: 'unit',
                key: 'unit',
            },
            {
                title: 'Cantidad',
                dataIndex: 'quantity',
                key: 'quantity',
                render: (quantity, record, index) => (
                    <div style={{ textAlign: 'center' }}>
                        <Input
                            prefix={<FieldNumberOutlined />}
                            style={{ width: '75px', textAlign: 'center' }}
                            value={quantity}
                            onChange={onInputChange('quantity', index)}
                            onFocus={onFocusQuantity}
                            onBlur={onBlur('quantity', index)}
                            className="form-add_input"
                        />
                    </div>
                ),
            },
            {
                title: 'Precio venta',
                dataIndex: 'price_sale',
                key: 'price_sale',
                render: (price_sale, record, index) => (
                    <div style={{ textAlign: 'center' }}>
                        <Input
                            prefix={<DollarOutlined />}
                            style={{ width: '110px', textAlign: 'right' }}
                            value={price_sale}
                            onChange={onInputChange('price_sale', index)}
                            onFocus={onFocusPrice}
                            onBlur={onBlur('price_sale', index)}
                            className="form-add_number"
                        />
                    </div>
                ),
            },
            {
                title: 'Sub Total',
                dataIndex: 'subtotal',
                key: 'subtotal',
                render: (subtotal) => (
                    <div style={{ textAlign: 'right' }}>
                        <b>{subtotal.toFixed(2)}</b>
                    </div>
                ),
            },
            {
                title: '',
                key: 'action',
                render: (text, record) => (
                    <Button
                        danger
                        type="primary"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => deleteProduc(record._id)}
                        style={{ float: 'right' }}
                    />
                ),
            },
        ];

        const columns_with_stock_purchase_with_up = [
            {
                title: 'Nombre',
                dataIndex: 'name_showed',
                key: 'name_showed',
                render: (name_showed) => <b>{name_showed}</b>,
            },
            {
                title: (
                    <div>
                        <DropboxOutlined />
                        &emsp;
                        <span>Stock</span>
                    </div>
                ),
                children: [
                    {
                        title: 'P',
                        dataIndex: 'stock_presentation_detail',
                        key: 'stock_presentation_detail',
                        width: 70,
                        render: (stock_presentation_detail) => (
                            <div>
                                <div style={{ textAlign: 'center' }}>
                                    {stock_presentation_detail.presentation_presentation
                                        ? stock_presentation_detail.presentation_presentation
                                        : '-'}
                                </div>
                                <Divider style={{ margin: '5px' }} />
                                <div style={{ textAlign: 'center' }}>
                                    {stock_presentation_detail.stock_presentation
                                        ? stock_presentation_detail.stock_presentation
                                        : '0'}
                                </div>
                            </div>
                        ),
                    },
                    {
                        title: 'U',
                        dataIndex: 'stock_unit_portion_detail',
                        key: 'stock_unit_portion_detail',
                        width: 70,
                        render: (stock_unit_portion_detail) => (
                            <div>
                                <div style={{ textAlign: 'center' }}>
                                    <b>
                                        {stock_unit_portion_detail.unit_presentation
                                            ? stock_unit_portion_detail.unit_presentation
                                            : ''}
                                    </b>
                                </div>
                                <Divider style={{ margin: '5px' }} />
                                <div style={{ textAlign: 'center' }}>
                                    <b>
                                        {stock_unit_portion_detail.stock_unit_portion !== null &&
                                        stock_unit_portion_detail.stock_unit_portion !== undefined
                                            ? stock_unit_portion_detail.stock_unit_portion.toFixed(1)
                                            : ''}
                                    </b>
                                </div>
                            </div>
                        ),
                    },
                ],
            },
            {
                title: (
                    <div>
                        <UnderlineOutlined />
                        <span>Porción por unidad</span>
                    </div>
                ),
                dataIndex: 'unit_portion',
                key: 'unit_portion',
                width: 90,
                render: (unit_portion) => (
                    <div style={{ textAlign: 'center' }}>{unit_portion ? unit_portion : '1'}</div>
                ),
            },
            {
                title: (
                    <div>
                        <FieldNumberOutlined />
                        &emsp;
                        <span>Cantidad</span>
                    </div>
                ),
                children: [
                    {
                        title: 'P',
                        dataIndex: 'quantity_presentation',
                        key: 'quantity_presentation',
                        render: (quantity_presentation, record, index) => (
                            <div style={{ textAlign: 'center' }}>
                                <Input
                                    //prefix={<FieldNumberOutlined />}
                                    style={{
                                        width: '55px',
                                        textAlign: 'center',
                                        paddingBottom: '5px',
                                        paddingTop: '5px',
                                        paddingLeft: '10px',
                                        paddingRight: '10px',
                                    }}
                                    value={quantity_presentation}
                                    onChange={onInputChange('quantity_presentation', index)}
                                    onFocus={onFocusQuantity}
                                    onBlur={onBlur('quantity_presentation', index)}
                                    className="form-add_input"
                                />
                            </div>
                        ),
                    },
                    {
                        title: 'U',
                        dataIndex: 'quantity_unit_portion',
                        key: 'quantity_unit_portion',
                        render: (quantity_unit_portion, record, index) =>
                            tableData[index].unit_portion > 1 && tableData[index].stock_control ? (
                                <div style={{ textAlign: 'center' }}>
                                    <Input
                                        //prefix={<FieldNumberOutlined />}
                                        style={{
                                            width: '55px',
                                            textAlign: 'center',
                                            paddingBottom: '5px',
                                            paddingTop: '5px',
                                            paddingLeft: '10px',
                                            paddingRight: '10px',
                                        }}
                                        value={quantity_unit_portion}
                                        onChange={onInputChange('quantity_unit_portion', index)}
                                        onFocus={onFocusQuantity}
                                        onBlur={onBlur('quantity_unit_portion', index)}
                                        className="form-add_input"
                                    />
                                </div>
                            ) : (
                                <div style={{ textAlign: 'center' }}>-</div>
                            ),
                    },
                ],
            },
            {
                title: (
                    <div>
                        <DollarOutlined />
                        &emsp;
                        <span>Precio compra</span>
                    </div>
                ),
                children: [
                    {
                        title: 'P',
                        dataIndex: 'price_purchase_presentation',
                        key: 'price_purchase_presentation',
                        render: (price_purchase_presentation, record, index) => (
                            <div style={{ textAlign: 'center' }}>
                                <Input
                                    //prefix={<DollarOutlined />}
                                    style={{
                                        width: '75px',
                                        textAlign: 'right',
                                        paddingBottom: '5px',
                                        paddingTop: '5px',
                                        paddingLeft: '10px',
                                        paddingRight: '10px',
                                    }}
                                    value={price_purchase_presentation}
                                    onChange={onInputChange('price_purchase_presentation', index)}
                                    onFocus={onFocusPrice}
                                    onBlur={onBlur('price_purchase_presentation', index)}
                                    className="form-add_number"
                                />
                            </div>
                        ),
                    },
                    {
                        title: 'U',
                        dataIndex: 'price_purchase_unit_portion',
                        key: 'price_purchase_unit_portion',
                        render: (price_purchase_unit_portion, record, index) =>
                            tableData[index].unit_portion > 1 && tableData[index].stock_control ? (
                                <div style={{ textAlign: 'center' }}>
                                    <Input
                                        //prefix={<DollarOutlined />}
                                        style={{
                                            width: '75px',
                                            textAlign: 'right',
                                            paddingBottom: '5px',
                                            paddingTop: '5px',
                                            paddingLeft: '10px',
                                            paddingRight: '10px',
                                        }}
                                        value={price_purchase_unit_portion}
                                        onChange={onInputChange('price_purchase_unit_portion', index)}
                                        onFocus={onFocusPrice}
                                        onBlur={onBlur('price_purchase_unit_portion', index)}
                                        className="form-add_number"
                                    />
                                </div>
                            ) : (
                                <div style={{ textAlign: 'center' }}>-</div>
                            ),
                    },
                ],
            },
            {
                title: (
                    <div>
                        <DollarOutlined />
                        &emsp;
                        <span>Precio venta</span>
                    </div>
                ),
                children: [
                    {
                        title: 'P',
                        dataIndex: 'price_sale_presentation',
                        key: 'price_sale_presentation',
                        render: (price_sale_presentation, record, index) => (
                            <div style={{ textAlign: 'center' }}>
                                <Input
                                    //prefix={<DollarOutlined />}
                                    style={{
                                        width: '75px',
                                        textAlign: 'right',
                                        paddingBottom: '5px',
                                        paddingTop: '5px',
                                        paddingLeft: '10px',
                                        paddingRight: '10px',
                                    }}
                                    value={price_sale_presentation}
                                    onChange={onInputChange('price_sale_presentation', index)}
                                    onFocus={onFocusPrice}
                                    onBlur={onBlur('price_sale_presentation', index)}
                                    className="form-add_number"
                                />
                            </div>
                        ),
                    },
                    {
                        title: 'U',
                        dataIndex: 'price_sale_unit_portion',
                        key: 'price_sale_unit_portion',
                        render: (price_sale_unit_portion, record, index) =>
                            tableData[index].unit_portion > 1 && tableData[index].stock_control ? (
                                <div style={{ textAlign: 'center' }}>
                                    <Input
                                        //prefix={<DollarOutlined />}
                                        style={{
                                            width: '75px',
                                            textAlign: 'right',
                                            paddingBottom: '5px',
                                            paddingTop: '5px',
                                            paddingLeft: '10px',
                                            paddingRight: '10px',
                                        }}
                                        value={price_sale_unit_portion}
                                        onChange={onInputChange('price_sale_unit_portion', index)}
                                        onFocus={onFocusPrice}
                                        onBlur={onBlur('price_sale_unit_portion', index)}
                                        className="form-add_number"
                                    />
                                </div>
                            ) : (
                                <div style={{ textAlign: 'center' }}>-</div>
                            ),
                    },
                ],
            },
            {
                title: 'Sub Total',
                dataIndex: 'subtotal',
                key: 'subtotal',
                render: (subtotal) => (
                    <div style={{ textAlign: 'right' }}>
                        <b>{subtotal.toFixed(2)}</b>
                    </div>
                ),
            },
            {
                title: '',
                key: 'action',
                render: (text, record) => (
                    <Button
                        danger
                        type="primary"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => deleteProduc(record._id)}
                        style={{ float: 'right' }}
                    />
                ),
            },
        ];

        const columns_with_stock_sale_with_up = [
            {
                title: 'Nombre',
                dataIndex: 'name_showed',
                key: 'name_showed',
                render: (name_showed) => <b>{name_showed}</b>,
            },
            {
                title: (
                    <div>
                        <DropboxOutlined />
                        &emsp;
                        <span>Stock</span>
                    </div>
                ),
                children: [
                    {
                        title: 'P',
                        dataIndex: 'stock_presentation_detail',
                        key: 'stock_presentation_detail',
                        width: 70,
                        render: (stock_presentation_detail) => (
                            <div>
                                <div style={{ textAlign: 'center' }}>
                                    {stock_presentation_detail.presentation_presentation
                                        ? stock_presentation_detail.presentation_presentation
                                        : '-'}
                                </div>
                                <Divider style={{ margin: '5px' }} />
                                <div style={{ textAlign: 'center' }}>
                                    <b>
                                        {stock_presentation_detail.stock_presentation
                                            ? stock_presentation_detail.stock_presentation
                                            : '0'}
                                    </b>
                                </div>
                            </div>
                        ),
                    },
                    {
                        title: 'U',
                        dataIndex: 'stock_unit_portion_detail',
                        key: 'stock_unit_portion_detail',
                        width: 70,
                        render: (stock_unit_portion_detail) => (
                            <div>
                                <div style={{ textAlign: 'center' }}>
                                    {stock_unit_portion_detail.unit_presentation
                                        ? stock_unit_portion_detail.unit_presentation
                                        : ''}
                                </div>
                                <Divider style={{ margin: '5px' }} />
                                <div style={{ textAlign: 'center' }}>
                                    <b>
                                        {stock_unit_portion_detail.stock_unit_portion !== null &&
                                        stock_unit_portion_detail.stock_unit_portion !== undefined
                                            ? stock_unit_portion_detail.stock_unit_portion.toFixed(1)
                                            : ''}
                                    </b>
                                </div>
                            </div>
                        ),
                    },
                ],
            },
            {
                title: (
                    <div>
                        <UnderlineOutlined />
                        <span>Porción por unidad</span>
                    </div>
                ),
                dataIndex: 'unit_portion',
                key: 'unit_portion',
                width: 90,
                render: (unit_portion) => (
                    <div style={{ textAlign: 'center' }}>{unit_portion ? unit_portion : '1'}</div>
                ),
            },
            {
                title: (
                    <div>
                        <FieldNumberOutlined />
                        &emsp;
                        <span>Cantidad</span>
                    </div>
                ),
                children: [
                    {
                        title: 'P',
                        dataIndex: 'quantity_presentation',
                        key: 'quantity_presentation',
                        render: (quantity_presentation, record, index) => (
                            <div style={{ textAlign: 'center' }}>
                                <Input
                                    //prefix={<FieldNumberOutlined />}
                                    style={{
                                        width: '55px',
                                        textAlign: 'center',
                                        paddingBottom: '5px',
                                        paddingTop: '5px',
                                        paddingLeft: '10px',
                                        paddingRight: '10px',
                                    }}
                                    value={quantity_presentation}
                                    onChange={onInputChange('quantity_presentation', index)}
                                    onFocus={onFocusQuantity}
                                    onBlur={onBlur('quantity_presentation', index)}
                                    className="form-add_input"
                                />
                            </div>
                        ),
                    },
                    {
                        title: 'U',
                        dataIndex: 'quantity_unit_portion',
                        key: 'quantity_unit_portion',
                        render: (quantity_unit_portion, record, index) =>
                            tableData[index].unit_portion > 1 && tableData[index].stock_control ? (
                                <div style={{ textAlign: 'center' }}>
                                    <Input
                                        //prefix={<FieldNumberOutlined />}
                                        style={{
                                            width: '55px',
                                            textAlign: 'center',
                                            paddingBottom: '5px',
                                            paddingTop: '5px',
                                            paddingLeft: '10px',
                                            paddingRight: '10px',
                                        }}
                                        value={quantity_unit_portion}
                                        onChange={onInputChange('quantity_unit_portion', index)}
                                        onFocus={onFocusQuantity}
                                        onBlur={onBlur('quantity_unit_portion', index)}
                                        className="form-add_input"
                                    />
                                </div>
                            ) : (
                                <div style={{ textAlign: 'center' }}>-</div>
                            ),
                    },
                ],
            },
            {
                title: (
                    <div>
                        <DollarOutlined />
                        &emsp;
                        <span>Precio venta</span>
                    </div>
                ),
                children: [
                    {
                        title: 'P',
                        dataIndex: 'price_sale_presentation',
                        key: 'price_sale_presentation',
                        render: (price_sale_presentation, record, index) => (
                            <div style={{ textAlign: 'center' }}>
                                <Input
                                    //prefix={<DollarOutlined />}
                                    style={{
                                        width: '75px',
                                        textAlign: 'right',
                                        paddingBottom: '5px',
                                        paddingTop: '5px',
                                        paddingLeft: '10px',
                                        paddingRight: '10px',
                                    }}
                                    value={price_sale_presentation}
                                    onChange={onInputChange('price_sale_presentation', index)}
                                    onFocus={onFocusPrice}
                                    onBlur={onBlur('price_sale_presentation', index)}
                                    className="form-add_number"
                                />
                            </div>
                        ),
                    },
                    {
                        title: 'U',
                        dataIndex: 'price_sale_unit_portion',
                        key: 'price_sale_unit_portion',
                        render: (price_sale_unit_portion, record, index) =>
                            tableData[index].unit_portion > 1 && tableData[index].stock_control ? (
                                <div style={{ textAlign: 'center' }}>
                                    <Input
                                        //prefix={<DollarOutlined />}
                                        style={{
                                            width: '75px',
                                            textAlign: 'right',
                                            paddingBottom: '5px',
                                            paddingTop: '5px',
                                            paddingLeft: '10px',
                                            paddingRight: '10px',
                                        }}
                                        value={price_sale_unit_portion}
                                        onChange={onInputChange('price_sale_unit_portion', index)}
                                        onFocus={onFocusPrice}
                                        onBlur={onBlur('price_sale_unit_portion', index)}
                                        className="form-add_number"
                                    />
                                </div>
                            ) : (
                                <div style={{ textAlign: 'center' }}>-</div>
                            ),
                    },
                ],
            },
            {
                title: 'Sub Total',
                dataIndex: 'subtotal',
                key: 'subtotal',
                render: (subtotal) => (
                    <div style={{ textAlign: 'right' }}>
                        <b>{subtotal.toFixed(2)}</b>
                    </div>
                ),
            },
            {
                title: '',
                key: 'action',
                render: (text, record) => (
                    <Button
                        danger
                        type="primary"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => deleteProduc(record._id)}
                        style={{ float: 'right' }}
                    />
                ),
            },
        ];

        const columns_with_stock_purchase_without_up = [
            {
                title: 'Nombre',
                dataIndex: 'name_showed',
                key: 'name_showed',
                render: (name_showed) => <b>{name_showed}</b>,
            },
            {
                title: (
                    <div>
                        <DropboxOutlined />
                        &ensp;
                        <span>Stock</span>
                    </div>
                ),
                dataIndex: 'stock_presentation_detail',
                key: 'stock_presentation_detail',
                width: 70,
                render: (stock_presentation_detail) => (
                    <div>
                        <div style={{ textAlign: 'center' }}>
                            {stock_presentation_detail.presentation_presentation
                                ? stock_presentation_detail.presentation_presentation
                                : '-'}
                        </div>
                        <Divider style={{ margin: '5px' }} />
                        <div style={{ textAlign: 'center' }}>
                            {stock_presentation_detail.stock_presentation
                                ? stock_presentation_detail.stock_presentation
                                : '0'}
                        </div>
                    </div>
                ),
            },
            {
                title: (
                    <div>
                        <FieldNumberOutlined />
                        &emsp;
                        <span>Cantidad</span>
                    </div>
                ),
                dataIndex: 'quantity_presentation',
                key: 'quantity_presentation',
                render: (quantity_presentation, record, index) => (
                    <div style={{ textAlign: 'center' }}>
                        <Input
                            //prefix={<FieldNumberOutlined />}
                            style={{
                                width: '90px',
                                textAlign: 'center',
                                paddingBottom: '5px',
                                paddingTop: '5px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                            }}
                            value={quantity_presentation}
                            onChange={onInputChange('quantity_presentation', index)}
                            onFocus={onFocusQuantity}
                            onBlur={onBlur('quantity_presentation', index)}
                            className="form-add_input"
                        />
                    </div>
                ),
            },
            {
                title: (
                    <div>
                        <DollarOutlined />
                        &emsp;
                        <span>Precio compra</span>
                    </div>
                ),
                dataIndex: 'price_purchase_presentation',
                key: 'price_purchase_presentation',
                render: (price_purchase_presentation, record, index) => (
                    <div style={{ textAlign: 'center' }}>
                        <Input
                            //prefix={<DollarOutlined />}
                            style={{
                                width: '100px',
                                textAlign: 'right',
                                paddingBottom: '5px',
                                paddingTop: '5px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                            }}
                            value={price_purchase_presentation}
                            onChange={onInputChange('price_purchase_presentation', index)}
                            onFocus={onFocusPrice}
                            onBlur={onBlur('price_purchase_presentation', index)}
                            className="form-add_number"
                        />
                    </div>
                ),
            },
            {
                title: (
                    <div>
                        <DollarOutlined />
                        &emsp;
                        <span>Precio venta</span>
                    </div>
                ),
                dataIndex: 'price_sale_presentation',
                key: 'price_sale_presentation',
                render: (price_sale_presentation, record, index) => (
                    <div style={{ textAlign: 'center' }}>
                        <Input
                            //prefix={<DollarOutlined />}
                            style={{
                                width: '100px',
                                textAlign: 'right',
                                paddingBottom: '5px',
                                paddingTop: '5px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                            }}
                            value={price_sale_presentation}
                            onChange={onInputChange('price_sale_presentation', index)}
                            onFocus={onFocusPrice}
                            onBlur={onBlur('price_sale_presentation', index)}
                            className="form-add_number"
                        />
                    </div>
                ),
            },
            {
                title: 'Sub Total',
                dataIndex: 'subtotal',
                key: 'subtotal',
                render: (subtotal) => (
                    <div style={{ textAlign: 'right' }}>
                        <b>{subtotal.toFixed(2)}</b>
                    </div>
                ),
            },
            {
                title: '',
                key: 'action',
                render: (text, record) => (
                    <Button
                        danger
                        type="primary"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => deleteProduc(record._id)}
                        style={{ float: 'right' }}
                    />
                ),
            },
        ];

        const columns_with_stock_sale_without_up = [
            {
                title: 'Nombre',
                dataIndex: 'name_showed',
                key: 'name_showed',
                render: (name_showed) => <b>{name_showed}</b>,
            },
            {
                title: (
                    <div>
                        <DropboxOutlined />
                        &ensp;
                        <span>Stock</span>
                    </div>
                ),
                dataIndex: 'stock_presentation_detail',
                key: 'stock_presentation_detail',
                width: 70,
                render: (stock_presentation_detail) => (
                    <div>
                        <div style={{ textAlign: 'center' }}>
                            {stock_presentation_detail.presentation_presentation
                                ? stock_presentation_detail.presentation_presentation
                                : '-'}
                        </div>
                        <Divider style={{ margin: '5px' }} />
                        <div style={{ textAlign: 'center' }}>
                            <b>
                                {stock_presentation_detail.stock_presentation
                                    ? stock_presentation_detail.stock_presentation
                                    : '0'}
                            </b>
                        </div>
                    </div>
                ),
            },
            {
                title: (
                    <div>
                        <FieldNumberOutlined />
                        &emsp;
                        <span>Cantidad</span>
                    </div>
                ),
                dataIndex: 'quantity_presentation',
                key: 'quantity_presentation',
                render: (quantity_presentation, record, index) => (
                    <div style={{ textAlign: 'center' }}>
                        <Input
                            //prefix={<FieldNumberOutlined />}
                            style={{
                                width: '90px',
                                textAlign: 'center',
                                paddingBottom: '5px',
                                paddingTop: '5px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                            }}
                            value={quantity_presentation}
                            onChange={onInputChange('quantity_presentation', index)}
                            onFocus={onFocusQuantity}
                            onBlur={onBlur('quantity_presentation', index)}
                            className="form-add_input"
                        />
                    </div>
                ),
            },
            {
                title: (
                    <div>
                        <DollarOutlined />
                        &emsp;
                        <span>Precio venta</span>
                    </div>
                ),
                dataIndex: 'price_sale_presentation',
                key: 'price_sale_presentation',
                render: (price_sale_presentation, record, index) => (
                    <div style={{ textAlign: 'center' }}>
                        <Input
                            //prefix={<DollarOutlined />}
                            style={{
                                width: '100px',
                                textAlign: 'right',
                                paddingBottom: '5px',
                                paddingTop: '5px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                            }}
                            value={price_sale_presentation}
                            onChange={onInputChange('price_sale_presentation', index)}
                            onFocus={onFocusPrice}
                            onBlur={onBlur('price_sale_presentation', index)}
                            className="form-add_number"
                        />
                    </div>
                ),
            },
            {
                title: 'Sub Total',
                dataIndex: 'subtotal',
                key: 'subtotal',
                render: (subtotal) => (
                    <div style={{ textAlign: 'right' }}>
                        <b>{subtotal.toFixed(2)}</b>
                    </div>
                ),
            },
            {
                title: '',
                key: 'action',
                render: (text, record) => (
                    <Button
                        danger
                        type="primary"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => deleteProduc(record._id)}
                        style={{ float: 'right' }}
                    />
                ),
            },
        ];

        return (
            <Table
                columns={
                    user.stock_control
                        ? isSale
                            ? user.use_unit_portion
                                ? columns_with_stock_sale_with_up
                                : columns_with_stock_sale_without_up
                            : user.use_unit_portion
                            ? columns_with_stock_purchase_with_up
                            : columns_with_stock_purchase_without_up
                        : isSale
                        ? isSellServices
                            ? columns_without_stock_sale_service
                            : columns_without_stock_sale
                        : columns_without_stock_purchase
                }
                dataSource={tableData}
                pagination={false}
                scroll={isMobile ? { x: '100vw' } : null}
            />
        );
    }
}
