import React, { useState } from 'react';
import { List, Button, Row, Col } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import Modal from '../../../Modal';
import AddBrandForm from '../AddBrandForm';
import EditBrandForm from '../EditBrandForm';

import './ListBrands.scss';

export default function ListBrands(props) {
    const { brands, setReloadBrands } = props;
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState(null);

    const addBrandModal = () => {
        setIsVisibleModal(true);
        setModalTitle('Nueva marca');
        setModalContent(<AddBrandForm setIsVisibleModal={setIsVisibleModal} setReloadBrands={setReloadBrands} />);
    };

    function onCloseModal() {}

    return (
        <div className="list-brands">
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Brands
                        brands={brands}
                        setIsVisibleModal={setIsVisibleModal}
                        setModalTitle={setModalTitle}
                        setModalContent={setModalContent}
                        setReloadBrands={setReloadBrands}
                        addBrandModal={addBrandModal}
                    />
                </Col>
            </Row>

            <Modal
                title={modalTitle}
                isVisible={isVisibleModal}
                setIsVisible={setIsVisibleModal}
                onCloseModal={onCloseModal}
            >
                {modalContent}
            </Modal>
        </div>
    );
}

function Brands(props) {
    const { brands, setIsVisibleModal, setModalTitle, setModalContent, setReloadBrands, addBrandModal } = props;

    const editBrand = (brand) => {
        setIsVisibleModal(true);
        setModalTitle(`Modificar ${brand.name ? brand.name : ''}`);
        setModalContent(
            <EditBrandForm brand={brand} setIsVisibleModal={setIsVisibleModal} setReloadBrands={setReloadBrands} />
        );
    };

    return (
        <div>
            <div className="list-brands_headers">
                <div>
                    <h1>
                        <b>Marcas</b>
                    </h1>
                </div>
                <Button className="button-action" type="primary" onClick={addBrandModal}>
                    <PlusOutlined />
                </Button>
            </div>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <List
                        className="brands"
                        itemLayout="horizontal"
                        dataSource={brands}
                        renderItem={(brand) => <Brand brand={brand} editBrand={editBrand} />}
                    />
                </Col>
            </Row>
        </div>
    );
}

function Brand(props) {
    const { brand, editBrand } = props;

    return (
        <List.Item
            actions={[
                <Button className="button-action" type="primary" onClick={() => editBrand(brand)}>
                    <EditOutlined />
                </Button>,
            ]}
        >
            <List.Item.Meta title={`${brand.name ? brand.name : ''}`} />
        </List.Item>
    );
}
