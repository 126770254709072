import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu } from 'antd';
import {
    HomeOutlined,
    ToolOutlined,
    DollarCircleOutlined,
    FileDoneOutlined,
    SettingOutlined,
    ShoppingCartOutlined,
    SwapOutlined,
    SnippetsOutlined,
} from '@ant-design/icons';
import './MenuSider.scss';
import Sider from 'antd/lib/layout/Sider';
import { getAccessTokenApi } from '../../api/auth';
import jwtDecode from 'jwt-decode';

const { SubMenu } = Menu;
const token = getAccessTokenApi();

function MenuSider(props) {
    const { menuCollapsed, location } = props;
    const user = jwtDecode(token);

    function setTitlePage(param) {
        localStorage.setItem('title_page', param);
    }

    return (
        <Sider className="menu-sider" collapsed={menuCollapsed}>
            <Menu theme="dark" mode="inline" defaultSelectedKeys={[location.pathname]} inlineCollapsed={menuCollapsed}>
                <Menu.Item key="/newsale1" icon={<HomeOutlined />}>
                    <Link className="a" to={'/newsale'} onClick={() => setTitlePage('Nueva venta')}>
                        Nueva venta
                    </Link>
                </Menu.Item>
                <Menu.Item key="/saleshisotry" icon={<DollarCircleOutlined />}>
                    <Link className="a" to={'/saleshisotry'} onClick={() => setTitlePage('Historial de ventas')}>
                        <span className="nav-text">Historial ventas</span>
                    </Link>
                </Menu.Item>
                {user.access_profit ? (
                    <Menu.Item key="/salesstatistics" icon={<FileDoneOutlined />}>
                        <Link
                            className="a"
                            to={'/salesstatistics'}
                            onClick={() => setTitlePage('Estadística de ventas')}
                        >
                            <span className="nav-text">Estadística ventas</span>
                        </Link>
                    </Menu.Item>
                ) : (
                    <div />
                )}
                <SubMenu key="compras" icon={<ShoppingCartOutlined />} title="Compras">
                    <Menu.Item key="/newpurchase">
                        <Link to={'/newpurchase'} onClick={() => setTitlePage('Nueva compra')}>
                            <span className="nav-text">Nueva compra</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/purchaseshistory">
                        <Link to={'/purchaseshistory'} onClick={() => setTitlePage('Historial de compras')}>
                            <span className="nav-text">Historial compras</span>
                        </Link>
                    </Menu.Item>
                </SubMenu>
                {user.stock_control ? (
                    <Menu.Item key="/movements" icon={<SwapOutlined />}>
                        <Link className="a" to={'/movements'} onClick={() => setTitlePage('Kardex')}>
                            <span className="nav-text">Kardex</span>
                        </Link>
                    </Menu.Item>
                ) : (
                    <div />
                )}
                <Menu.Item key="/products" icon={<ToolOutlined />}>
                    <Link className="a" to={'/products'} onClick={() => setTitlePage('Productos')}>
                        <span className="nav-text">Productos</span>
                    </Link>
                </Menu.Item>
                <SubMenu key="reportes" icon={<SnippetsOutlined />} title="Reportes">
                    <Menu.Item key="/salesreport">
                        <Link to={'/salesreport'} onClick={() => setTitlePage('Reporte de ventas')}>
                            <span className="nav-text">Reporte de ventas</span>
                        </Link>
                    </Menu.Item>
                </SubMenu>
                <SubMenu key="configuracion" icon={<SettingOutlined />} title="Configuración">
                    <Menu.Item key="/mycompany">
                        <Link to={'/mycompany'} onClick={() => setTitlePage('Configuración de mi negocio')}>
                            <span className="nav-text">Mi negocio</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/cashregisters">
                        <Link to={'/cashregisters'} onClick={() => setTitlePage('Configuración de cajas')}>
                            <span className="nav-text">Cajas</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/categories">
                        <Link to={'/categories'} onClick={() => setTitlePage('Configuración de categorías')}>
                            <span className="nav-text">Categorías</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/brands">
                        <Link to={'/brands'} onClick={() => setTitlePage('Configuración de marcas')}>
                            <span className="nav-text">Marcas</span>
                        </Link>
                    </Menu.Item>
                    {!user.stock_control || user.use_unit_portion ? (
                        <Menu.Item key="/units">
                            <Link to={'/units'} onClick={() => setTitlePage('Configuración de unidades')}>
                                <span className="nav-text">Unidades</span>
                            </Link>
                        </Menu.Item>
                    ) : (
                        <div />
                    )}
                    {user.stock_control ? (
                        <Menu.Item key="/presentations">
                            <Link
                                to={'/presentations'}
                                onClick={() => setTitlePage('Configuración de formas de presentación')}
                            >
                                <span className="nav-text">Presentación</span>
                            </Link>
                        </Menu.Item>
                    ) : (
                        <div />
                    )}
                    {user.stock_control ? (
                        <Menu.Item key="/warehouses">
                            <Link to={'/warehouses'} onClick={() => setTitlePage('Configuración de almacenes')}>
                                <span className="nav-text">Almacenes</span>
                            </Link>
                        </Menu.Item>
                    ) : (
                        <div />
                    )}
                    <Menu.Item key="/branchoffices">
                        <Link to={'/branchoffices'} onClick={() => setTitlePage('Configuración de sucursales')}>
                            <span className="nav-text">Sucursales</span>
                        </Link>
                    </Menu.Item>
                </SubMenu>
            </Menu>
        </Sider>
    );
}

export default withRouter(MenuSider);
