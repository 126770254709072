import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ConfigProvider } from 'antd';
import 'moment/locale/es';
//import 'antd/dist/reset.css';

import es_ES from 'antd/es/locale/es_ES';

ReactDOM.render(
    //<React.StrictMode>
    <ConfigProvider locale={es_ES}>
        <App />
    </ConfigProvider>,

    //</React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
