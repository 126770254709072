import { basePath } from './config';

export function registerPurchaseApi(token, purchase) {
    const url = `${basePath}/register-purchase`;

    const params = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(purchase),
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}

export function getPurchasesByDatesApi(token, page, date_since, date_to) {
    const url = `${basePath}/get-purchases-by-dates?page=${page}&date_since=${date_since}&date_to=${date_to}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function getPurchasesByDocumentPurchaseApi(token, page, serie, number) {
    const url = `${basePath}/get-purchases-by-document-purchase?page=${page}&serie=${serie}&number=${number}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function getPurchasesByProviderApi(token, page, provider) {
    const url = `${basePath}/get-purchases-by-provider?page=${page}&provider=${provider}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function annularPurchaseApi(token, purchaseId) {
    const url = `${basePath}/annular-purchase/${purchaseId}`;

    const parmas = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, parmas)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}
