import React, { useState, useEffect } from 'react';
import { List, Avatar, Button, Modal as ModalAntd, Divider, notification, Tooltip } from 'antd';
import {
    EditOutlined,
    StopOutlined,
    DeleteOutlined,
    CheckOutlined,
    PlusOutlined,
    ReloadOutlined,
    DropboxOutlined,
} from '@ant-design/icons';
import NoImage from '../../../assets/img/png/no-producto.png';
import Modal from '../../Modal';
import EditProductForm from '../EditProductForm';
import AddProductForm from '../AddProductForm';
import EditProductWarehouse from '../EditProductWarehouse';
import { getImageApi, activateProductApi, deleteProductApi } from '../../../api/products';
import { getAccessTokenApi } from '../../../api/auth';
import jwtDecode from 'jwt-decode';
import { useMediaQuery } from 'react-responsive';

import './ListProducts.scss';

const { confirm } = ModalAntd;

export default function ListProducts(props) {
    const {
        productsActives,
        setReloadProducts,
        pagePaginationActive,
        setPagePaginationActive,
        limitPaginationActive,
        totalPaginationActive,
        setSearchByDefault,
        setSearchByName,
        setSearchByCategory,
    } = props;
    const [isVisibleModalProduct, setIsVisibleModalProduct] = useState(false);
    const [isVisibleModalWarehouse, setIsVisibleModalWarehouse] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState(null);

    const isMobile = useMediaQuery({ maxWidth: 425 });

    console.log('COMPONENT LIST PRODUCT');

    const addProductModal = () => {
        setIsVisibleModalProduct(true);
        setModalTitle('Nuevo producto');
        setModalContent(
            <AddProductForm setIsVisibleModalProduct={setIsVisibleModalProduct} setReloadProducts={setReloadProducts} />
        );
    };

    const showDeleteConfirm = (product) => {
        const token = getAccessTokenApi();
        confirm({
            title: 'Eliminando producto',
            content: `¿Estás seguro que quieres eliminar ${product.name}?`,
            okText: 'Eliminar',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk() {
                deleteProductApi(token, product._id)
                    .then((response) => {
                        notification['success']({ message: response });
                        setReloadProducts(true);
                    })
                    .catch((err) => {
                        notification['error']({ message: err });
                    });
            },
        });
    };

    const reloadProducts = () => {
        setReloadProducts(true);
        setSearchByDefault(true);
        setSearchByName(false);
        setSearchByCategory(false);
    };

    function onCloseModal() {}

    return (
        <div className="list-products">
            <div className="list-products_headers">
                <Tooltip title="Actualizar">
                    <Button className="button-action" type="primary" onClick={reloadProducts}>
                        <ReloadOutlined />
                    </Button>
                </Tooltip>
                <Tooltip title="Agregar producto">
                    <Button className="button-action" type="primary" onClick={addProductModal}>
                        <PlusOutlined />
                    </Button>
                </Tooltip>
            </div>
            <ProductsActives
                productsActives={productsActives}
                setIsVisibleModalProduct={setIsVisibleModalProduct}
                setIsVisibleModalWarehouse={setIsVisibleModalWarehouse}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadProducts={setReloadProducts}
                showDeleteConfirm={showDeleteConfirm}
                pagePaginationActive={pagePaginationActive}
                setPagePaginationActive={setPagePaginationActive}
                limitPaginationActive={limitPaginationActive}
                totalPaginationActive={totalPaginationActive}
                isMobile={isMobile}
            />
            <Modal
                title={modalTitle}
                isVisible={isVisibleModalProduct}
                setIsVisible={setIsVisibleModalProduct}
                onCloseModal={onCloseModal}
            >
                {modalContent}
            </Modal>
            <Modal
                title={modalTitle}
                isVisible={isVisibleModalWarehouse}
                setIsVisible={setIsVisibleModalWarehouse}
                onCloseModal={onCloseModal}
            >
                {modalContent}
            </Modal>
        </div>
    );
}

function ProductsActives(props) {
    const {
        productsActives,
        setIsVisibleModalProduct,
        setIsVisibleModalWarehouse,
        setModalTitle,
        setModalContent,
        setReloadProducts,
        showDeleteConfirm,
        setPagePaginationActive,
        limitPaginationActive,
        totalPaginationActive,
        isMobile,
    } = props;

    const editProduct = (product) => {
        setIsVisibleModalProduct(true);
        setModalTitle(`Modificar ${product.name ? product.name : ''}`);
        setModalContent(
            <EditProductForm
                product={product}
                setIsVisibleModalProduct={setIsVisibleModalProduct}
                setReloadProducts={setReloadProducts}
            />
        );
    };

    const editProductWarehouse = (product) => {
        setIsVisibleModalWarehouse(true);
        setModalTitle(`Ajuste de stock de ${product.name ? product.name : ''}`);
        setModalContent(
            <EditProductWarehouse
                product={product}
                setIsVisibleModalWarehouse={setIsVisibleModalWarehouse}
                setReloadProducts={setReloadProducts}
            />
        );
    };

    return (
        <List
            className="products-active"
            pagination={{
                onChange: (pagePaginationActive) => {
                    setPagePaginationActive(pagePaginationActive);
                },
                pageSize: limitPaginationActive,
                total: totalPaginationActive,
            }}
            itemLayout="horizontal"
            dataSource={productsActives}
            renderItem={(product) => (
                <Product
                    product={product}
                    editProduct={editProduct}
                    editProductWarehouse={editProductWarehouse}
                    setReloadProducts={setReloadProducts}
                    showDeleteConfirm={showDeleteConfirm}
                    isMobile={isMobile}
                />
            )}
        />
    );
}

function Product(props) {
    const { product, editProduct, editProductWarehouse, setReloadProducts, showDeleteConfirm, isMobile } = props;
    const [image, setImage] = useState(null);

    const token = getAccessTokenApi();
    const user = jwtDecode(token);

    useEffect(() => {
        if (product.image) {
            getImageApi(product.image).then((response) => {
                setImage(response);
            });
        } else {
            setImage(null);
        }
    }, [product]);

    const desactivateProduct = () => {
        activateProductApi(token, product._id, false)
            .then((response) => {
                notification['success']({ message: response });
                setReloadProducts(true);
            })
            .catch((err) => {
                notification['error']({ message: err });
            });
    };

    const activateProduct = () => {
        activateProductApi(token, product._id, true)
            .then((response) => {
                notification['success']({ message: response });
                setReloadProducts(true);
            })
            .catch((err) => {
                notification['error']({ message: err });
            });
    };

    return (
        <div>
            <Divider />
            <List.Item
                actions={[
                    <Button className="button-action" type="primary" onClick={() => editProduct(product)}>
                        <EditOutlined />
                    </Button>,
                    product.stock_control ? (
                        <Button className="button-action" type="primary" onClick={() => editProductWarehouse(product)}>
                            <DropboxOutlined />
                        </Button>
                    ) : null,
                    product.active ? (
                        <Button className="button-action" type="danger" onClick={desactivateProduct}>
                            <StopOutlined />
                        </Button>
                    ) : (
                        <Button className="button-action" type="primary" onClick={activateProduct}>
                            <CheckOutlined />
                        </Button>
                    ),
                    <Button className="button-action" type="danger" onClick={() => showDeleteConfirm(product)}>
                        <DeleteOutlined />
                    </Button>,
                ]}
                style={isMobile ? { display: 'block' } : { display: 'flex' }}
            >
                <List.Item.Meta
                    avatar={<Avatar src={image ? image : NoImage} />}
                    title={
                        <div>
                            <table>
                                <tr>
                                    <td colSpan={2}>
                                        <b>
                                            {`${product.name ? product.name : ''} - 
                                            ${product.brand ? product.brand : ''} -
                                            ${
                                                product.stock_control && user.stock_control
                                                    ? product.presentation_form?.presentation
                                                    : product.unit
                                            }`}
                                        </b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{`Precio de compra:`} &emsp;</td>
                                    <td>
                                        {product.stock_control && user.stock_control
                                            ? `${
                                                  product.presentation_form.price_purchase_presentation
                                                      ? product.presentation_form.price_purchase_presentation.toFixed(2)
                                                      : ''
                                              } S/`
                                            : `${product.price_purchase ? product.price_purchase.toFixed(2) : ''} S/`}
                                    </td>
                                </tr>
                                <tr>
                                    <td>{`Precio de venta:`} &emsp;</td>
                                    <td>
                                        {product.stock_control && user.stock_control
                                            ? `${
                                                  product.presentation_form.price_sale_presentation
                                                      ? product.presentation_form.price_sale_presentation.toFixed(2)
                                                      : ''
                                              } S/`
                                            : `${product.price_sale ? product.price_sale.toFixed(2) : ''} S/`}
                                    </td>
                                </tr>
                                {product.stock_control && user.stock_control && user.use_unit_portion ? (
                                    <tr>
                                        <td>{`Porción por unidad:`} &emsp;</td>
                                        <td>
                                            {product.presentation_form?.unit_portion
                                                ? product.presentation_form.unit_portion
                                                : ''}
                                        </td>
                                    </tr>
                                ) : (
                                    ''
                                )}
                                {product.stock_control &&
                                user.stock_control &&
                                user.use_unit_portion &&
                                product.presentation_form.unit_portion > 1 ? (
                                    <tr>
                                        <td>{`Unidad:`} &emsp;</td>
                                        <td>{product.presentation_form.unit ? product.presentation_form.unit : ''}</td>
                                    </tr>
                                ) : (
                                    ''
                                )}
                                {product.stock_control &&
                                user.stock_control &&
                                user.use_unit_portion &&
                                product.presentation_form.unit_portion > 1 ? (
                                    <tr>
                                        <td>{`Precio venta unidad:`} &emsp;</td>
                                        <td>
                                            {product.presentation_form.price_sale_unit_portion
                                                ? product.presentation_form.price_sale_unit_portion.toFixed(2)
                                                : ''}{' '}
                                            S/
                                        </td>
                                    </tr>
                                ) : (
                                    ''
                                )}
                                <tr>
                                    <td colSpan={2}>
                                        <Divider style={{ margin: 0 }}></Divider>
                                    </td>
                                </tr>
                                {product.stock_control && user.stock_control
                                    ? product.warehouses.map((warehouse, i) => (
                                          <tr>
                                              <td>Stock {warehouse.name}: &emsp;</td>
                                              <td>
                                                  <span
                                                      style={{
                                                          color: warehouse.stock_presentation !== 0 ? 'green' : 'red',
                                                      }}
                                                  >
                                                      {warehouse.stock_presentation}
                                                  </span>
                                                  &ensp;
                                                  {warehouse.stock_unit_portion !== 0
                                                      ? product.presentation_form.presentation
                                                      : ''}
                                                  &emsp;
                                                  {warehouse.stock_unit_portion !== 0
                                                      ? warehouse.stock_unit_portion
                                                      : ''}
                                                  &ensp;
                                                  {warehouse.stock_unit_portion !== 0
                                                      ? product.presentation_form.unit
                                                      : ''}
                                              </td>
                                          </tr>
                                      ))
                                    : ''}
                                <tr>
                                    <td colSpan={2}>
                                        <Divider style={{ margin: 0 }}></Divider>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    }
                    description={product.category ? `${product.category} / ${product.sub_category}` : null}
                />
            </List.Item>
            {product.offer ? `En onferta: ${product.price_offer.toFixed(2)} S/.` : ''}
        </div>
    );
}
