import { basePath } from './config';

export function getAllBrandsApi(token) {
    const url = `${basePath}/get-all-brands`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function registerBrandApi(token, data) {
    const url = `${basePath}/register-brand`;
    const params = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(data),
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            if (result.brand_id) {
                return { ok: true, brand_id: result.brand_id, message: result.message };
            }
            return { ok: false, message: result.message };
        })
        .catch((err) => {
            return err.message;
        });
}

export function updateBrandApi(token, brand, brandId) {
    const url = `${basePath}/update-brand/${brandId}`;

    const parmas = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(brand),
    };

    return fetch(url, parmas)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            if (result.brand_id) {
                return { ok: true, brand_id: result.brand_id, message: result.message };
            }
            return { ok: false, message: result.message };
        })
        .catch((err) => {
            return err.message;
        });
}
