import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, notification, Divider } from 'antd';
import { UnderlineOutlined } from '@ant-design/icons';
import { registerUnitApi } from '../../../../api/unit';
import { getAccessTokenApi } from '../../../../api/auth';

import './AddUnitForm.scss';

export default function AddUnitForm(props) {
    const { setIsVisibleModal, setReloadUnits } = props;
    const [unitData, setUnitData] = useState({});
    const [disabledButton, setDisabledButton] = useState(false);

    useEffect(() => {
        setUnitData({ ...unitData, name: '' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addUnit = (e) => {
        const accessToken = getAccessTokenApi();
        if (!unitData.name) {
            notification['error']({ message: 'El nombre es obligatorio' });
            return;
        }

        setDisabledButton(true);

        registerUnitApi(accessToken, unitData)
            .then((response) => {
                if (response.ok) {
                    notification['success']({ message: response.message });
                    setIsVisibleModal(false);
                    setReloadUnits(true);
                    setUnitData({});
                } else {
                    notification['error']({ message: response.message });
                    setUnitData({});
                }
                setDisabledButton(false);
            })
            .catch((err) => {
                setDisabledButton(false);
                notification['error']({ message: err });
            });
    };

    return (
        <div className="add-unit-form">
            <AddForm unitData={unitData} setUnitData={setUnitData} addUnit={addUnit} disabledButton={disabledButton} />
        </div>
    );
}

function AddForm(props) {
    const { unitData, setUnitData, addUnit, disabledButton } = props;

    return (
        <Form className="form-add" onFinish={addUnit}>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item>
                        <Input
                            className="form-add_input"
                            prefix={<UnderlineOutlined />}
                            placeholder="Nombre"
                            value={unitData.name}
                            onChange={(e) => setUnitData({ ...unitData, name: e.target.value })}
                            ref={(input) => input && input.focus()}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <Form.Item>
                <Button type="primary" htmlType="submit" disabled={disabledButton} className="btn-submit">
                    Crear unidad
                </Button>
            </Form.Item>
        </Form>
    );
}
