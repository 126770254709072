import React, { useState } from 'react';
import { Row, Space } from 'antd';
import moment from 'moment';

import './Movements.scss';
import SearchMovements from '../../components/Movements/SearchMovements/SearchMovements';
import MovementsHistory from '../../components/Movements/MovementsHistory/MovementsHistory';

export default function Movements() {
    const [product, setProduct] = useState(null);
    const [dateSince, setDateSince] = useState(moment().add(-30, 'days').format('DD/MM/YYYY'));
    const [dateTo, setDateTo] = useState(moment().format('DD/MM/YYYY'));
    const [warehouse, setWarehouse] = useState(null);
    const [reloadMovements, setReloadMovements] = useState(false);

    return (
        <div>
            <div>
                <SearchMovements
                    setReloadMovements={setReloadMovements}
                    product={product}
                    setProduct={setProduct}
                    dateSince={dateSince}
                    setDateSince={setDateSince}
                    dateTo={dateTo}
                    setDateTo={setDateTo}
                    warehouse={warehouse}
                    setWarehouse={setWarehouse}
                />
            </div>
            <Row gutter={[24, 24]}>
                <Space size={20} direction="vertical">
                    <div></div>
                    <div></div>
                </Space>
            </Row>
            <div>
                <MovementsHistory
                    product={product}
                    reloadMovements={reloadMovements}
                    setReloadMovements={setReloadMovements}
                    dateSince={dateSince}
                    dateTo={dateTo}
                    warehouse={warehouse}
                />
            </div>
        </div>
    );
}
