import React from 'react';
import { Result, Button } from 'antd';

import './Error404.scss';

export default function Error404() {
    return (
        <Result
            status="404"
            title="404"
            subTitle="Disculpe, la página visitada no existe"
            extra={
                <a href="/">
                    <Button type="primary" className="error-404_button">
                        Volver al incio
                    </Button>
                </a>
            }
        />
    );
}
