import React from 'react';
import Companies from '../../../components/Settings/Companies';

import './Company.scss';

export default function Company() {
    return (
        <div className="company-page">
            <Companies />
        </div>
    );
}
