import React from 'react';
import { Modal as ModalAntd } from 'antd';

export default function Modal(props) {
    const { children, title, isVisible, setIsVisible, onCloseModal } = props;

    return (
        <ModalAntd
            title={title}
            centered
            open={isVisible}
            onCancel={() => setIsVisible(false)}
            footer={false}
            afterClose={() => onCloseModal()}
        >
            {children}
        </ModalAntd>
    );
}
