import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, notification, Select, Divider, Tooltip, List } from 'antd';
import { LaptopOutlined, PlusOutlined } from '@ant-design/icons';
import { getAllUsersByCompanyApi } from '../../../../api/user';
import { registerCashRegisterApi } from '../../../../api/cashregister';
import { getAllBranchOfficesActivesApi } from '../../../../api/branchoffice';
import { getAccessTokenApi } from '../../../../api/auth';
import Modal from '../../../Modal';
import AddSalesDocumentForm from '../AddSalesDocumentForm';

import './AddCashRegisterForm.scss';

export default function AddCashRegisterForm(props) {
    const { setIsVisibleModal, setReloadCashRegisters } = props;
    const [cashRegisterData, setCashRegisterData] = useState({});
    const [users, setUsers] = useState({});
    const [salesDocuments, setSalesDocuments] = useState([]);
    const [salesDocumentsSelected, setSalesDocumentsSelected] = useState(null);
    const [branchOffices, setBranchOffices] = useState({});

    const [isVisibleModalDocument, setIsVisibleModalDocument] = useState(false);
    const [modalTitleDocument, setModalTitleDocument] = useState('');
    const [modalContentDocument, setModalContentDocument] = useState(null);
    const [disabledButton, setDisabledButton] = useState(false);

    const token = getAccessTokenApi();

    useEffect(() => {
        getAllUsersByCompanyApi(token)
            .then((response) => {
                if (response.users) {
                    setUsers(response.users);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });

        getAllBranchOfficesActivesApi(token)
            .then((response) => {
                if (response.success) {
                    setBranchOffices(response.branch_offices);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });
    }, [token]);

    useEffect(() => {
        if (salesDocumentsSelected && salesDocumentsSelected.sales_document_id) {
            // for (let i = 0; i < salesDocuments.length; i++) {
            //     if (salesDocumentsSelected.sales_document_id === salesDocuments[i].sales_document_id) {
            //         notification['error']({ message: 'El tipo de documento ya se agregó' });
            //         return;
            //     }
            // }
            salesDocuments.push(salesDocumentsSelected);
            setSalesDocuments(salesDocuments);
            setCashRegisterData({ ...cashRegisterData, documents: salesDocuments });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salesDocumentsSelected]);

    const addCashRegister = (e) => {
        if (!cashRegisterData.name) {
            notification['error']({ message: 'Ingresar nombre de caja' });
            return;
        }
        if (!cashRegisterData.user_id) {
            notification['error']({ message: 'Seleccionar usuario' });
            return;
        }

        setDisabledButton(true);

        registerCashRegisterApi(token, cashRegisterData)
            .then((response) => {
                if (response.ok) {
                    notification['success']({ message: response.message });
                    setIsVisibleModal(false);
                    setReloadCashRegisters(true);
                    setCashRegisterData({});
                } else {
                    notification['error']({ message: response.message });
                }
                setDisabledButton(false);
            })
            .catch((err) => {
                setDisabledButton(false);
                notification['error']({ message: err });
            });
    };

    const addSalesDocumentModal = () => {
        setIsVisibleModalDocument(true);
        setModalTitleDocument('Nuevo documento de venta');
        setModalContentDocument(
            <AddSalesDocumentForm
                setIsVisibleModalDocument={setIsVisibleModalDocument}
                setSalesDocumentsSelected={setSalesDocumentsSelected}
            />
        );
    };

    function onCloseModalDocument() {}

    return (
        <div className="add-cash-register-form">
            <AddForm
                cashRegisterData={cashRegisterData}
                setCashRegisterData={setCashRegisterData}
                addCashRegister={addCashRegister}
                users={users}
                addSalesDocumentModal={addSalesDocumentModal}
                salesDocuments={salesDocuments}
                disabledButton={disabledButton}
                branchOffices={branchOffices}
            />
            <Modal
                title={modalTitleDocument}
                isVisible={isVisibleModalDocument}
                setIsVisible={setIsVisibleModalDocument}
                onCloseModal={onCloseModalDocument}
            >
                {modalContentDocument}
            </Modal>
        </div>
    );
}

function AddForm(props) {
    const {
        cashRegisterData,
        setCashRegisterData,
        addCashRegister,
        users,
        addSalesDocumentModal,
        salesDocuments,
        disabledButton,
        branchOffices,
    } = props;
    const { Option } = Select;

    const childrenUser = [];
    for (let i = 0; users && i < users.length; i++) {
        childrenUser.push(
            <Option key={users[i]._id}>
                {users[i].firstname} {users[i].lastname}
            </Option>
        );
    }

    const childrenBranchOffices = [];
    for (let i = 0; branchOffices && i < branchOffices.length; i++) {
        childrenBranchOffices.push(<Option key={branchOffices[i]._id}>{branchOffices[i].name}</Option>);
    }

    return (
        <Form className="form-add" onFinish={addCashRegister}>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item>
                        <Input
                            className="form-add_input"
                            prefix={<LaptopOutlined />}
                            placeholder="Nombre"
                            value={cashRegisterData.name}
                            onChange={(e) => setCashRegisterData({ ...cashRegisterData, name: e.target.value })}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item>
                        <Select
                            placeholder="Usuario"
                            onChange={(e) => setCashRegisterData({ ...cashRegisterData, user_id: e })}
                            value={cashRegisterData.user_id}
                            className="form-add_select"
                        >
                            {childrenUser}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item>
                        <Select
                            placeholder="Sucursal"
                            onChange={(e) => setCashRegisterData({ ...cashRegisterData, branch_office_id: e })}
                            value={cashRegisterData.branch_office_id}
                            className="form-add_select"
                        >
                            {childrenBranchOffices}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item>
                        <div className="add-cash-register-form_header-doc">
                            <div>
                                <span>Documentos</span>
                            </div>
                            <Tooltip title="Agregar documento">
                                <Button className="button-action" type="primary" onClick={addSalesDocumentModal}>
                                    <PlusOutlined />
                                </Button>
                            </Tooltip>
                        </div>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <List
                        className="documents"
                        itemLayout="horizontal"
                        dataSource={salesDocuments}
                        renderItem={(document) => <Document document={document} />}
                    />
                </Col>
            </Row>
            <Divider />
            <Form.Item>
                <Button type="primary" htmlType="submit" disabled={disabledButton} className="btn-submit">
                    Crear Caja
                </Button>
            </Form.Item>
        </Form>
    );
}

function Document(props) {
    const { document } = props;

    function leftFill(data, width) {
        var number = Math.abs(data);
        var length = number.toString().length;
        var zero = '0';

        return zero.repeat(width - length) + number.toString();
    }

    return (
        <List.Item>
            <List.Item.Meta
                title={
                    <div>
                        <b>{document.sales_document_name}</b>
                        <br />
                        {`Serie:`}&emsp;&emsp;&emsp;&ensp;
                        {document.serie.toUpperCase()}
                        <br />
                        {`Número:`}&emsp;&emsp;
                        {leftFill(document.number, 10)}
                    </div>
                }
            />
        </List.Item>
    );
}
