import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, notification, Divider, Select, Radio } from 'antd';
import { UnderlineOutlined, FilePptOutlined } from '@ant-design/icons';
import { getAccessTokenApi } from '../../../api/auth';
import { getAllWarehousesApi } from '../../../api/warehouse';
import { adjustmentStockProductApi } from '../../../api/products';
import jwtDecode from 'jwt-decode';

import './EditProductWarehouse.scss';

export default function EditProductWarehouse(props) {
    const { product, setIsVisibleModalWarehouse, setReloadProducts } = props;
    const [warehouse, setWarehouse] = useState({});
    const [typeMovement, setTypeMovement] = useState('entry');
    const [quantityFP, setQuantityFP] = useState(null);
    const [quantityPU, setQuantityPU] = useState(null);
    const [reason, setReason] = useState('');
    const [warehouses, setWarehouses] = useState([]);
    const [warehouseId, setWarehouseId] = useState(null);
    const [disabledButton, setDisabledButton] = useState(false);

    const token = getAccessTokenApi();
    const user = jwtDecode(token);

    useEffect(() => {
        getAllWarehousesApi(token)
            .then((response) => {
                if (response.success) {
                    setWarehouses(response.warehouses);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });
    }, [token]);

    useEffect(() => {
        setTypeMovement('entry');
        setWarehouseId(null);
        setQuantityFP(null);
        setQuantityPU(null);
        setReason('');
    }, [product]);

    const registerAdjustment = (e) => {
        const adjustmentData = {
            type_movement: typeMovement,
            warehouse: warehouse,
            quantity_fp: parseFloat(quantityFP),
            quantity_pu: parseFloat(quantityPU),
            reason: reason,
        };

        setDisabledButton(true);

        adjustmentStockProductApi(token, adjustmentData, product._id)
            .then((response) => {
                if (response.success) {
                    notification['success']({ message: response.message });
                    setIsVisibleModalWarehouse(false);
                    setReloadProducts(true);
                } else {
                    notification['error']({ message: response.message });
                }
                setDisabledButton(false);
            })
            .catch((err) => {
                setDisabledButton(false);
                notification['error']({ message: err });
            });
    };

    return (
        <div className="edit-product-warehouse">
            <EditForm
                product={product}
                setWarehouse={setWarehouse}
                typeMovement={typeMovement}
                setTypeMovement={setTypeMovement}
                warehouses={warehouses}
                setWarehouseId={setWarehouseId}
                warehouseId={warehouseId}
                reason={reason}
                setReason={setReason}
                quantityFP={quantityFP}
                setQuantityFP={setQuantityFP}
                quantityPU={quantityPU}
                setQuantityPU={setQuantityPU}
                registerAdjustment={registerAdjustment}
                disabledButton={disabledButton}
                user={user}
            />
        </div>
    );
}

function EditForm(props) {
    const {
        product,
        setWarehouse,
        warehouses,
        typeMovement,
        setTypeMovement,
        setWarehouseId,
        warehouseId,
        reason,
        setReason,
        quantityFP,
        setQuantityFP,
        quantityPU,
        setQuantityPU,
        registerAdjustment,
        disabledButton,
        user,
    } = props;

    const { Option } = Select;
    const { TextArea } = Input;

    const onChangeTypeMovement = (e) => {
        setTypeMovement(e.target.value);
    };

    function selectWarehouse(e) {
        setWarehouseId(e);
        for (let i = 0; warehouses && i < warehouses.length; i++) {
            if (warehouses[i]._id === e) {
                setWarehouse(warehouses[i]);
                break;
            }
        }
    }

    const childrenWarehouse = [];
    for (let i = 0; warehouses && i < warehouses.length; i++) {
        childrenWarehouse.push(<Option key={warehouses[i]._id}>{warehouses[i].name}</Option>);
    }

    return (
        <Form className="form-edit" onFinish={registerAdjustment}>
            <Row gutter={24}>
                <Col span={24}>
                    {product.warehouses.map((warehouse) => {
                        return (
                            <Row gutter={24} key={warehouse._id}>
                                <Col span={24}>
                                    <Form.Item>
                                        <b>{warehouse.name}</b>
                                    </Form.Item>
                                </Col>
                                <Col span={16}>
                                    <Form.Item>{'Stock en presentación'}</Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item>
                                        <Input
                                            className="form-edit_number"
                                            prefix={<FilePptOutlined />}
                                            placeholder="Stock de Presentación"
                                            value={warehouse.stock_presentation}
                                        />
                                    </Form.Item>
                                </Col>
                                {product.presentation_form.unit_portion > 1 && user.use_unit_portion ? (
                                    <Col span={16}>
                                        <Form.Item>{'Stock en unidad'}</Form.Item>
                                    </Col>
                                ) : null}
                                {product.presentation_form.unit_portion > 1 && user.use_unit_portion ? (
                                    <Col span={8}>
                                        <Form.Item>
                                            <Input
                                                className="form-edit_number"
                                                prefix={<UnderlineOutlined />}
                                                placeholder="Stock de Unidad"
                                                value={warehouse.stock_unit_portion.toFixed(1)}
                                            />
                                        </Form.Item>
                                    </Col>
                                ) : null}
                            </Row>
                        );
                    })}
                </Col>
                <Divider />
                <Col span={24}>
                    <Form.Item>
                        <Row>
                            <Col span={24}>
                                <b>Ingrese datos de ajuste</b>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row style={{ textAlign: 'center' }}>
                            <Col span={24}>
                                <Radio.Group onChange={onChangeTypeMovement} value={typeMovement}>
                                    <Radio value={'entry'}>¿Es ingreso?</Radio>
                                    <Radio value={'exit'}>¿Es salida?</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={24}>
                                <Form.Item>
                                    <Select
                                        showSearch
                                        placeholder={
                                            typeMovement === 'entry'
                                                ? 'Seleccione almacén destino'
                                                : 'Seleccione almacén origen'
                                        }
                                        onChange={(e) => selectWarehouse(e)}
                                        value={warehouseId}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        className="form-add_select"
                                    >
                                        {childrenWarehouse}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        {user.use_unit_portion ? (
                            <Row style={{ textAlign: 'center' }}>
                                <Col span={11}>
                                    <Form.Item>
                                        <Input
                                            className="form-edit_number"
                                            prefix={<FilePptOutlined />}
                                            placeholder="Cantidad presentación"
                                            onChange={(e) => setQuantityFP(e.target.value)}
                                            value={quantityFP}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={2}></Col>
                                <Col span={11}>
                                    <Form.Item>
                                        {product.presentation_form.unit_portion > 1 ? (
                                            <Input
                                                className="form-edit_number"
                                                prefix={<UnderlineOutlined />}
                                                placeholder="Cantidad unidad"
                                                onChange={(e) => setQuantityPU(e.target.value)}
                                                value={quantityPU}
                                            />
                                        ) : null}
                                    </Form.Item>
                                </Col>
                            </Row>
                        ) : (
                            <Row style={{ textAlign: 'center' }}>
                                <Col span={24}>
                                    <Form.Item>
                                        <Input
                                            className="form-edit_number"
                                            prefix={<FilePptOutlined />}
                                            placeholder="Cantidad presentación"
                                            onChange={(e) => setQuantityFP(e.target.value)}
                                            value={quantityFP}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={24}>
                                <Form.Item>
                                    <TextArea
                                        autoComplete="false"
                                        showCount
                                        maxLength={50}
                                        autoSize={{ minRows: 2, maxRows: 5 }}
                                        placeholder={'Ingresar motivo del ajuste de stock'}
                                        value={reason}
                                        onChange={(e) => setReason(e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <Form.Item>
                <Button type="primary" htmlType="submit" disabled={disabledButton} className="btn-submit">
                    Realizar ajuste de stock
                </Button>
            </Form.Item>
        </Form>
    );
}
