import { basePath } from './config';

export function registerSubCategoryApi(token, data) {
    const url = `${basePath}/register-subcategory`;

    const params = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(data),
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            if (result.subcategory_id) {
                return { ok: true, subcategory_id: result.subcategory_id, message: result.message };
            }
            return { ok: false, message: result.message };
        })
        .catch((err) => {
            return err.message;
        });
}

export function updateSubCategoryApi(token, subCategory, subCategoryId) {
    const url = `${basePath}/update-subcategory/${subCategoryId}`;

    const parmas = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(subCategory),
    };

    return fetch(url, parmas)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            if (result.subcategory_id) {
                return { ok: true, subcategory_id: result.subcategory_id, message: result.message };
            }
            return { ok: false, message: result.message };
        })
        .catch((err) => {
            return err.message;
        });
}

export function getSubCategoriesByCategoryApi(token, categoryId) {
    const url = `${basePath}/get-subcategories-by-category/${categoryId}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function getSubCategoriesActivesByCategoryApi(token, categoryId) {
    const url = `${basePath}/get-subcategories-actives-by-category/${categoryId}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function getSubCategoryByIdApi(token, subCategoryId) {
    const url = `${basePath}/get-subcategory-by-id/${subCategoryId}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function activateSubCategoryApi(token, subCategoryId, status) {
    const url = `${basePath}/activate-subcategory/${subCategoryId}`;

    const params = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify({
            active: status,
        }),
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}
