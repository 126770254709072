import { basePath } from './config';

export function generateSaleApi(token, sale) {
    const url = `${basePath}/generate-sale`;

    const params = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(sale),
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}

export function getSalesDocumentsByUserApi(token, userId) {
    const url = `${basePath}/get-current-sales-documents-by-user/${userId}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function getSalesDocumentsByUserTypeSaleApi(token, userId) {
    const url = `${basePath}/get-current-sales-documents-by-user-type-sale/${userId}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function getProductsByNameApi(token, name) {
    const url = `${basePath}/get-products-by-name?name=${name}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function getSalesDocumentPdfApi(token, documentName, company) {
    const url = `${basePath}/get-sales-document-pdf/${documentName}/${company}`;

    return fetch(url)
        .then((response) => {
            return response.url;
        })
        .catch((err) => {
            return err.message;
        });
}

export function getSalesByDatesApi(token, page, date_since, date_to) {
    const url = `${basePath}/get-sales-by-dates?page=${page}&date_since=${date_since}&date_to=${date_to}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function getSalesByDocumentSaleApi(token, page, serie, number) {
    const url = `${basePath}/get-sales-by-document-sale?page=${page}&serie=${serie}&number=${number}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function getSalesByClientApi(token, page, client) {
    const url = `${basePath}/get-sales-by-client?page=${page}&client=${client}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function rePrintSalesDocumentApi(token, document_id, type_print) {
    const url = `${basePath}/reprint-sales-document/${document_id}/${type_print}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function reSendSalesDocumentApi(token, document_id) {
    const url = `${basePath}/resend-sales-document/${document_id}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function getSalesSummaryByDatesApi(token, date_since, date_to) {
    const url = `${basePath}/get-sales-summary-by-dates?date_since=${date_since}&date_to=${date_to}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function annularSaleApi(token, saleId) {
    const url = `${basePath}/annular-sale/${saleId}`;

    const params = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        //body: JSON.stringify(sale),
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}

export function getEnableBillPreviusMonthApi(token, userId, typeSaleDocument) {
    const url = `${basePath}/get-enable-bill-previus-month/${userId}/${typeSaleDocument}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function getTotalProfitProductsByDatesApi(token, date_since, date_to) {
    const url = `${basePath}/get-total-prrofit-by-dates?date_since=${date_since}&date_to=${date_to}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function getDetailsProfitProductsByDatesApi(token, page, date_since, date_to) {
    const url = `${basePath}/get-details-prrofit-by-dates?page=${page}&date_since=${date_since}&date_to=${date_to}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}
