import React, { useState } from 'react';
import { List, Button, Row, Col } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import Modal from '../../../Modal';
import AddUnitForm from '../AddUnitForm';
import EditUnitForm from '../EditUnitForm';

import './ListUnits.scss';

export default function Listunits(props) {
    const { units, setReloadUnits } = props;
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState(null);

    const addUnitModal = () => {
        setIsVisibleModal(true);
        setModalTitle('Nueva unidad');
        setModalContent(<AddUnitForm setIsVisibleModal={setIsVisibleModal} setReloadUnits={setReloadUnits} />);
    };

    function onCloseModal() {}

    return (
        <div className="list-units">
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Units
                        units={units}
                        setIsVisibleModal={setIsVisibleModal}
                        setModalTitle={setModalTitle}
                        setModalContent={setModalContent}
                        setReloadUnits={setReloadUnits}
                        addUnitModal={addUnitModal}
                    />
                </Col>
            </Row>

            <Modal
                title={modalTitle}
                isVisible={isVisibleModal}
                setIsVisible={setIsVisibleModal}
                onCloseModal={onCloseModal}
            >
                {modalContent}
            </Modal>
        </div>
    );
}

function Units(props) {
    const { units, setIsVisibleModal, setModalTitle, setModalContent, setReloadUnits, addUnitModal } = props;

    const editUnit = (unit) => {
        setIsVisibleModal(true);
        setModalTitle(`Modificar ${unit.name ? unit.name : ''}`);
        setModalContent(
            <EditUnitForm unit={unit} setIsVisibleModal={setIsVisibleModal} setReloadUnits={setReloadUnits} />
        );
    };

    return (
        <div>
            <div className="list-units_headers">
                <div>
                    <h1>
                        <b>Unidades</b>
                    </h1>
                </div>
                <Button className="button-action" type="primary" onClick={addUnitModal}>
                    <PlusOutlined />
                </Button>
            </div>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <List
                        className="units"
                        itemLayout="horizontal"
                        dataSource={units}
                        renderItem={(unit) => <Unit unit={unit} editUnit={editUnit} />}
                    />
                </Col>
            </Row>
        </div>
    );
}

function Unit(props) {
    const { unit, editUnit } = props;

    return (
        <List.Item
            actions={[
                <Button className="button-action" type="primary" onClick={() => editUnit(unit)}>
                    <EditOutlined />
                </Button>,
            ]}
        >
            <List.Item.Meta title={`${unit.name ? unit.name : ''}`} />
        </List.Item>
    );
}
