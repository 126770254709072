import React, { useState, useEffect } from 'react';
import { Row, Col, Space, Input, Select, Form, notification } from 'antd';
import { getAllWarehousesApi } from '../../../api/warehouse';
import { getAccessTokenApi } from '../../../api/auth';
import jwtDecode from 'jwt-decode';

import './AdditionalPurchases.scss';

export default function AdditionalPurchases(props) {
    const { observation, setObservation, setWarehouse, reloadPurchase } = props;
    const [warehouses, setWarehouses] = useState([]);
    const [warehouseId, setWarehouseId] = useState(null);

    const { TextArea } = Input;
    const { Option } = Select;

    const token = getAccessTokenApi();
    const user = jwtDecode(token);

    useEffect(() => {
        getAllWarehousesApi(token)
            .then((response) => {
                if (response.success) {
                    setWarehouses(response.warehouses);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });
    }, [token]);

    useEffect(() => {
        setObservation('');
        setWarehouseId(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadPurchase]);

    function selectWarehouse(e) {
        setWarehouseId(e);
        for (let i = 0; warehouses && i < warehouses.length; i++) {
            if (warehouses[i]._id === e) {
                setWarehouse(warehouses[i]);
                break;
            }
        }
    }

    const childrenWarehouse = [];
    for (let i = 0; warehouses && i < warehouses.length; i++) {
        childrenWarehouse.push(<Option key={warehouses[i]._id}>{warehouses[i].name}</Option>);
    }

    return (
        <div>
            <Space size={20} direction="vertical" style={{ width: '100%' }}>
                <div className="background_block ">
                    {user.stock_control ? (
                        <div>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <h1>
                                        <b>Almacén de destino</b>
                                    </h1>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={12} md={10} lg={8}>
                                    <Form.Item>
                                        <Select
                                            showSearch
                                            placeholder="Seleccione almacén"
                                            onChange={(e) => selectWarehouse(e)}
                                            value={warehouseId}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            className="form-add_select"
                                        >
                                            {childrenWarehouse}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        <div></div>
                    )}

                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <h1>
                                <b>Observación</b>
                            </h1>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <TextArea
                                autoComplete="false"
                                showCount
                                maxLength={300}
                                autoSize={{ minRows: 4, maxRows: 10 }}
                                placeholder={'Ingresar alguna observación sobre la compra'}
                                value={observation}
                                onChange={(e) => setObservation(e.target.value)}
                            />
                        </Col>
                    </Row>
                </div>
            </Space>
        </div>
    );
}
