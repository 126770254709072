export function onlyNumbers(data) {
    const regex = /^[0-9]*$/;
    const r = regex.test(data);
    return r;
}

export function onlyNumbersDecimal(data) {
    const regex = /^\d*\.?\d*$/;
    const r = regex.test(data);
    return r;
}
