import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, notification, Divider } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { updatePasswordApi } from '../../../../api/user';
import { getAccessTokenApi } from '../../../../api/auth';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../../../../utils/constants';

import './UserPasswordForm.scss';

export default function UserPasswordForm(props) {
    const { setIsVisibleModal } = props;
    const [passwordData, setPasswordData] = useState({});
    const [disabledButton, setDisabledButton] = useState(false);

    const accessToken = getAccessTokenApi();

    useEffect(() => {
        setPasswordData({ ...passwordData, beforePassword: '' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changePassword = async (e) => {
        if (passwordData.newPassword1 !== passwordData.newPassword2) {
            notification['error']({ message: 'Las contraseñas deben ser iguales' });
            return;
        }

        setDisabledButton(true);

        const result = await updatePasswordApi(accessToken, passwordData);
        if (result.message) {
            notification['error']({ message: result.message });
        } else {
            notification['success']({ message: 'Actualización correcta' });
            setTimeout(function () {}, 1000);

            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(REFRESH_TOKEN);

            const { accessToken, refreshToken } = result;
            localStorage.setItem(ACCESS_TOKEN, accessToken);
            localStorage.setItem(REFRESH_TOKEN, refreshToken);

            localStorage.setItem('title_page', 'Nueva venta');
            window.location.href = '/newsale';
            //window.location.reload();

            setIsVisibleModal(false);
        }

        setPasswordData({});
        setDisabledButton(false);
    };

    return (
        <div className="add-brand-form">
            <Form className="form-add" onFinish={changePassword}>
                <Divider orientation="left" plain>
                    Contraseña anterior
                </Divider>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item>
                            <Input
                                className="form-add_input"
                                prefix={<LockOutlined />}
                                placeholder="Ingresar contraseña anterior"
                                value={passwordData.beforePassword}
                                onChange={(e) => setPasswordData({ ...passwordData, beforePassword: e.target.value })}
                                type="password"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left" plain>
                    Nueva contraseña
                </Divider>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item>
                            <Input
                                className="form-add_input"
                                prefix={<LockOutlined />}
                                placeholder="Ingresar nueva contraseña"
                                value={passwordData.newPassword1}
                                onChange={(e) => setPasswordData({ ...passwordData, newPassword1: e.target.value })}
                                type="password"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item>
                            <Input
                                className="form-add_input"
                                prefix={<LockOutlined />}
                                placeholder="Repetir nueva conttraseña"
                                value={passwordData.newPassword2}
                                onChange={(e) => setPasswordData({ ...passwordData, newPassword2: e.target.value })}
                                type="password"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={disabledButton} className="btn-submit">
                        Registrar nueva contraseña
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}
