// Layouts
import LayoutAdmin from '../layouts/LayoutAdmin';

// Admin Pages
import AdminSignIn from '../pages/SignIn';
import AdminProducts from '../pages/Products';
import CashRegisters from '../pages/Settings/CashRegisters';
import AdminCategories from '../pages/Settings/Categories';
import AdminUnits from '../pages/Settings/Units';
import AdminPresentations from '../pages/Settings/Presentations';
import AdminWarehouses from '../pages/Settings/Warehoses';
import AdminBrands from '../pages/Settings/Brands';
import AdminSales from '../pages/Sales/Sales';
import SalesHistory from '../pages/Sales/SalesHistory';
import Company from '../pages/Settings/Company';
import Purchases from '../pages/Purchases/Purchases';
import PurchasesHistory from '../pages/Purchases/PurchasesHistory';
import Movements from '../pages/Movements';
import SalesReport from '../pages/Reports/SalesReport';
import BranchOffices from '../pages/Settings/BranchOffices';
import SalesStatistics from '../pages/Sales/SalesStatistics/SalesStatistics';

// Clients pages
//import Home from '../pages/Home';

// Other
import Error404 from '../pages/Error404';

const routes = [
    {
        path: '/',
        component: LayoutAdmin,
        exact: false,
        routes: [
            {
                path: '/login',
                component: AdminSignIn,
                exact: true,
            },
            {
                path: '/products',
                component: AdminProducts,
                exact: true,
            },
            {
                path: '/cashregisters',
                component: CashRegisters,
                exact: true,
            },
            {
                path: '/categories',
                component: AdminCategories,
                exact: true,
            },
            {
                path: '/units',
                component: AdminUnits,
                exact: true,
            },
            {
                path: '/presentations',
                component: AdminPresentations,
                exact: true,
            },
            {
                path: '/brands',
                component: AdminBrands,
                exact: true,
            },
            {
                path: '/warehouses',
                component: AdminWarehouses,
                exact: true,
            },
            {
                path: '/newsale',
                component: AdminSales,
                exact: true,
            },
            {
                path: '/saleshisotry',
                component: SalesHistory,
                exact: true,
            },
            {
                path: '/salesstatistics',
                component: SalesStatistics,
                exact: true,
            },
            {
                path: '/mycompany',
                component: Company,
                exact: true,
            },
            {
                path: '/newpurchase',
                component: Purchases,
                exact: true,
            },
            {
                path: '/purchasesHistory',
                component: PurchasesHistory,
                exact: true,
            },
            {
                path: '/movements',
                component: Movements,
                exact: true,
            },
            {
                path: '/salesreport',
                component: SalesReport,
                exact: true,
            },
            {
                path: '/branchoffices',
                component: BranchOffices,
                exact: true,
            },
            {
                component: Error404,
            },
        ],
    },
];

export default routes;
