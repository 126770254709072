import React, { useState, useEffect } from 'react';
import { notification } from 'antd';
import { getAccessTokenApi } from '../../../api/auth';
import { getAllCashRegistersApi } from '../../../api/cashregister';
import ListCashRegisters from '../../../components/Settings/CashRegisters/ListCashRegisters';

import './CashRegisters.scss';

export default function CashRegisters() {
    const [cashRegisters, setCashRegisters] = useState([]);
    const [reloadCashRegisters, setReloadCashRegisters] = useState(false);
    const token = getAccessTokenApi();

    useEffect(() => {
        getAllCashRegistersApi(token)
            .then((response) => {
                if (response.cashregisters) {
                    setCashRegisters(response.cashregisters);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });

        setReloadCashRegisters(false);
    }, [token, reloadCashRegisters]);

    return (
        <div className="cash-registers">
            <ListCashRegisters cashRegisters={cashRegisters} setReloadCashRegisters={setReloadCashRegisters} />
        </div>
    );
}
