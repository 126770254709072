import React, { useState, useEffect } from 'react';
import {
    AutoComplete,
    Row,
    Col,
    Button,
    Select,
    Tooltip,
    notification,
    Space,
    Input,
    Typography,
    DatePicker,
} from 'antd';
import {
    SearchOutlined,
    ToolOutlined,
    MediumOutlined,
    FilePptOutlined,
    UnderlineOutlined,
    FieldBinaryOutlined,
} from '@ant-design/icons';
import { getAccessTokenApi } from '../../../api/auth';
import { getProductsByNameApi, getProductById } from '../../../api/products';
import { getAllWarehousesApi } from '../../../api/warehouse';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

import './SearchMovements.scss';

export default function SearchMovements(props) {
    const {
        setReloadMovements,
        product,
        setProduct,
        dateSince,
        setDateSince,
        dateTo,
        setDateTo,
        warehouse,
        setWarehouse,
    } = props;
    const [productsResultSearch, setProductsResultSearch] = useState([]);
    const [productFound, setProductFound] = useState(null);
    const [warehouses, setWarehouses] = useState([]);

    const [options, setOptions] = useState([]);
    const [searchText, setSearchText] = useState('');

    const { Paragraph } = Typography;
    const { RangePicker } = DatePicker;
    const { Option } = Select;
    const dateFormat = 'DD/MM/YYYY';
    const token = getAccessTokenApi();
    const user = jwtDecode(token);

    useEffect(() => {
        getAllWarehousesApi(token)
            .then((response) => {
                if (response.success) {
                    setWarehouses(response.warehouses);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });
    }, [token]);

    useEffect(() => {
        if (productFound?._id) {
            getProductById(token, productFound._id)
                .then((response) => {
                    if (response.success) {
                        setProduct(response.product);
                    } else if (response.error) {
                        notification['error']({ message: response.message });
                    }
                    setSearchText('');
                })
                .catch((err) => {
                    notification['error']({ message: err });
                    setSearchText('');
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, productFound]);

    const onSelect = (data) => {
        setProductFound(
            productsResultSearch.find(
                (product) =>
                    `${product.name} | ${product.brand} | ${
                        product.stock_control && user.stock_control ? product.presentation : product.unit
                    }` === data
            )
        );
        setSearchText(data);
        setOptions([]);
    };

    const onSearch = (text) => {
        setSearchText(text);
        if (text.length > 1) {
            const resultSearch = [];
            getProductsByNameApi(token, text)
                .then((response) => {
                    setProductsResultSearch(response.products);
                    for (let i = 0; response.products && i < response.products.length; i++) {
                        if (response.products[i].stock_control && user.stock_control) {
                            resultSearch.push({
                                value: `${response.products[i].name} | ${response.products[i].brand} | ${response.products[i].presentation}`,
                            });
                        } else {
                            resultSearch.push({
                                value: `${response.products[i].name} | ${response.products[i].brand} | ${response.products[i].unit}`,
                            });
                        }
                    }
                    setOptions(!text ? [] : resultSearch);
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });
        } else {
            setOptions([]);
        }
    };

    function onChangeRangeDate(e) {
        setDateSince(e[0].format('DD/MM/YYYY'));
        setDateTo(e[1].format('DD/MM/YYYY'));
    }

    function selectWarehouse(e) {
        for (let i = 0; warehouses && i < warehouses.length; i++) {
            if (warehouses[i]._id === e) {
                setWarehouse(warehouses[i]);
                break;
            }
        }
    }

    const searchMovements = () => {
        setReloadMovements(true);
    };

    const childrenWarehouse = [];
    for (let i = 0; warehouses && i < warehouses.length; i++) {
        childrenWarehouse.push(<Option key={warehouses[i]._id}>{warehouses[i].name}</Option>);
    }

    return (
        <div className="search-movements">
            <div className="background_block">
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <h1>
                            <b>Opciones de búsqueda</b>
                        </h1>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={9}>
                        <AutoComplete
                            options={options}
                            onSelect={onSelect}
                            onSearch={onSearch}
                            value={searchText}
                            placeholder="Buscar nombre de producto"
                            className="search_input"
                        />
                    </Col>
                    <Col xs={24} sm={24} md={11} lg={8}>
                        <RangePicker
                            format={dateFormat}
                            defaultValue={[moment(dateSince, dateFormat), moment(dateTo, dateFormat)]}
                            onChange={onChangeRangeDate}
                            style={{ width: '100%' }}
                        />
                    </Col>
                    <Col xs={20} sm={22} md={11} lg={6}>
                        <Select
                            showSearch
                            placeholder="Seleccione almacén"
                            onChange={(e) => selectWarehouse(e)}
                            value={warehouse?._id}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            className="form-add_select"
                            style={{ width: '100%' }}
                        >
                            {childrenWarehouse}
                        </Select>
                    </Col>
                    <Col xs={4} sm={2} md={2} lg={1}>
                        <Tooltip title="Buscar">
                            <Button
                                type="primary"
                                shape="circle"
                                icon={<SearchOutlined />}
                                onClick={() => searchMovements()}
                                style={{ float: 'right' }}
                            />
                        </Tooltip>
                    </Col>
                </Row>
            </div>
            <Space size={10} direction="vertical">
                <div></div>
            </Space>
            <div className="background_block">
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={16} lg={9}>
                        <Paragraph
                            ellipsis={{
                                rows: 1,
                            }}
                        >
                            &emsp;Nombre de producto
                        </Paragraph>
                        <Input
                            prefix={<ToolOutlined />}
                            value={product?.name}
                            className="form-add_input"
                            disabled={true}
                        />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={user.use_unit_portion ? 4 : 8}>
                        <Paragraph
                            ellipsis={{
                                rows: 1,
                            }}
                        >
                            &emsp;Marca
                        </Paragraph>
                        <Input
                            prefix={<MediumOutlined />}
                            value={product?.brand}
                            className="form-add_input"
                            disabled={true}
                        />
                    </Col>
                    {user.use_unit_portion ? (
                        <Col xs={24} sm={12} md={8} lg={3}>
                            <Paragraph
                                ellipsis={{
                                    rows: 1,
                                }}
                            >
                                &emsp;Porción por Unidad
                            </Paragraph>
                            <Input
                                prefix={<FieldBinaryOutlined />}
                                value={product?.presentation_form?.unit_portion}
                                className="form-add_number"
                                disabled={true}
                            />
                        </Col>
                    ) : (
                        <div />
                    )}
                    <Col xs={24} sm={12} md={8} lg={user.use_unit_portion ? 4 : 7}>
                        <Paragraph
                            ellipsis={{
                                rows: 1,
                            }}
                        >
                            &emsp;{'Presentación'}
                        </Paragraph>
                        <Input
                            prefix={<FilePptOutlined />}
                            value={product?.presentation_form?.presentation}
                            className="form-add_input"
                            disabled={true}
                        />
                    </Col>
                    {user.use_unit_portion ? (
                        <Col xs={24} sm={12} md={8} lg={4}>
                            <Paragraph
                                ellipsis={{
                                    rows: 1,
                                }}
                            >
                                &emsp;Unidad
                            </Paragraph>
                            <Input
                                prefix={<UnderlineOutlined />}
                                value={product?.presentation_form?.unit}
                                className="form-add_input"
                                disabled={true}
                            />
                        </Col>
                    ) : (
                        <div />
                    )}
                </Row>
            </div>
        </div>
    );
}
