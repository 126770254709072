import React, { useEffect } from 'react';
import { Row, Col, Space, Input } from 'antd';

import './AdditionalSales.scss';

export default function AdditionalSales(props) {
    const { observation, setObservation, reloadSale } = props;

    const { TextArea } = Input;

    useEffect(() => {
        setObservation('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadSale]);

    return (
        <div>
            <Space size={20} direction="vertical" style={{ width: '100%' }}>
                <div className="background_block ">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <h1>
                                <b>Observación</b>
                            </h1>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <TextArea
                                autoComplete="false"
                                showCount
                                maxLength={50}
                                autoSize={{ minRows: 1, maxRows: 5 }}
                                placeholder={'Ingresar alguna observación sobre la venta'}
                                value={observation}
                                onChange={(e) => setObservation(e.target.value)}
                            />
                        </Col>
                    </Row>
                </div>
            </Space>
        </div>
    );
}
