import React, { useState, useEffect } from 'react';
import { notification } from 'antd';
import { getAccessTokenApi } from '../../../api/auth';
import { getAllWarehousesApi } from '../../../api/warehouse';
import ListWarehouses from '../../../components/Settings/Warehouses/ListWarehouses';

import './Warehouses.scss';

export default function Warehouses() {
    const [warehouses, setWarehouses] = useState([]);
    const [reloadWarehouses, setReloadWarehouses] = useState(false);
    const token = getAccessTokenApi();

    useEffect(() => {
        getAllWarehousesApi(token)
            .then((response) => {
                if (response.success) {
                    setWarehouses(response.warehouses);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });

        setReloadWarehouses(false);
    }, [token, reloadWarehouses]);

    return (
        <div className="warehouses">
            <ListWarehouses warehouses={warehouses} setReloadWarehouses={setReloadWarehouses} />
        </div>
    );
}
