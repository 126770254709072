import React, { useState, useEffect } from 'react';
import { notification, Table } from 'antd';
import { getAccessTokenApi } from '../../../api/auth';
import { getMovementsByProductWarehouseDates } from '../../../api/movement';
import jwtDecode from 'jwt-decode';
import { useMediaQuery } from 'react-responsive';

import './MovementsHistory.scss';

export default function MovementsHistory(props) {
    const { product, reloadMovements, setReloadMovements, dateSince, dateTo, warehouse } = props;
    const [movements, setMovements] = useState([]);
    const [pagePagination, setPagePagination] = useState(1);
    const [limitPagination, setLimitPagination] = useState(null);
    const [totalPagination, setTotalPagination] = useState(null);

    const isMobile = useMediaQuery({ maxWidth: 425 });

    const token = getAccessTokenApi();
    const user = jwtDecode(token);

    useEffect(() => {
        if (reloadMovements) {
            getMovementsByProductWarehouseDates(token, product?._id, warehouse?._id, dateSince, dateTo, pagePagination)
                .then((response) => {
                    if (response.success) {
                        setMovements(response.movements.docs);
                        setLimitPagination(response.movements.limit);
                        setTotalPagination(response.movements.totalDocs);
                    } else if (response.error) {
                        notification['error']({ message: response.message });
                    }
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });
        }

        setReloadMovements(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadMovements, pagePagination]);

    const columns_with_unit_portion = [
        {
            title: 'Fecha',
            dataIndex: 'date_movement',
            key: 'date_movement',
            width: 200,
            render: (date_movement) => <div style={{ textAlign: 'left' }}>{date_movement}</div>,
        },
        {
            title: 'Descripción',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Entradas',
            children: [
                {
                    title: 'P',
                    dataIndex: 'quantity_pf_entry',
                    key: 'quantity_pf_entry',
                    width: 90,
                    render: (quantity_pf_entry) => (
                        <div style={{ textAlign: 'right' }}>{quantity_pf_entry ? quantity_pf_entry : 0}</div>
                    ),
                },
                {
                    title: 'U',
                    dataIndex: 'quantity_up_entry',
                    key: 'quantity_up_entry',
                    width: 90,
                    render: (quantity_up_entry) => (
                        <div style={{ textAlign: 'right' }}>{quantity_up_entry ? quantity_up_entry : 0}</div>
                    ),
                },
                {
                    title: 'Total',
                    dataIndex: 'total_entry',
                    key: 'total_entry',
                    width: 100,
                    render: (total_entry) => (
                        <div style={{ textAlign: 'right' }}>{total_entry ? total_entry.toFixed(2) : 0}</div>
                    ),
                },
            ],
        },
        {
            title: 'Salidas',
            children: [
                {
                    title: 'P',
                    dataIndex: 'quantity_pf_exit',
                    key: 'quantity_pf_exit',
                    width: 90,
                    render: (quantity_pf_exit) => {
                        return {
                            props: {
                                style: { background: '#F6F6F6' },
                            },
                            children: (
                                <div style={{ textAlign: 'right' }}>{quantity_pf_exit ? quantity_pf_exit : 0}</div>
                            ),
                        };
                    },
                },
                {
                    title: 'U',
                    dataIndex: 'quantity_up_exit',
                    key: 'quantity_up_exit',
                    width: 90,
                    render: (quantity_up_exit) => {
                        return {
                            props: {
                                style: { background: '#F6F6F6' },
                            },
                            children: (
                                <div style={{ textAlign: 'right' }}>{quantity_up_exit ? quantity_up_exit : 0}</div>
                            ),
                        };
                    },
                },
                {
                    title: 'Total',
                    dataIndex: 'total_exit',
                    key: 'total_exit',
                    width: 100,
                    render: (total_exit) => {
                        return {
                            props: {
                                style: { background: '#F6F6F6' },
                            },
                            children: (
                                <div style={{ textAlign: 'right' }}>{total_exit ? total_exit.toFixed(2) : 0}</div>
                            ),
                        };
                    },
                },
            ],
        },
        {
            title: 'Balance',
            children: [
                {
                    title: 'P',
                    dataIndex: 'quantity_pf_balance',
                    key: 'quantity_pf_balance',
                    width: 90,
                    render: (quantity_pf_balance) => (
                        <div style={{ textAlign: 'right' }}>{quantity_pf_balance ? quantity_pf_balance : 0}</div>
                    ),
                },
                {
                    title: 'U',
                    dataIndex: 'quantity_up_balance',
                    key: 'quantity_up_balance',
                    width: 90,
                    render: (quantity_up_balance) => (
                        <div style={{ textAlign: 'right' }}>
                            {quantity_up_balance ? quantity_up_balance.toFixed(1) : 0}
                        </div>
                    ),
                },
                {
                    title: 'Total',
                    dataIndex: 'total_balance',
                    key: 'total_balance',
                    width: 100,
                    render: (total_balance) => (
                        <div style={{ textAlign: 'right' }}>{total_balance ? total_balance.toFixed(2) : 0}</div>
                    ),
                },
            ],
        },
    ];

    const columns_without_unit_portion = [
        {
            title: 'Fecha',
            dataIndex: 'date_movement',
            key: 'date_movement',
            width: 200,
            render: (date_movement) => <div style={{ textAlign: 'left' }}>{date_movement}</div>,
        },
        {
            title: 'Descripción',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Entradas',
            children: [
                {
                    title: 'Cantidad',
                    dataIndex: 'quantity_pf_entry',
                    key: 'quantity_pf_entry',
                    width: 100,
                    render: (quantity_pf_entry) => (
                        <div style={{ textAlign: 'right' }}>{quantity_pf_entry ? quantity_pf_entry : 0}</div>
                    ),
                },
                {
                    title: 'Total',
                    dataIndex: 'total_entry',
                    key: 'total_entry',
                    width: 110,
                    render: (total_entry) => (
                        <div style={{ textAlign: 'right' }}>{total_entry ? total_entry.toFixed(2) : 0}</div>
                    ),
                },
            ],
        },
        {
            title: 'Salidas',
            children: [
                {
                    title: 'Cantidad',
                    dataIndex: 'quantity_pf_exit',
                    key: 'quantity_pf_exit',
                    width: 100,
                    render: (quantity_pf_exit) => {
                        return {
                            props: {
                                style: { background: '#F6F6F6' },
                            },
                            children: (
                                <div style={{ textAlign: 'right' }}>{quantity_pf_exit ? quantity_pf_exit : 0}</div>
                            ),
                        };
                    },
                },
                {
                    title: 'Total',
                    dataIndex: 'total_exit',
                    key: 'total_exit',
                    width: 110,
                    render: (total_exit) => {
                        return {
                            props: {
                                style: { background: '#F6F6F6' },
                            },
                            children: (
                                <div style={{ textAlign: 'right' }}>{total_exit ? total_exit.toFixed(2) : 0}</div>
                            ),
                        };
                    },
                },
            ],
        },
        {
            title: 'Balance',
            children: [
                {
                    title: 'Cantidad',
                    dataIndex: 'quantity_pf_balance',
                    key: 'quantity_pf_balance',
                    width: 100,
                    render: (quantity_pf_balance) => (
                        <div style={{ textAlign: 'right' }}>
                            {quantity_pf_balance ? quantity_pf_balance.toFixed(1) : 0}
                        </div>
                    ),
                },
                {
                    title: 'Total',
                    dataIndex: 'total_balance',
                    key: 'total_balance',
                    width: 110,
                    render: (total_balance) => (
                        <div style={{ textAlign: 'right' }}>{total_balance ? total_balance.toFixed(2) : 0}</div>
                    ),
                },
            ],
        },
    ];

    return (
        <div className="background_block">
            <h1>
                <b>Historial de movimientos</b>
            </h1>
            <Table
                className="components-table-demo-nested"
                columns={user.use_unit_portion ? columns_with_unit_portion : columns_without_unit_portion}
                dataSource={movements}
                pagination={{
                    onChange: (pagePagination) => {
                        setReloadMovements(true);
                        setPagePagination(pagePagination);
                    },
                    current: pagePagination,
                    pageSize: limitPagination,
                    total: totalPagination,
                }}
                scroll={isMobile ? { x: '100vw' } : null}
            />
        </div>
    );
}
