import React, { useState, useEffect } from 'react';
import { Row, Col, Space } from 'antd';
import Modal from '../../../components/Modal';
import SaleClientForm from '../../../components/Sales/ClientForm';
import ProductsSales from '../../../components/Sales/ProductsSales';
import DetailsSales from '../../../components/Sales/DetailsSales';
import AdditionalSales from '../../../components/Sales/AdditionalSales/AdditionalSales';
import { useMediaQuery } from 'react-responsive';
import { getAccessTokenApi } from '../../../api/auth';
import jwtDecode from 'jwt-decode';

import './Sales.scss';

export default function Sales() {
    const [clientSelected, setClientSelected] = useState({});
    const [products, setProducts] = useState([]);
    const [reloadProducts, setReloadProducts] = useState(false);
    const [reloadSale, setReloadSale] = useState(false);
    const [observation, setObservation] = useState('');
    const [isSale, setIsSale] = useState(true);
    const [isSellServices, setIsSellServices] = useState(false);
    const [services, setServices] = useState([]);

    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState(null);

    const token = getAccessTokenApi();
    const user = jwtDecode(token);
    const isTablet = useMediaQuery({ maxWidth: 1280 });

    useEffect(() => {
        setReloadSale(false);
    }, [reloadSale]);

    useEffect(() => {
        setReloadProducts(false);
    }, [reloadProducts]);

    function onCloseModal() {}

    return (
        <div className="sales">
            <Row gutter={[24, 24]}>
                <SaleClientForm
                    setModalTitle={setModalTitle}
                    setIsVisibleModal={setIsVisibleModal}
                    setModalContent={setModalContent}
                    clientSelected={clientSelected}
                    setClientSelected={setClientSelected}
                    reloadSale={reloadSale}
                />
            </Row>
            <Row gutter={[24, 24]}>
                <Space size={20} direction="vertical">
                    <div></div>
                    <div></div>
                </Space>
            </Row>
            {isTablet && user.stock_control ? (
                <Row gutter={[24, 24]} className="sales_row-products">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <ProductsSales
                            products={products}
                            setProducts={setProducts}
                            setReloadProducts={setReloadProducts}
                            reloadSale={reloadSale}
                            isSale={isSale}
                            isSellServices={isSellServices}
                            setIsSellServices={setIsSellServices}
                            services={services}
                            setServices={setServices}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <AdditionalSales
                            observation={observation}
                            setObservation={setObservation}
                            reloadSale={reloadSale}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <DetailsSales
                            products={products}
                            reloadProducts={reloadProducts}
                            clientSelected={clientSelected}
                            reloadSale={reloadSale}
                            setReloadSale={setReloadSale}
                            observation={observation}
                        />
                    </Col>
                </Row>
            ) : (
                <Row gutter={[24, 24]} className="sales_row-products">
                    <Col xs={24} sm={24} md={18} lg={18}>
                        <Row gutter={[24, 24]}>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <ProductsSales
                                    products={products}
                                    setProducts={setProducts}
                                    setReloadProducts={setReloadProducts}
                                    reloadSale={reloadSale}
                                    isSale={isSale}
                                    isSellServices={isSellServices}
                                    setIsSellServices={setIsSellServices}
                                    services={services}
                                    setServices={setServices}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <AdditionalSales
                                    observation={observation}
                                    setObservation={setObservation}
                                    reloadSale={reloadSale}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6}>
                        <DetailsSales
                            products={products}
                            reloadProducts={reloadProducts}
                            clientSelected={clientSelected}
                            reloadSale={reloadSale}
                            setReloadSale={setReloadSale}
                            observation={observation}
                            isSellServices={isSellServices}
                            services={services}
                        />
                    </Col>
                </Row>
            )}

            <Modal
                title={modalTitle}
                isVisible={isVisibleModal}
                setIsVisible={setIsVisibleModal}
                onCloseModal={onCloseModal}
            >
                {modalContent}
            </Modal>
        </div>
    );
}
