import React, { useState, useEffect } from 'react';
import { notification } from 'antd';
import { getAccessTokenApi } from '../../../api/auth';
import { getAllCategoriesApi } from '../../../api/category';
import ListCategories from '../../../components/Settings/Categories/ListCategories';

import './Categories.scss';

export default function Categories() {
    const [categories, setCategories] = useState([]);
    const [reloadCategories, setReloadCategories] = useState(false);
    const token = getAccessTokenApi();

    useEffect(() => {
        getAllCategoriesApi(token)
            .then((response) => {
                if (response.categories) {
                    setCategories(response.categories);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });

        setReloadCategories(false);
    }, [token, reloadCategories]);

    return (
        <div className="categories">
            <ListCategories categories={categories} setReloadCategories={setReloadCategories} />
        </div>
    );
}
