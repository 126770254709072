import { basePath } from './config';

export function getAllDocumentsClientApi(token) {
    const url = `${basePath}/get-all-documents-client`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}
