import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Menu, Dropdown, Space, Typography } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, PoweroffOutlined, UserOutlined, MoreOutlined } from '@ant-design/icons';
import Logo from '../../assets/img/png/logo.png';
import LogoLetras from '../../assets/img/png/logo-letras.png';
import { logOut } from '../../api/auth';
import Modal from '../Modal';
import UserPasswordForm from '../Settings/Users/UserPasswordForm';
import { useMediaQuery } from 'react-responsive';
import jwtDecode from 'jwt-decode';
import { getAccessTokenApi } from '../../api/auth';

import './MenuTop.scss';

const { Text } = Typography;
const token = getAccessTokenApi();

function MenuTop(props) {
    const { menuCollapsed, setMenuCollapsed } = props;
    const [title, setTitle] = useState(null);

    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState(null);

    const isMobile = useMediaQuery({ maxWidth: 425 });
    const user = jwtDecode(token);

    let titleStorage = localStorage.getItem('title_page');

    useEffect(() => {
        setTitle(titleStorage);
    }, [titleStorage]);

    const logoutUser = () => {
        logOut();
        window.location.reload();
    };

    const changePassword = () => {
        setIsVisibleModal(true);
        setModalTitle('Cambiar contraseña');
        setModalContent(<UserPasswordForm setIsVisibleModal={setIsVisibleModal} />);
    };

    function setTitlePage(param) {
        localStorage.setItem('title_page', param);
    }

    function onCloseModal() {}

    const menu = (
        <Menu
            selectable
            defaultSelectedKeys={['/newsale']}
            items={[
                {
                    key: '/newsale',
                    label: (
                        <Link
                            className="a"
                            to={'/newsale'}
                            onClick={() => setTitlePage('Nueva venta')}
                            style={{ fontSize: 17 }}
                        >
                            Nueva venta
                        </Link>
                    ),
                },
                {
                    key: '/saleshisotry',
                    label: (
                        <Link
                            className="a"
                            to={'/saleshisotry'}
                            onClick={() => setTitlePage('Historial ventas')}
                            style={{ fontSize: 17 }}
                        >
                            Historial ventas
                        </Link>
                    ),
                },
                user.access_profit
                    ? {
                          key: '/salesstatistics',
                          label: (
                              <Link
                                  className="a"
                                  to={'/salesstatistics'}
                                  onClick={() => setTitlePage('Estadística ventas')}
                                  style={{ fontSize: 17 }}
                              >
                                  Estadística ventas
                              </Link>
                          ),
                      }
                    : null,
                {
                    key: 'compras',
                    label: <label style={{ fontSize: 17 }}>Compras</label>,
                    children: [
                        {
                            key: '/newpurchase',
                            label: (
                                <Link
                                    to={'/newpurchase'}
                                    onClick={() => setTitlePage('Nueva compra')}
                                    style={{ fontSize: 17 }}
                                >
                                    <span className="nav-text">Nueva compra</span>
                                </Link>
                            ),
                        },
                        {
                            key: '/purchaseshistory',
                            label: (
                                <Link
                                    to={'/purchaseshistory'}
                                    onClick={() => setTitlePage('Historial compras')}
                                    style={{ fontSize: 17 }}
                                >
                                    <span className="nav-text">Historial compras</span>
                                </Link>
                            ),
                        },
                    ],
                },
                user.stock_control
                    ? {
                          key: '/movements',
                          label: (
                              <Link
                                  className="a"
                                  to={'/movements'}
                                  onClick={() => setTitlePage('Kardex')}
                                  style={{ fontSize: 17 }}
                              >
                                  <span className="nav-text">Kardex</span>
                              </Link>
                          ),
                      }
                    : null,
                {
                    key: '/products',
                    label: (
                        <Link
                            className="a"
                            to={'/products'}
                            onClick={() => setTitlePage('Productos')}
                            style={{ fontSize: 17 }}
                        >
                            <span className="nav-text">Productos</span>
                        </Link>
                    ),
                },
                {
                    key: 'reportes',
                    label: <label style={{ fontSize: 17 }}>Reportes</label>,
                    children: [
                        {
                            key: '/salesreport',
                            label: (
                                <Link
                                    to={'/salesreport'}
                                    onClick={() => setTitlePage('Reporte de ventas')}
                                    style={{ fontSize: 17 }}
                                >
                                    <span className="nav-text">Reporte de ventas</span>
                                </Link>
                            ),
                        },
                    ],
                },
                {
                    type: 'divider',
                },
                {
                    key: 'configuracion',
                    label: <label style={{ fontSize: 17 }}>Configuración</label>,
                    children: [
                        {
                            key: '/mycompany',
                            label: (
                                <Link
                                    to={'/mycompany'}
                                    onClick={() => setTitlePage('Conf. de mi negocio')}
                                    style={{ fontSize: 17 }}
                                >
                                    <span className="nav-text">Mi negocio</span>
                                </Link>
                            ),
                        },
                        {
                            key: '/cashregisters',
                            label: (
                                <Link
                                    to={'/cashregisters'}
                                    onClick={() => setTitlePage('Conf. de cajas')}
                                    style={{ fontSize: 17 }}
                                >
                                    <span className="nav-text">Cajas</span>
                                </Link>
                            ),
                        },
                        {
                            key: '/categories',
                            label: (
                                <Link
                                    to={'/categories'}
                                    onClick={() => setTitlePage('Conf. de categorías')}
                                    style={{ fontSize: 17 }}
                                >
                                    <span className="nav-text">Categorías</span>
                                </Link>
                            ),
                        },
                        {
                            key: '/brands',
                            label: (
                                <Link
                                    to={'/brands'}
                                    onClick={() => setTitlePage('Conf. de marcas')}
                                    style={{ fontSize: 17 }}
                                >
                                    <span className="nav-text">Marcas</span>
                                </Link>
                            ),
                        },
                        !user.stock_control || user.use_unit_portion
                            ? {
                                  key: '/units',
                                  label: (
                                      <Link
                                          to={'/units'}
                                          onClick={() => setTitlePage('Conf. de unidades')}
                                          style={{ fontSize: 17 }}
                                      >
                                          <span className="nav-text">Unidades</span>
                                      </Link>
                                  ),
                              }
                            : null,
                        user.stock_control
                            ? {
                                  key: '/presentations',
                                  label: (
                                      <Link
                                          to={'/presentations'}
                                          onClick={() => setTitlePage('Conf. de presentaciones')}
                                          style={{ fontSize: 17 }}
                                      >
                                          <span className="nav-text">Presetanción</span>
                                      </Link>
                                  ),
                              }
                            : null,
                        user.stock_control
                            ? {
                                  key: '/warehouses',
                                  label: (
                                      <Link
                                          to={'/warehouses'}
                                          onClick={() => setTitlePage('Conf. de almacenes')}
                                          style={{ fontSize: 17 }}
                                      >
                                          <span className="nav-text">Almacenes</span>
                                      </Link>
                                  ),
                              }
                            : null,
                        {
                            key: '/branchoffices',
                            label: (
                                <Link
                                    to={'/branchoffices'}
                                    onClick={() => setTitlePage('Conf. de sucursales')}
                                    style={{ fontSize: 17 }}
                                >
                                    <span className="nav-text">Sucursales</span>
                                </Link>
                            ),
                        },
                    ],
                },
                {
                    type: 'divider',
                },
                {
                    key: 'password',
                    label: <label style={{ fontSize: 17 }}>Cambiar contraseña</label>,
                    onClick: changePassword,
                },
                {
                    type: 'divider',
                },
                {
                    key: 'logout',
                    label: <label style={{ fontSize: 17 }}>Salir</label>,
                    onClick: logoutUser,
                },
            ]}
        />
    );

    return (
        <div className="menu-top">
            {!isMobile ? (
                <div>
                    <div className="menu-top_left">
                        &emsp;
                        <img className="menu-top_left-logo" src={Logo} alt="imagen" />
                        <img className="menu-top_left-logo-letras" src={LogoLetras} alt="imagen" />
                        <Button type="link" onClick={() => setMenuCollapsed(!menuCollapsed)}>
                            {menuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        </Button>
                        &emsp;&emsp;&emsp;&emsp;
                        <u>
                            <span className="title-page">{title}</span>
                        </u>
                    </div>
                    <div className="menu-top_right">
                        <Button type="link" onClick={changePassword}>
                            <UserOutlined />
                        </Button>
                        <Button type="link" onClick={logoutUser}>
                            <PoweroffOutlined />
                            Salir
                        </Button>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="menu-top_left">
                        &emsp;
                        <img className="menu-top_left-logo" src={Logo} alt="imagen" />
                        <img className="menu-top_left-logo-letras" src={LogoLetras} alt="imagen" />
                        &emsp;&emsp;&emsp;
                        <u className="menu-top_left-title">
                            <Text className="title-page" style={{ width: '180px' }} ellipsis={{ tooltip: title }}>
                                {title}
                            </Text>
                        </u>
                    </div>
                    <div className="menu-top_right">
                        <Dropdown overlay={menu} placement="bottomRight">
                            <Typography.Link>
                                <Space>
                                    <MoreOutlined style={{ color: 'white', fontSize: 20 }} />
                                </Space>
                            </Typography.Link>
                        </Dropdown>
                        &emsp;
                    </div>
                </div>
            )}

            <Modal
                title={modalTitle}
                isVisible={isVisibleModal}
                setIsVisible={setIsVisibleModal}
                onCloseModal={onCloseModal}
            >
                {modalContent}
            </Modal>
        </div>
    );
}

export default withRouter(MenuTop);
