import React, { useState } from 'react';
import { Row, Col, Space } from 'antd';
import moment from 'moment';
import SearchSalesStatistics from '../../../components/Sales/SearchSalesStatistics/SearchSalesStatistics';
import SummarySalesProfit from '../../../components/Sales/SummarySalesProfit/SummarySalesProfit';
import HistoryDetailsSalesProfit from '../../../components/Sales/HistoryDatilsSalesProfit/HistoryDetailsSalesProfit';

import './SalesStatistics.scss';

export default function SalesStatistics() {
    const [dateSince, setDateSince] = useState(moment().format('DD/MM/YYYY'));
    const [dateTo, setDateTo] = useState(moment().format('DD/MM/YYYY'));
    const [reloadSales, setReloadSales] = useState(false);

    return (
        <div>
            <Row gutter={[24, 24]} className="sales_row-products">
                <Col xs={24} sm={24} md={18} lg={20}>
                    <SearchSalesStatistics
                        dateSince={dateSince}
                        setDateSince={setDateSince}
                        dateTo={dateTo}
                        setDateTo={setDateTo}
                        setReloadSales={setReloadSales}
                    />
                </Col>
                <Col xs={24} sm={24} md={6} lg={4}>
                    <SummarySalesProfit
                        dateSince={dateSince}
                        dateTo={dateTo}
                        reloadSales={reloadSales}
                        setReloadSales={setReloadSales}
                    />
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Space size={20} direction="vertical">
                    <div></div>
                    <div></div>
                </Space>
            </Row>
            <Row gutter={[24, 24]} className="sales_row-products">
                <Col xs={24} sm={24} md={24} lg={24}>
                    <HistoryDetailsSalesProfit
                        dateSince={dateSince}
                        dateTo={dateTo}
                        reloadSales={reloadSales}
                        setReloadSales={setReloadSales}
                    />
                </Col>
            </Row>
        </div>
    );
}
