import React, { useState, useEffect } from 'react';
import { notification } from 'antd';
import { getAccessTokenApi } from '../../../api/auth';
import { getAllPresentationsApi } from '../../../api/presentation';
import ListPresentations from '../../../components/Settings/Presentations/ListPresentations';

import './Presentations.scss';

export default function Presentations() {
    const [presentations, setPresentations] = useState([]);
    const [reloadPresentations, setReloadPresentations] = useState(false);
    const token = getAccessTokenApi();

    useEffect(() => {
        getAllPresentationsApi(token)
            .then((response) => {
                if (response.presentations) {
                    setPresentations(response.presentations);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });

        setReloadPresentations(false);
    }, [token, reloadPresentations]);

    return (
        <div className="presentations">
            <ListPresentations presentations={presentations} setReloadPresentations={setReloadPresentations} />
        </div>
    );
}
