import React, { useState, useEffect } from 'react';
import { Row, Col, notification, Statistic } from 'antd';
import { getAccessTokenApi } from '../../../api/auth';
import { getTotalProfitProductsByDatesApi } from '../../../api/sale';

import './SummarySalesProfit.scss';

export default function SummarySalesProfit(props) {
    const { dateSince, dateTo, reloadSales } = props;
    const [totals, setTotals] = useState(0);

    const token = getAccessTokenApi();

    useEffect(() => {
        getTotalProfitProductsByDatesApi(token, formatDateSearch(dateSince), formatDateSearch(dateTo))
            .then((response) => {
                if (response.success) {
                    setTotals(response.totalProfit);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadSales]);

    function formatDateSearch(date) {
        const args = date.split('/');
        return args[2] + args[1] + args[0];
    }

    return (
        <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={24} lg={24}>
                <div className="background_block">
                    <Row gutter={[16, 16]} style={{ textAlign: 'center' }}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Statistic
                                title="Total de ganancia"
                                value={totals?.toFixed(2)}
                                valueStyle={{ color: '#3f8600' }}
                            />
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    );
}
