import React, { useState, useEffect } from 'react';
import { List, Button, Row, Col, notification, Tooltip } from 'antd';
import { EditOutlined, StopOutlined, CheckOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import Modal from '../../../Modal';
import EditCategoryForm from '../EditCategoryForm';
import AddCategoryForm from '../AddCategoryForm';
import EditSubCategoryForm from '../EditSubCategoryForm';
import AddSubCategoryForm from '../AddSubCategoryForm';
import { getSubCategoriesByCategoryApi, activateSubCategoryApi } from '../../../../api/subcategory';
import { activateCategoryApi } from '../../../../api/category';
import { getAccessTokenApi } from '../../../../api/auth';

import './ListCategories.scss';

export default function ListCategories(props) {
    const { categories, setReloadCategories } = props;
    const [reloadSubCategories, setReloadSubCategories] = useState(false);
    const [categoryId, setCategoryId] = useState(null);
    const [subCategories, setSubCategories] = useState([]);
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState(null);
    const [categorySelected, setCategorySelected] = useState('');

    const token = getAccessTokenApi();

    const addCategoryModal = () => {
        setIsVisibleModal(true);
        setModalTitle('Nueva categoría');
        setModalContent(
            <AddCategoryForm setIsVisibleModal={setIsVisibleModal} setReloadCategories={setReloadCategories} />
        );
    };

    const addSubCategoryModal = () => {
        if (!categoryId) {
            notification['error']({ message: 'Seleccione una categoría' });
        } else {
            setIsVisibleModal(true);
            setModalTitle('Nueva sub categoría');
            setModalContent(
                <AddSubCategoryForm
                    setIsVisibleModal={setIsVisibleModal}
                    setReloadSubCategories={setReloadSubCategories}
                    categoryId={categoryId}
                />
            );
        }
    };

    useEffect(() => {
        getSubCategoriesByCategoryApi(token, categoryId)
            .then((response) => {
                if (response.subcategories) {
                    setSubCategories(response.subcategories);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });

        setReloadSubCategories(false);
    }, [token, reloadSubCategories, categoryId]);

    function onCloseModal() {}

    return (
        <div className="list-categories">
            <Row gutter={[16, 16]} className="sales_row-products">
                <Col xs={24} sm={24} md={24} lg={12}>
                    <Categories
                        categories={categories}
                        setIsVisibleModal={setIsVisibleModal}
                        setModalTitle={setModalTitle}
                        setModalContent={setModalContent}
                        setReloadCategories={setReloadCategories}
                        addCategoryModal={addCategoryModal}
                        setSubCategories={setSubCategories}
                        setCategoryId={setCategoryId}
                        categoryId={categoryId}
                        setCategorySelected={setCategorySelected}
                    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                    <SubCategories
                        subCategories={subCategories}
                        setIsVisibleModal={setIsVisibleModal}
                        setModalTitle={setModalTitle}
                        setModalContent={setModalContent}
                        setReloadSubCategories={setReloadSubCategories}
                        addSubCategoryModal={addSubCategoryModal}
                        categorySelected={categorySelected}
                    />
                </Col>
            </Row>

            <Modal
                title={modalTitle}
                isVisible={isVisibleModal}
                setIsVisible={setIsVisibleModal}
                onCloseModal={onCloseModal}
            >
                {modalContent}
            </Modal>
        </div>
    );
}

function Categories(props) {
    const {
        categories,
        setIsVisibleModal,
        setModalTitle,
        setModalContent,
        setReloadCategories,
        addCategoryModal,
        setSubCategories,
        setCategoryId,
        categoryId,
        setCategorySelected,
    } = props;

    const editCategory = (category) => {
        setIsVisibleModal(true);
        setModalTitle(`Modificar ${category.name ? category.name : ''}`);
        setModalContent(
            <EditCategoryForm
                category={category}
                setIsVisibleModal={setIsVisibleModal}
                setReloadCategories={setReloadCategories}
            />
        );
    };

    return (
        <div>
            <div className="list-categories_headers">
                <div>
                    <h1>
                        <b>Categorías</b>
                    </h1>
                </div>
                <Tooltip title="Agregar categoría">
                    <Button className="button-action" type="primary" onClick={addCategoryModal}>
                        <PlusOutlined />
                    </Button>
                </Tooltip>
            </div>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <List
                        className="categories"
                        itemLayout="horizontal"
                        dataSource={categories}
                        renderItem={(category) => (
                            <Category
                                category={category}
                                editCategory={editCategory}
                                setReloadCategories={setReloadCategories}
                                setSubCategories={setSubCategories}
                                setCategoryId={setCategoryId}
                                categoryId={categoryId}
                                setCategorySelected={setCategorySelected}
                            />
                        )}
                    />
                </Col>
            </Row>
        </div>
    );
}

function SubCategories(props) {
    const {
        subCategories,
        setIsVisibleModal,
        setModalTitle,
        setModalContent,
        setReloadSubCategories,
        addSubCategoryModal,
        categorySelected,
    } = props;

    const editSubCategory = (subcategory) => {
        setIsVisibleModal(true);
        setModalTitle(`Modificar ${subcategory.name ? subcategory.name : ''}`);
        setModalContent(
            <EditSubCategoryForm
                subcategory={subcategory}
                setIsVisibleModal={setIsVisibleModal}
                setReloadSubCategories={setReloadSubCategories}
            />
        );
    };

    return (
        <div>
            <div className="list-categories_headers">
                <div>
                    <h1>
                        <b>Sub Categorías de: {categorySelected}</b>
                    </h1>
                </div>
                <Tooltip title="Agregar Sub categoría">
                    <Button className="button-action" type="primary" onClick={addSubCategoryModal}>
                        <PlusOutlined />
                    </Button>
                </Tooltip>
            </div>
            <List
                className="categories"
                itemLayout="horizontal"
                dataSource={subCategories}
                renderItem={(subcategory) => (
                    <SubCategory
                        subcategory={subcategory}
                        editSubCategory={editSubCategory}
                        setReloadSubCategories={setReloadSubCategories}
                    />
                )}
            />
        </div>
    );
}

function Category(props) {
    const {
        category,
        editCategory,
        setReloadCategories,
        setSubCategories,
        setCategoryId,
        categoryId,
        setCategorySelected,
    } = props;

    const activateDesactivate = () => {
        const accessToken = getAccessTokenApi();

        if (category.active) {
            activateCategoryApi(accessToken, category._id, false)
                .then((response) => {
                    if (response.ok) {
                        notification['success']({ message: response.message });
                    } else {
                        notification['error']({ message: response.message });
                    }

                    setReloadCategories(true);
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });
        } else {
            activateCategoryApi(accessToken, category._id, true)
                .then((response) => {
                    if (response.ok) {
                        notification['success']({ message: response.message });
                    } else {
                        notification['error']({ message: response.message });
                    }

                    setReloadCategories(true);
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });
        }
    };

    const getSubcategoriesByCategory = () => {
        const token = getAccessTokenApi();

        getSubCategoriesByCategoryApi(token, category._id)
            .then((response) => {
                if (response.subcategories) {
                    setSubCategories(response.subcategories);
                } else {
                    notification['error']({ message: response.message });
                }
                setCategorySelected(category.name);
            })
            .catch((err) => {
                notification['error']({ message: err });
            });

        setCategoryId(category._id);
    };

    return (
        <>
            <List.Item
                actions={[
                    <Button className="button-action" type="primary" onClick={() => editCategory(category)}>
                        <EditOutlined />
                    </Button>,
                    <div>
                        {category.active ? (
                            <Button className="button-action" danger onClick={activateDesactivate}>
                                <StopOutlined />
                            </Button>
                        ) : (
                            <Button className="button-action" type="primary" ghost onClick={activateDesactivate}>
                                <CheckOutlined />
                            </Button>
                        )}
                    </div>,
                    <Button
                        className="button-action"
                        type="secondary"
                        shape="circle"
                        onClick={getSubcategoriesByCategory}
                    >
                        <RightOutlined />
                    </Button>,
                ]}
            >
                {categoryId === category._id ? (
                    <List.Item.Meta
                        title={<b className="list-categories_selected">{`${category.name ? category.name : ''}`}</b>}
                    />
                ) : (
                    <List.Item.Meta title={`${category.name ? category.name : ''}`} />
                )}
            </List.Item>
        </>
    );
}

function SubCategory(props) {
    const { subcategory, editSubCategory, setReloadSubCategories } = props;

    const activateDesactivate = () => {
        const accessToken = getAccessTokenApi();

        if (subcategory.active) {
            activateSubCategoryApi(accessToken, subcategory._id, false)
                .then((response) => {
                    if (response.ok) {
                        notification['success']({ message: response.message });
                    } else {
                        notification['error']({ message: response.message });
                    }

                    setReloadSubCategories(true);
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });
        } else {
            activateSubCategoryApi(accessToken, subcategory._id, true)
                .then((response) => {
                    if (response.ok) {
                        notification['success']({ message: response.message });
                    } else {
                        notification['error']({ message: response.message });
                    }

                    setReloadSubCategories(true);
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });
        }
    };

    return (
        <List.Item
            actions={[
                <Button className="button-action" type="primary" onClick={() => editSubCategory(subcategory)}>
                    <EditOutlined />
                </Button>,
                <div>
                    {subcategory.active ? (
                        <Button className="button-action" danger onClick={activateDesactivate}>
                            <StopOutlined />
                        </Button>
                    ) : (
                        <Button className="button-action" type="primary" ghost onClick={activateDesactivate}>
                            <CheckOutlined />
                        </Button>
                    )}
                </div>,
            ]}
        >
            <List.Item.Meta title={`${subcategory.name ? subcategory.name : ''}`} />
        </List.Item>
    );
}
