import React, { useState, useEffect, useCallback } from 'react';
import { Avatar, Form, Input, Select, Button, Row, Col, Divider, notification, Tooltip, Checkbox, Switch } from 'antd';
import {
    DollarCircleOutlined,
    ToolOutlined,
    BarcodeOutlined,
    PlusOutlined,
    UnderlineOutlined,
} from '@ant-design/icons';
import { useDropzone } from 'react-dropzone';
import NoImage from '../../../assets/img/png/no-producto.png';
import { updateProductApi, uploadImageApi, getImageApi } from '../../../api/products';
import { getAccessTokenApi } from '../../../api/auth';
import { getAllActiveCategoriesApi, getCategoryByIdApi } from '../../../api/category';
import { getSubCategoriesActivesByCategoryApi, getSubCategoryByIdApi } from '../../../api/subcategory';
import { getAllUnitsApi } from '../../../api/unit';
import { getAllBrandsApi } from '../../../api/brand';
import { getAllPresentationsApi } from '../../../api/presentation';
import Modal from '../../Modal';
import AddBrandForm from '../../Settings/Brands/AddBrandForm';
import AddUnitForm from '../../Settings/Units/AddUnitForm';
import AddPresentationForm from '../../Settings/Presentations/AddPresentationForm';
import { onlyNumbers, onlyNumbersDecimal } from '../../../utils/generalValidation';
import jwtDecode from 'jwt-decode';

import './EditProductForm.scss';

export default function EditProductForm(props) {
    const { product, setIsVisibleModalProduct, setReloadProducts } = props;
    const [image, setImage] = useState(null);
    const [productData, setProductData] = useState({});
    const [categories, setCategories] = useState({});
    const [units, setUnits] = useState({});
    const [brands, setBrands] = useState({});
    const [presentations, setPresentations] = useState({});
    const [presentationForm, setPresentationForm] = useState({});

    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState(null);
    const [reloadBrands, setReloadBrands] = useState(false);
    const [reloadUnits, setReloadUnits] = useState(false);
    const [reloadPresentations, setReloadPresentations] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);

    const token = getAccessTokenApi();

    console.log('COMPONENT ADD PRODUCT');

    useEffect(() => {
        setProductData({
            name: product.name,
            price_purchase: product.price_purchase,
            price_sale: product.price_sale,
            category: product.category,
            category_id: product.category_id,
            sub_category: product.sub_category,
            sub_category_id: product.sub_category_id,
            unit: product.unit,
            unit_id: product.unit_id,
            brand: product.brand,
            brand_id: product.brand_id,
            barcode: product.barcode_used ? product.barcode : '',
            barcode_used: product.barcode_used,
            billeable: product.billeable,
            image: product.image,
            stock_control: product.stock_control,
            presentation_form: product.presentation_form,
        });
        setPresentationForm(product.presentation_form);
    }, [product]);

    useEffect(() => {
        getAllActiveCategoriesApi(token)
            .then((response) => {
                if (response.categories) {
                    setCategories(response.categories);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });
    }, [token]);

    useEffect(() => {
        getAllUnitsApi(token)
            .then((response) => {
                if (response.units) {
                    setUnits(response.units);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });
    }, [token, reloadUnits]);

    useEffect(() => {
        getAllPresentationsApi(token)
            .then((response) => {
                if (response.success) {
                    setPresentations(response.presentations);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });
        setReloadPresentations(false);
    }, [token, reloadPresentations]);

    useEffect(() => {
        getAllBrandsApi(token)
            .then((response) => {
                if (response.brands) {
                    setBrands(response.brands);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });
    }, [token, reloadBrands]);

    useEffect(() => {
        if (product.image) {
            getImageApi(product.image).then((response) => {
                setImage(response);
            });
        } else {
            setImage(null);
        }
    }, [product]);

    useEffect(() => {
        if (image) {
            setProductData({ ...productData, image: image.file });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image]);

    useEffect(() => {
        if (productData.name || productData.brand_id) {
            setProductData({ ...productData, presentation_form: presentationForm });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [presentationForm]);

    const updateProduct = (e) => {
        let productUpdate = productData;

        if (
            !productData.name ||
            !productData.brand_id ||
            ((!productData.unit_id || !productData.price_sale) && !productData.stock_control)
        ) {
            notification['error']({ message: 'Ingrese campos obligatorios *' });
            return;
        }

        if (productData.category_id) {
            if (!productData.sub_category_id) {
                notification['error']({ message: 'Debe seleccionar una sub categoría también.' });
                return;
            }
        }

        if (productData.stock_control) {
            if (
                !presentationForm ||
                !presentationForm.presentation_id ||
                !presentationForm.unit_portion ||
                !presentationForm.price_sale_presentation ||
                !presentationForm.price_purchase_presentation
            ) {
                notification['error']({ message: 'Debe ingresar datos obligatorios de presentación' });
                return;
            }

            if (
                presentationForm.unit_portion > 1 &&
                (!presentationForm.price_sale_unit_portion || !presentationForm.unit_id)
            ) {
                notification['error']({ message: 'Ingrese datos de porción por unidad' });
                return;
            }

            for (let i = 0; i < presentations.length; i++) {
                if (presentationForm.presentation_id === presentations[i]._id) {
                    presentationForm.presentation = presentations[i].name;
                    break;
                }
            }

            for (let i = 0; i < units.length; i++) {
                if (presentationForm.unit_id === units[i]._id) {
                    presentationForm.unit = units[i].name;
                    break;
                }
            }
        }

        setDisabledButton(true);

        updateProductApi(token, productUpdate, product._id)
            .then((response) => {
                if (response.ok) {
                    notification['success']({ message: response.message });
                    if (typeof productUpdate.image === 'object') {
                        uploadImageApi(token, productUpdate.image, product._id).then((response) => {
                            productUpdate.image = response.imageName;
                            setIsVisibleModalProduct(false);
                            setReloadProducts(true);
                            setProductData({});
                            setPresentationForm({});
                        });
                    } else {
                        setIsVisibleModalProduct(false);
                        setReloadProducts(true);
                        setProductData({});
                        setPresentationForm({});
                    }
                } else {
                    notification['error']({ message: response.message });
                }
                setDisabledButton(false);
            })
            .catch((err) => {
                setDisabledButton(false);
                notification['error']({ message: err.message });
            });
    };

    const addBrandModal = () => {
        setIsVisibleModal(true);
        setModalTitle('Nueva marca');
        setModalContent(<AddBrandForm setIsVisibleModal={setIsVisibleModal} setReloadBrands={setReloadBrands} />);
    };

    const addUnitModal = () => {
        setIsVisibleModal(true);
        setModalTitle('Nueva unidad');
        setModalContent(<AddUnitForm setIsVisibleModal={setIsVisibleModal} setReloadUnits={setReloadUnits} />);
    };

    const addPresentationModal = () => {
        setIsVisibleModal(true);
        setModalTitle('Nueva de presentación');
        setModalContent(
            <AddPresentationForm
                setIsVisibleModal={setIsVisibleModal}
                setReloadPresentations={setReloadPresentations}
            />
        );
    };

    function onCloseModal() {}

    return (
        <div className="edit-product">
            <UploadImage image={image} setImage={setImage} />
            <EditForm
                productData={productData}
                setProductData={setProductData}
                updateProduct={updateProduct}
                categories={categories}
                units={units}
                brands={brands}
                token={token}
                addBrandModal={addBrandModal}
                addUnitModal={addUnitModal}
                disabledButton={disabledButton}
                presentations={presentations}
                addPresentationModal={addPresentationModal}
                presentationForm={presentationForm}
                setPresentationForm={setPresentationForm}
            />

            <Modal
                title={modalTitle}
                isVisible={isVisibleModal}
                setIsVisible={setIsVisibleModal}
                onCloseModal={onCloseModal}
            >
                {modalContent}
            </Modal>
        </div>
    );
}

function UploadImage(props) {
    const { image, setImage } = props;
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        if (image) {
            if (image.preview) {
                setImageUrl(image.preview);
            } else {
                setImageUrl(image);
            }
        } else {
            setImageUrl(null);
        }
    }, [image]);

    const onDrop = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];
            setImage({ file, preview: URL.createObjectURL(file) });
        },
        [setImage]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: 'image/jpeg, image/png',
        noKeyboard: true,
        onDrop,
    });

    return (
        <div className="upload-image" {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
                <Avatar size={150} src={NoImage} />
            ) : (
                <Avatar size={150} src={imageUrl ? imageUrl : NoImage} />
            )}
        </div>
    );
}

function EditForm(props) {
    const {
        productData,
        setProductData,
        updateProduct,
        categories,
        units,
        brands,
        token,
        addBrandModal,
        addUnitModal,
        disabledButton,
        presentations,
        addPresentationModal,
        presentationForm,
        setPresentationForm,
    } = props;
    const [subCategories, setSubCategories] = useState([]);
    const { Option } = Select;

    const user = jwtDecode(token);

    useEffect(() => {
        getSubCategoriesActivesByCategoryApi(token, productData.category_id)
            .then((response) => {
                if (response.subcategories) {
                    setSubCategories(response.subcategories);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });
    }, [token, productData.category_id]);

    useEffect(() => {
        if (!presentationForm?.unit_portion) {
            setPresentationForm({ ...presentationForm, unit_portion: 1 });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, productData.stock_control, presentationForm]);

    function onChangeCategory(e) {
        getCategoryByIdApi(token, e)
            .then((response) => {
                if (response.category) {
                    if (!response.category.active) {
                        notification['warning']({ message: 'La categoría está desactivada' });
                    }
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });

        setSubCategories([]);
        //productData.sub_category_id = null;
        setProductData({ ...productData, category_id: e, sub_category_id: null });
    }

    function onChangeSubCategory(e) {
        getSubCategoryByIdApi(token, e)
            .then((response) => {
                if (response.subcategory) {
                    if (!response.subcategory.active) {
                        notification['warning']({ message: 'La sub categoría está desactivada' });
                    }
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });

        setProductData({ ...productData, sub_category_id: e });
    }

    function onChangeBillable(event) {
        var billeableTtmp = [];
        for (let i = 0; i < event.length; i++) {
            billeableTtmp.push(event[i]);
        }

        setProductData({ ...productData, billeable: billeableTtmp });
    }

    function onChangePricePurchase(e) {
        if (onlyNumbersDecimal(e.target.value)) {
            setProductData({ ...productData, price_purchase: e.target.value });
        }
    }

    function onChangePricePurchasePresentation(e) {
        if (onlyNumbersDecimal(e.target.value)) {
            setPresentationForm({ ...presentationForm, price_purchase_presentation: e.target.value });
        }
    }

    function onChangePriceSale(e) {
        if (onlyNumbersDecimal(e.target.value)) {
            setProductData({ ...productData, price_sale: e.target.value });
        }
    }

    function onChangePriceSalePresentation(e) {
        if (onlyNumbersDecimal(e.target.value)) {
            setPresentationForm({ ...presentationForm, price_sale_presentation: e.target.value });
        }
    }

    function onChangePriceSaleUnitPortion(e) {
        if (onlyNumbersDecimal(e.target.value)) {
            setPresentationForm({ ...presentationForm, price_sale_unit_portion: e.target.value });
        }
    }

    function onChangeUnitPortion(e) {
        if (onlyNumbers(e.target.value)) {
            setPresentationForm({ ...presentationForm, unit_portion: e.target.value });
        }
    }

    const childrenCategory = [];
    for (let i = 0; categories && i < categories.length; i++) {
        childrenCategory.push(<Option key={categories[i]._id}>{categories[i].name}</Option>);
    }

    const childrenSubCategory = [];
    for (let i = 0; subCategories && i < subCategories.length; i++) {
        childrenSubCategory.push(<Option key={subCategories[i]._id}>{subCategories[i].name}</Option>);
    }

    const childrenUnit = [];
    for (let i = 0; units && i < units.length; i++) {
        childrenUnit.push(<Option key={units[i]._id}>{units[i].name}</Option>);
    }

    const childrenPresentation = [];
    for (let i = 0; units && i < presentations.length; i++) {
        childrenPresentation.push(<Option key={presentations[i]._id}>{presentations[i].name}</Option>);
    }

    const childrenBrand = [];
    for (let i = 0; brands && i < brands.length; i++) {
        childrenBrand.push(<Option key={brands[i]._id}>{brands[i].name}</Option>);
    }

    return (
        <Form className="form-edit" onFinish={updateProduct}>
            {user.stock_control ? (
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: 'end' }}>
                        <Switch
                            checkedChildren="Desactivar control de stock"
                            unCheckedChildren="Activar control de stock"
                            checked={productData.stock_control}
                            onChange={(e) => setProductData({ ...productData, stock_control: e })}
                        />
                    </Col>
                </Row>
            ) : (
                <div />
            )}
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item>
                        <Input
                            prefix={<ToolOutlined />}
                            placeholder="Nombre"
                            value={productData.name}
                            onChange={(e) => setProductData({ ...productData, name: e.target.value })}
                            className="form-edit_input"
                            autoFocus
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={12} md={12} lg={12}>
                    <Form.Item>
                        <Select
                            showSearch
                            placeholder="Categoría"
                            onChange={(e) => onChangeCategory(e)}
                            value={productData.category_id}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            className="form-edit_select"
                        >
                            {childrenCategory}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12}>
                    <Form.Item>
                        <Select
                            showSearch
                            placeholder="Sub categoría"
                            onChange={(e) => onChangeSubCategory(e)}
                            value={productData.sub_category_id}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            className="form-edit_select"
                        >
                            {childrenSubCategory}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            {!productData.stock_control ? (
                <Row gutter={24}>
                    <Col xs={21} sm={10} md={10} lg={10}>
                        <Form.Item>
                            <Select
                                showSearch
                                placeholder="* Marca"
                                onChange={(e) => setProductData({ ...productData, brand_id: e })}
                                value={productData.brand_id}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                className="form-edit_select"
                            >
                                {childrenBrand}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={3} sm={2} md={2} lg={2}>
                        <Form.Item>
                            <Tooltip title="Agregar marca">
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<PlusOutlined />}
                                    onClick={addBrandModal}
                                    style={{ float: 'right' }}
                                />
                            </Tooltip>
                        </Form.Item>
                    </Col>
                    <Col xs={21} sm={10} md={10} lg={10}>
                        <Form.Item>
                            <Select
                                showSearch
                                placeholder="* Unidad"
                                onChange={(e) => setProductData({ ...productData, unit_id: e })}
                                value={productData.unit_id}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                className="form-edit_select"
                            >
                                {childrenUnit}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={3} sm={2} md={2} lg={2}>
                        <Form.Item>
                            <Tooltip title="Agregar unidad">
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<PlusOutlined />}
                                    onClick={addUnitModal}
                                    style={{ float: 'right' }}
                                />
                            </Tooltip>
                        </Form.Item>
                    </Col>
                </Row>
            ) : (
                <Row gutter={24}>
                    <Col xs={21} sm={10} md={10} lg={10}>
                        <Form.Item>
                            <Select
                                showSearch
                                placeholder="* Marca"
                                onChange={(e) => setProductData({ ...productData, brand_id: e })}
                                value={productData.brand_id}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                className="form-edit_select"
                            >
                                {childrenBrand}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={3} sm={2} md={2} lg={2}>
                        <Form.Item>
                            <Tooltip title="Agregar marca">
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<PlusOutlined />}
                                    onClick={addBrandModal}
                                    style={{ float: 'right' }}
                                />
                            </Tooltip>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12}>
                        <Form.Item>
                            <Checkbox.Group
                                style={{ width: '100%' }}
                                value={productData.billeable}
                                onChange={onChangeBillable}
                            >
                                <Row style={{ textAlign: 'center' }}>
                                    <Col span={24}>
                                        <Checkbox value="Facturable">¿Tiene factura?</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </Col>
                </Row>
            )}
            {!productData.stock_control ? (
                <Row gutter={24}>
                    <Col xs={24} sm={12} md={12} lg={12}>
                        <Form.Item>
                            <Input
                                prefix={<DollarCircleOutlined />}
                                placeholder="Precio compra"
                                value={productData.price_purchase}
                                onChange={onChangePricePurchase}
                                className="form-add_number"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12}>
                        <Form.Item>
                            <Input
                                prefix={<DollarCircleOutlined />}
                                placeholder="* Precio venta"
                                value={productData.price_sale}
                                onChange={onChangePriceSale}
                                className="form-add_number"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            ) : (
                <div>
                    <Divider orientation="left" plain>
                        Presentación
                    </Divider>
                    <Row gutter={24}>
                        <Col xs={21} sm={10} md={10} lg={10}>
                            <Form.Item>
                                <Select
                                    showSearch
                                    placeholder="* Presentación"
                                    onChange={(e) => setPresentationForm({ ...presentationForm, presentation_id: e })}
                                    value={presentationForm?.presentation_id}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    className="form-add_select"
                                >
                                    {childrenPresentation}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={3} sm={2} md={2} lg={2}>
                            <Form.Item>
                                <Tooltip title="Agregar presentación">
                                    <Button
                                        type="primary"
                                        shape="circle"
                                        icon={<PlusOutlined />}
                                        onClick={addPresentationModal}
                                        style={{ float: 'right' }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        {user.use_unit_portion ? (
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item>
                                    <Input
                                        prefix={<UnderlineOutlined />}
                                        placeholder="* Porción por unidad"
                                        value={presentationForm?.unit_portion}
                                        onChange={onChangeUnitPortion}
                                        className="form-add_number"
                                        disabled={!user.use_unit_portion ? true : false}
                                    />
                                </Form.Item>
                            </Col>
                        ) : (
                            <div />
                        )}
                    </Row>
                    <Row gutter={24}>
                        <Col xs={24} sm={12} md={12} lg={12}>
                            <Form.Item>
                                <Input
                                    prefix={<DollarCircleOutlined />}
                                    placeholder="* Precio compra"
                                    value={presentationForm?.price_purchase_presentation}
                                    onChange={onChangePricePurchasePresentation}
                                    className="form-add_number"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12}>
                            <Form.Item>
                                <Input
                                    prefix={<DollarCircleOutlined />}
                                    placeholder="* Precio venta"
                                    value={presentationForm?.price_sale_presentation}
                                    onChange={onChangePriceSalePresentation}
                                    className="form-add_number"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {presentationForm?.unit_portion > 1 ? (
                        <div>
                            <Divider orientation="left" plain>
                                Unidad
                            </Divider>
                            <Row gutter={24}>
                                <Col xs={21} sm={10} md={10} lg={10}>
                                    <Form.Item>
                                        <Select
                                            showSearch
                                            placeholder="* Unidad"
                                            onChange={(e) => setPresentationForm({ ...presentationForm, unit_id: e })}
                                            value={presentationForm?.unit_id}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            className="form-add_select"
                                        >
                                            {childrenUnit}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={3} sm={2} md={2} lg={2}>
                                    <Form.Item>
                                        <Tooltip title="Agregar unidad">
                                            <Button
                                                type="primary"
                                                shape="circle"
                                                icon={<PlusOutlined />}
                                                onClick={addUnitModal}
                                                style={{ float: 'right' }}
                                            />
                                        </Tooltip>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12}>
                                    <Form.Item>
                                        <Input
                                            prefix={<DollarCircleOutlined />}
                                            placeholder="* Precio venta unidad"
                                            value={presentationForm?.price_sale_unit_portion}
                                            onChange={onChangePriceSaleUnitPortion}
                                            className="form-add_number"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        <div />
                    )}
                    <Divider />
                </div>
            )}
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item>
                        <Input
                            prefix={<BarcodeOutlined />}
                            placeholder="Código de barras"
                            value={productData.barcode}
                            onChange={(e) => setProductData({ ...productData, barcode: e.target.value })}
                            className="form-edit_input"
                        />
                    </Form.Item>
                </Col>
            </Row>
            {!productData.stock_control ? (
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item>
                            <Checkbox.Group
                                style={{ width: '100%' }}
                                value={productData.billeable}
                                onChange={onChangeBillable}
                            >
                                <Row style={{ textAlign: 'center' }}>
                                    <Col span={24}>
                                        <Checkbox value="Facturable">¿Tiene factura?</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </Col>
                </Row>
            ) : (
                <div />
            )}

            <Divider />
            <Form.Item>
                <Button type="primary" htmlType="submit" disabled={disabledButton} className="btn-submit">
                    Modificar producto
                </Button>
            </Form.Item>
        </Form>
    );
}
