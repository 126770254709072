import React, { useState } from 'react';
import { List, Button, Row, Col } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import Modal from '../../../Modal';
import AddPresentationForm from '../AddPresentationForm';
import EditPresentationForm from '../EditPresentationForm';

import './ListPresentations.scss';

export default function ListPresentations(props) {
    const { presentations, setReloadPresentations } = props;
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState(null);

    const addPresentationModal = () => {
        setIsVisibleModal(true);
        setModalTitle('Nueva presentación');
        setModalContent(
            <AddPresentationForm
                setIsVisibleModal={setIsVisibleModal}
                setReloadPresentations={setReloadPresentations}
            />
        );
    };

    function onCloseModal() {}

    return (
        <div className="list-presentations">
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Presentations
                        presentations={presentations}
                        setIsVisibleModal={setIsVisibleModal}
                        setModalTitle={setModalTitle}
                        setModalContent={setModalContent}
                        setReloadPresentations={setReloadPresentations}
                        addPresentationModal={addPresentationModal}
                    />
                </Col>
            </Row>

            <Modal
                title={modalTitle}
                isVisible={isVisibleModal}
                setIsVisible={setIsVisibleModal}
                onCloseModal={onCloseModal}
            >
                {modalContent}
            </Modal>
        </div>
    );
}

function Presentations(props) {
    const {
        presentations,
        setIsVisibleModal,
        setModalTitle,
        setModalContent,
        setReloadPresentations,
        addPresentationModal,
    } = props;

    const editPresentation = (presentation) => {
        setIsVisibleModal(true);
        setModalTitle(`Modificar ${presentation.name ? presentation.name : ''}`);
        setModalContent(
            <EditPresentationForm
                presentation={presentation}
                setIsVisibleModal={setIsVisibleModal}
                setReloadPresentations={setReloadPresentations}
            />
        );
    };

    return (
        <div>
            <div className="list-presentations_headers">
                <div>
                    <h1>
                        <b>Formas de presentación</b>
                    </h1>
                </div>
                <Button className="button-action" type="primary" onClick={addPresentationModal}>
                    <PlusOutlined />
                </Button>
            </div>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <List
                        className="presentations"
                        itemLayout="horizontal"
                        dataSource={presentations}
                        renderItem={(presentation) => (
                            <Presentation presentation={presentation} editPresentation={editPresentation} />
                        )}
                    />
                </Col>
            </Row>
        </div>
    );
}

function Presentation(props) {
    const { presentation, editPresentation } = props;

    return (
        <List.Item
            actions={[
                <Button className="button-action" type="primary" onClick={() => editPresentation(presentation)}>
                    <EditOutlined />
                </Button>,
            ]}
        >
            <List.Item.Meta title={`${presentation.name ? presentation.name : ''}`} />
        </List.Item>
    );
}
