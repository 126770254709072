import React, { useState, useEffect } from 'react';
import { AutoComplete, Row, Col, Button, Select, Tooltip, notification } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { getAccessTokenApi } from '../../api/auth';
import {
    getProductsApi,
    getProductsByNameApi,
    getProductsByCategorySubCategoryApi,
    getProductByIdPaginate,
} from '../../api/products';
import ListProducts from '../../components/Products/ListProducts';
import { getAllActiveCategoriesApi } from '../../api/category';
import { getSubCategoriesActivesByCategoryApi } from '../../api/subcategory';
import jwtDecode from 'jwt-decode';

import './Products.scss';

export default function Products() {
    const [productsActives, setProductsActives] = useState([]);
    const [reloadProducts, setReloadProducts] = useState(false);
    const [pagePaginationActive, setPagePaginationActive] = useState(1);
    const [limitPaginationActive, setLimitPaginationActive] = useState(null);
    const [totalPaginationActive, setTotalPaginationActive] = useState(null);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [categoryId, setCategoryId] = useState(null);
    const [subCategoryId, setSubCategoryId] = useState(null);
    const [productsResultSearch, setProductsResultSearch] = useState([]);
    const [productFound, setProductFound] = useState(null);

    const [options, setOptions] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchByName, setSearchByName] = useState(false);
    const [searchByDefault, setSearchByDefault] = useState(true);
    const [searchByCategory, setSearchByCategory] = useState(false);

    const token = getAccessTokenApi();
    const user = jwtDecode(token);

    const { Option } = Select;

    console.log('PAGE PRODUCT');

    useEffect(() => {
        if (searchByName) {
            getProductByIdPaginate(token, 1, productFound._id)
                .then((response) => {
                    if (response && response.products) {
                        setProductsActives(response.products.docs);
                        setLimitPaginationActive(response.products.limit);
                        setTotalPaginationActive(response.products.totalDocs);
                    }
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });
            setSearchByName(true);
            setSearchByDefault(false);
            setSearchByCategory(false);
        } else if (searchByDefault) {
            getProductsApi(token, pagePaginationActive)
                .then((response) => {
                    if (response && response.products) {
                        setProductsActives(response.products.docs);
                        setLimitPaginationActive(response.products.limit);
                        setTotalPaginationActive(response.products.totalDocs);
                    }
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });
            setSearchByName(false);
            setSearchByDefault(true);
            setSearchByCategory(false);
        } else if (searchByCategory) {
            getProductsByCategorySubCategoryApi(token, pagePaginationActive, categoryId, subCategoryId)
                .then((response) => {
                    if (response && response.products) {
                        setProductsActives(response.products.docs);
                        setLimitPaginationActive(response.products.limit);
                        setTotalPaginationActive(response.products.totalDocs);
                    }
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });
            setSearchByName(false);
            setSearchByDefault(false);
            setSearchByCategory(true);
        }

        setReloadProducts(false);
    }, [
        token,
        reloadProducts,
        pagePaginationActive,
        searchByName,
        searchText,
        searchByDefault,
        searchByCategory,
        categoryId,
        subCategoryId,
        productFound,
    ]);

    useEffect(() => {
        getAllActiveCategoriesApi(token)
            .then((response) => {
                if (response.categories) {
                    setCategories(response.categories);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });
    }, [token]);

    useEffect(() => {
        getSubCategoriesActivesByCategoryApi(token, categoryId)
            .then((response) => {
                if (response.subcategories) {
                    setSubCategories(response.subcategories);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });
    }, [token, categoryId]);

    const childrenCategory = [];
    for (let i = 0; categories && i < categories.length; i++) {
        childrenCategory.push(<Option key={categories[i]._id}>{categories[i].name}</Option>);
    }

    const childrenSubCategory = [];
    for (let i = 0; subCategories && i < subCategories.length; i++) {
        childrenSubCategory.push(<Option key={subCategories[i]._id}>{subCategories[i].name}</Option>);
    }

    function onChangeCategory(e) {
        setSubCategories([]);
        setSubCategoryId(null);
        setCategoryId(e);
    }

    function getProductsByCategory() {
        setSearchByName(false);
        setSearchByDefault(false);
        setSearchByCategory(true);
    }

    const onSelect = (data) => {
        setProductFound(
            productsResultSearch.find(
                (product) =>
                    `${product.name} | ${product.brand} | ${
                        product.stock_control && user.stock_control ? product.presentation : product.unit
                    }` === data
            )
        );
        setSearchText(data);
        setSearchByName(true);
        setSearchByDefault(false);
        setSearchByCategory(false);
        setOptions([]);
    };

    const onSearch = (text) => {
        if (text.length > 1) {
            const resultSearch = [];
            getProductsByNameApi(token, text)
                .then((response) => {
                    setProductsResultSearch(response.products);
                    for (let i = 0; response.products && i < response.products.length; i++) {
                        if (response.products[i].stock_control && user.stock_control) {
                            resultSearch.push({
                                value: `${response.products[i].name} | ${response.products[i].brand} | ${response.products[i].presentation}`,
                            });
                        } else {
                            resultSearch.push({
                                value: `${response.products[i].name} | ${response.products[i].brand} | ${response.products[i].unit}`,
                            });
                        }
                    }
                    setOptions(!text ? [] : resultSearch);
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });
        } else {
            setOptions([]);
        }
    };

    return (
        <div className="products">
            <div className="background_block">
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <h1>
                            <b>Opciones de búsqueda</b>
                        </h1>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={13}>
                        <AutoComplete
                            options={options}
                            onSelect={onSelect}
                            onSearch={onSearch}
                            placeholder="Buscar nombre de producto"
                            className="search_input"
                        />
                    </Col>
                    <Col xs={24} sm={11} md={5} lg={5}>
                        <Select
                            showSearch
                            placeholder="Categoría"
                            onChange={(e) => onChangeCategory(e)}
                            value={categoryId}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            className="select_input"
                        >
                            {childrenCategory}
                        </Select>
                    </Col>
                    <Col xs={24} sm={11} md={5} lg={5}>
                        <Select
                            showSearch
                            placeholder="Sub categoría"
                            onChange={(e) => setSubCategoryId(e)}
                            value={subCategoryId}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            className="select_input"
                        >
                            {childrenSubCategory}
                        </Select>
                    </Col>
                    <Col xs={24} sm={2} md={2} lg={1}>
                        <Tooltip title="Buscar">
                            <Button
                                type="primary"
                                shape="circle"
                                icon={<SearchOutlined />}
                                onClick={getProductsByCategory}
                                style={{ float: 'right' }}
                            />
                        </Tooltip>
                    </Col>
                </Row>
            </div>

            <ListProducts
                productsActives={productsActives}
                setReloadProducts={setReloadProducts}
                pagePaginationActive={pagePaginationActive}
                setPagePaginationActive={setPagePaginationActive}
                limitPaginationActive={limitPaginationActive}
                totalPaginationActive={totalPaginationActive}
                setSearchByDefault={setSearchByDefault}
                setSearchByName={setSearchByName}
                setSearchByCategory={setSearchByCategory}
            />
        </div>
    );
}
