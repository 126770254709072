import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import Modal from '../../../components/Modal';
import DetailsPurchases from '../../../components/Purchases/DetailsPurchases/DetailsPurchases';
import AdditionalPurchases from '../../../components/Purchases/AdditionalPurchases';
import ProductsSales from '../../../components/Sales/ProductsSales';
import { useMediaQuery } from 'react-responsive';
import { getAccessTokenApi } from '../../../api/auth';
import jwtDecode from 'jwt-decode';

import './Purchases.scss';

export default function Purchases() {
    const [products, setProducts] = useState([]);
    const [reloadProducts, setReloadProducts] = useState(false);
    const [reloadPurchase, setReloadPurchase] = useState(false);
    const [warehouse, setWarehouse] = useState(null);
    const [observation, setObservation] = useState('');
    const [isSale, setIsSale] = useState(false);
    const [isSellServices, setIsSellServices] = useState(false);
    const [services, setServices] = useState([]);

    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState(null);

    const token = getAccessTokenApi();
    const user = jwtDecode(token);
    const isTablet = useMediaQuery({ maxWidth: 1280 });

    useEffect(() => {
        setReloadPurchase(false);
    }, [reloadPurchase]);

    useEffect(() => {
        setReloadProducts(false);
    }, [reloadProducts]);

    function onCloseModal() {}

    return (
        <div className="purchases">
            {isTablet && user.stock_control ? (
                <Row gutter={[24, 24]} className="sales_row-products">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <ProductsSales
                            products={products}
                            setProducts={setProducts}
                            setReloadProducts={setReloadProducts}
                            reloadPurchase={reloadPurchase}
                            isSale={isSale}
                            isSellServices={isSellServices}
                            setIsSellServices={setIsSellServices}
                            services={services}
                            setServices={setServices}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <AdditionalPurchases
                            observation={observation}
                            setObservation={setObservation}
                            setWarehouse={setWarehouse}
                            reloadPurchase={reloadPurchase}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <DetailsPurchases
                            products={products}
                            reloadProducts={reloadProducts}
                            reloadPurchase={reloadPurchase}
                            setReloadPurchase={setReloadPurchase}
                            setIsVisibleModal={setIsVisibleModal}
                            setModalTitle={setModalTitle}
                            setModalContent={setModalContent}
                            observation={observation}
                            warehouse={warehouse}
                        />
                    </Col>
                </Row>
            ) : (
                <Row gutter={[24, 24]} className="sales_row-products">
                    <Col xs={24} sm={24} md={24} lg={17}>
                        <Row gutter={[24, 24]}>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <ProductsSales
                                    products={products}
                                    setProducts={setProducts}
                                    setReloadProducts={setReloadProducts}
                                    reloadPurchase={reloadPurchase}
                                    isSale={isSale}
                                    isSellServices={isSellServices}
                                    setIsSellServices={setIsSellServices}
                                    services={services}
                                    setServices={setServices}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <AdditionalPurchases
                                    observation={observation}
                                    setObservation={setObservation}
                                    setWarehouse={setWarehouse}
                                    reloadPurchase={reloadPurchase}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={7}>
                        <DetailsPurchases
                            products={products}
                            reloadProducts={reloadProducts}
                            reloadPurchase={reloadPurchase}
                            setReloadPurchase={setReloadPurchase}
                            setIsVisibleModal={setIsVisibleModal}
                            setModalTitle={setModalTitle}
                            setModalContent={setModalContent}
                            observation={observation}
                            warehouse={warehouse}
                        />
                    </Col>
                </Row>
            )}

            <Modal
                title={modalTitle}
                isVisible={isVisibleModal}
                setIsVisible={setIsVisibleModal}
                onCloseModal={onCloseModal}
            >
                {modalContent}
            </Modal>
        </div>
    );
}
