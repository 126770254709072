import { basePath } from './config';

export function getProductsApi(token, page) {
    const url = `${basePath}/products?page=${page}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function getProductsByNameApi(token, name) {
    const url = `${basePath}/products-name?name=${name}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function getProductByIdPaginate(token, page, id) {
    const url = `${basePath}/product-by-id-paginate?page=${page}&id=${id}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function getProductsByCategorySubCategoryApi(token, page, category, subcategory) {
    const url = `${basePath}/products-category-subcategory?page=${page}&category=${category}&subcategory=${subcategory}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function uploadImageApi(token, image, productId) {
    const url = `${basePath}/upload-image/${productId}`;

    const formData = new FormData();
    formData.append('image', image, image.name);

    const params = {
        method: 'PUT',
        body: formData,
        headers: {
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function getImageApi(imageName) {
    const url = `${basePath}/get-image/${imageName}`;

    return fetch(url)
        .then((response) => {
            return response.url;
        })
        .catch((err) => {
            return err.message;
        });
}

export function activateProductApi(token, productId, status) {
    const url = `${basePath}/activate-product/${productId}`;

    const params = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify({
            active: status,
        }),
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result.message;
        })
        .catch((err) => {
            return err.message;
        });
}

export function deleteProductApi(token, productId) {
    const url = `${basePath}/delete-product/${productId}`;

    const params = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result.message;
        })
        .catch((err) => {
            return err.message;
        });
}

export function updateProductApi(token, product, productId) {
    const url = `${basePath}/update-product/${productId}`;

    const params = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(product),
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            if (result.product_id) {
                return { ok: true, product_id: result.product_id, message: result.message };
            }
            return { ok: false, message: result.message };
        })
        .catch((err) => {
            return { ok: false, message: err.message };
        });
}

export function addProductApi(token, product) {
    const url = `${basePath}/add-product`;

    const params = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(product),
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            if (result.product_id) {
                return { ok: true, product_id: result.product_id, message: result.message };
            }
            return { ok: false, message: result.message };
        })
        .catch((err) => {
            return { ok: false, message: err.message };
        });
}

export function getProductActiveByURLApi(path) {
    const url = `${basePath}/product-active-by-url/${path}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function adjustmentStockProductApi(token, adjustmentData, productId) {
    const url = `${basePath}/adjustment-stock-product/${productId}`;

    const params = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(adjustmentData),
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function getProductById(token, id) {
    const url = `${basePath}/product-by-id/${id}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}
