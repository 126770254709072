import React, { useState, useEffect } from 'react';
import { Button, Tooltip, notification, Table, Space, Tag, Menu, Dropdown, Modal } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { getAccessTokenApi } from '../../../api/auth';
import {
    getPurchasesByDatesApi,
    getPurchasesByDocumentPurchaseApi,
    getPurchasesByProviderApi,
    annularPurchaseApi,
} from '../../../api/purchase';
import jwtDecode from 'jwt-decode';
import { useMediaQuery } from 'react-responsive';

import './HistoryDetailsPurchases.scss';

export default function HistoryDetailsPurchases(props) {
    const {
        searchType,
        dateSince,
        dateTo,
        seriePurchaseDocument,
        numberPurchaseDocument,
        providerSelected,
        reloadPurchases,
        setReloadPurchases,
    } = props;
    const [purchasesTale, setPurchasesTable] = useState([]);
    const [pagePagination, setPagePagination] = useState(1);
    const [limitPagination, setLimitPagination] = useState(null);
    const [totalPagination, setTotalPagination] = useState(null);
    const [visibleModal, setVisibleModal] = useState(false);
    const [record, setRecord] = useState(null);

    const isMobile = useMediaQuery({ maxWidth: 425 });

    const token = getAccessTokenApi();
    const user = jwtDecode(token);

    useEffect(() => {
        if (searchType === 1) {
            getPurchasesByDatesApi(token, pagePagination, formatDateSearch(dateSince), formatDateSearch(dateTo))
                .then((response) => {
                    if (response?.success) {
                        chargePurchasesDocuments(response.purchases);
                    } else {
                        notification['error']({ message: response.message });
                    }
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });
        } else if (searchType === 2) {
            getPurchasesByDocumentPurchaseApi(token, pagePagination, seriePurchaseDocument, numberPurchaseDocument)
                .then((response) => {
                    if (response?.success) {
                        chargePurchasesDocuments(response.purchases);
                    } else {
                        notification['error']({ message: response.message });
                    }
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });
        } else if (searchType === 3) {
            getPurchasesByProviderApi(token, pagePagination, providerSelected._id)
                .then((response) => {
                    if (response?.success) {
                        chargePurchasesDocuments(response.purchases);
                    } else {
                        notification['error']({ message: response.message });
                    }
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });
        }
        setReloadPurchases(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchType, pagePagination, reloadPurchases]);

    useEffect(() => {
        setPagePagination(1);
    }, [searchType]);

    function chargePurchasesDocuments(response) {
        if (response) {
            const purchasesTmp = response.docs;
            const purchases = [];
            for (let i = 0; purchasesTmp && i < purchasesTmp.length; i++) {
                const products = [];
                for (let j = 0; purchasesTmp[i].products_detail && j < purchasesTmp[i].products_detail.length; j++) {
                    products.push({
                        key: purchasesTmp[i].products_detail[j].product_id,
                        name: `${purchasesTmp[i].products_detail[j].name} | ${purchasesTmp[i].products_detail[j].brand}`,
                        stock_control: purchasesTmp[i].products_detail[j].stock_control,
                        unit_portion: purchasesTmp[i].products_detail[j].unit_portion,
                        presentation_presentation: purchasesTmp[i].products_detail[j].stock_control
                            ? purchasesTmp[i].products_detail[j].presentation_presentation
                            : purchasesTmp[i].products_detail[j].unit
                            ? purchasesTmp[i].products_detail[j].unit
                            : purchasesTmp[i].products_detail[j].presentation_presentation,
                        unit_presentation: purchasesTmp[i].products_detail[j].unit_presentation,
                        quantity_presentation: purchasesTmp[i].products_detail[j].stock_control
                            ? purchasesTmp[i].products_detail[j].quantity_presentation
                            : purchasesTmp[i].products_detail[j].quantity
                            ? purchasesTmp[i].products_detail[j].quantity
                            : purchasesTmp[i].products_detail[j].quantity_presentation,
                        quantity_unit_portion: purchasesTmp[i].products_detail[j].quantity_unit_portion,
                        price_purchase_presentation: purchasesTmp[i].products_detail[j].stock_control
                            ? parseFloat(purchasesTmp[i].products_detail[j].price_purchase_presentation).toFixed(2)
                            : purchasesTmp[i].products_detail[j].price_purchase
                            ? parseFloat(purchasesTmp[i].products_detail[j].price_purchase).toFixed(2)
                            : parseFloat(purchasesTmp[i].products_detail[j].price_purchase_presentation).toFixed(2),
                        price_purchase_unit_portion: parseFloat(
                            purchasesTmp[i].products_detail[j].price_purchase_unit_portion
                        ).toFixed(2),
                        subtotal: parseFloat(purchasesTmp[i].products_detail[j].subtotal).toFixed(2),
                    });
                }

                purchases.push({
                    _id: purchasesTmp[i]._id,
                    key: purchasesTmp[i]._id,
                    provider: purchasesTmp[i].provider_name,
                    document: purchasesTmp[i].serie + completeCeroLeft(purchasesTmp[i].number, 8),
                    date_issue: purchasesTmp[i].date_issue,
                    affection_value: purchasesTmp[i].affection_value.toFixed(2),
                    igv_value: purchasesTmp[i].igv_value.toFixed(2),
                    unaffection_value: purchasesTmp[i].unaffection_value.toFixed(2),
                    state: purchasesTmp[i].state,
                    products: products,
                });
            }

            setPurchasesTable(purchases);
            setLimitPagination(response.limit);
            setTotalPagination(response.totalDocs);
        }
    }

    function onAnnularPurchase(event) {
        annularPurchaseApi(token, event._id)
            .then((response) => {
                if (response.success) {
                    notification['success']({ message: response.message });
                } else {
                    notification['error']({ message: response.message });
                }
                setReloadPurchases(true);
            })
            .catch((err) => {
                notification['error']({ message: err });
                setReloadPurchases(true);
            });
    }

    const showModal = (record) => {
        setRecord(record);
        setVisibleModal(true);
    };

    const hideModal = () => {
        setVisibleModal(false);
    };

    const confirmAnnular = () => {
        onAnnularPurchase(record);
        setVisibleModal(false);
    };

    const columns = [
        { title: 'Proveedor', dataIndex: 'provider', key: 'provider' },
        {
            title: 'Documento',
            dataIndex: 'document',
            key: 'document',
            render: (document) => <div style={{ textAlign: 'center' }}>{document}</div>,
        },
        {
            title: 'Fecha de emisión',
            dataIndex: 'date_issue',
            key: 'date_issue',
            render: (date_issue) => <div style={{ textAlign: 'center' }}>{date_issue}</div>,
        },
        {
            title: 'Afecto',
            dataIndex: 'affection_value',
            key: 'affection_value',
            render: (affection_value) => <div style={{ textAlign: 'right' }}>{affection_value}</div>,
        },
        {
            title: 'IGV',
            dataIndex: 'igv_value',
            key: 'igv_value',
            render: (igv_value) => <div style={{ textAlign: 'right' }}>{igv_value}</div>,
        },
        {
            title: 'Total',
            dataIndex: 'unaffection_value',
            key: 'unaffection_value',
            render: (unaffection_value) => <div style={{ textAlign: 'right' }}>{unaffection_value}</div>,
        },
        {
            title: 'Estado',
            dataIndex: 'state',
            key: 'state',
            render: (state) => (
                <div style={{ textAlign: 'center' }}>
                    {state === 'Activo' ? <Tag color="green">{state}</Tag> : <Tag color="red">{state}</Tag>}
                </div>
            ),
        },
        {
            title: 'Acciones',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, record) => (
                <div style={{ textAlign: 'center' }}>
                    <Space size="middle">
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item
                                        key="2"
                                        onClick={() => showModal(record)}
                                        disabled={record.state === 'Anulado' ? true : false}
                                    >
                                        Anular compra
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <Tooltip title="Más opciones">
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<MoreOutlined />}
                                    onClick={null}
                                    style={{ float: 'right' }}
                                />
                            </Tooltip>
                        </Dropdown>
                    </Space>
                </div>
            ),
        },
    ];

    const expandedRowRender = (e) => {
        const columns_without_stock = [
            { title: 'Nombre de producto', dataIndex: 'name', key: 'name' },
            {
                title: 'Unidad',
                dataIndex: 'presentation_presentation',
                key: 'presentation_presentation',
                render: (presentation_presentation, record, index) => (
                    <div style={{ textAlign: 'center' }}>
                        {presentation_presentation ? presentation_presentation : '-'}
                    </div>
                ),
            },
            {
                title: 'Cantidad',
                dataIndex: 'quantity_presentation',
                key: 'quantity_presentation',
                render: (quantity_presentation, record, index) => (
                    <div style={{ textAlign: 'center' }}>{quantity_presentation ? quantity_presentation : '-'}</div>
                ),
            },
            {
                title: 'Precio',
                dataIndex: 'price_purchase_presentation',
                key: 'price_purchase_presentation',
                render: (price_purchase_presentation, record, index) => (
                    <div style={{ textAlign: 'right' }}>
                        {price_purchase_presentation ? price_purchase_presentation : '-'}
                    </div>
                ),
            },
            {
                title: 'Sub total',
                dataIndex: 'subtotal',
                key: 'subtotal',
                render: (subtotal) => <div style={{ textAlign: 'right' }}>{subtotal}</div>,
            },
        ];

        const columns_with_stock_with_up = [
            { title: 'Nombre de producto', dataIndex: 'name', key: 'name' },
            {
                title: 'Porción por unidad',
                dataIndex: 'unit_portion',
                key: 'unit_portion',
                width: 90,
                render: (unit_portion) => (
                    <div style={{ textAlign: 'center' }}>{unit_portion ? unit_portion : '1'}</div>
                ),
            },
            {
                title: 'Presentación',
                children: [
                    {
                        title: 'P',
                        dataIndex: 'presentation_presentation',
                        key: 'presentation_presentation',
                        render: (presentation_presentation, record, index) => (
                            <div style={{ textAlign: 'center' }}>
                                {presentation_presentation ? presentation_presentation : '-'}
                            </div>
                        ),
                    },
                    {
                        title: 'U',
                        dataIndex: 'unit_presentation',
                        key: 'unit_presentation',
                        render: (unit_presentation, record, index) =>
                            e.products[index].unit_portion > 1 && e.products[index].stock_control ? (
                                <div style={{ textAlign: 'center' }}>{unit_presentation ? unit_presentation : '-'}</div>
                            ) : (
                                <div style={{ textAlign: 'center' }}>-</div>
                            ),
                    },
                ],
            },
            {
                title: 'Cantidad',
                children: [
                    {
                        title: 'P',
                        dataIndex: 'quantity_presentation',
                        key: 'quantity_presentation',
                        render: (quantity_presentation, record, index) => (
                            <div style={{ textAlign: 'center' }}>
                                {quantity_presentation ? quantity_presentation : '-'}
                            </div>
                        ),
                    },
                    {
                        title: 'U',
                        dataIndex: 'quantity_unit_portion',
                        key: 'quantity_unit_portion',
                        render: (quantity_unit_portion, record, index) =>
                            e.products[index].unit_portion > 1 && e.products[index].stock_control ? (
                                <div style={{ textAlign: 'center' }}>
                                    {quantity_unit_portion ? quantity_unit_portion : '-'}
                                </div>
                            ) : (
                                <div style={{ textAlign: 'center' }}>-</div>
                            ),
                    },
                ],
            },
            {
                title: 'Precio',
                children: [
                    {
                        title: 'P',
                        dataIndex: 'price_purchase_presentation',
                        key: 'price_purchase_presentation',
                        render: (price_purchase_presentation, record, index) => (
                            <div style={{ textAlign: 'right' }}>
                                {price_purchase_presentation ? price_purchase_presentation : '-'}
                            </div>
                        ),
                    },
                    {
                        title: 'U',
                        dataIndex: 'price_purchase_unit_portion',
                        key: 'price_purchase_unit_portion',
                        render: (price_purchase_unit_portion, record, index) =>
                            e.products[index].unit_portion > 1 &&
                            e.products[index].stock_control &&
                            e.products[index].price_purchase_unit_portion > 0 ? (
                                <div style={{ textAlign: 'right' }}>
                                    {price_purchase_unit_portion ? price_purchase_unit_portion : '-'}
                                </div>
                            ) : (
                                <div style={{ textAlign: 'center' }}>-</div>
                            ),
                    },
                ],
            },
            {
                title: 'Sub total',
                dataIndex: 'subtotal',
                key: 'subtotal',
                render: (subtotal) => <div style={{ textAlign: 'right' }}>{subtotal}</div>,
            },
        ];

        const columns_with_stock_witout_up = [
            { title: 'Nombre de producto', dataIndex: 'name', key: 'name' },
            {
                title: 'Presentación',
                dataIndex: 'presentation_presentation',
                key: 'presentation_presentation',
                render: (presentation_presentation, record, index) => (
                    <div style={{ textAlign: 'center' }}>
                        {presentation_presentation ? presentation_presentation : '-'}
                    </div>
                ),
            },
            {
                title: 'Cantidad',
                dataIndex: 'quantity_presentation',
                key: 'quantity_presentation',
                render: (quantity_presentation, record, index) => (
                    <div style={{ textAlign: 'center' }}>{quantity_presentation ? quantity_presentation : '-'}</div>
                ),
            },
            {
                title: 'Precio',
                dataIndex: 'price_purchase_presentation',
                key: 'price_purchase_presentation',
                render: (price_purchase_presentation, record, index) => (
                    <div style={{ textAlign: 'right' }}>
                        {price_purchase_presentation ? price_purchase_presentation : '-'}
                    </div>
                ),
            },
            {
                title: 'Sub total',
                dataIndex: 'subtotal',
                key: 'subtotal',
                render: (subtotal) => <div style={{ textAlign: 'right' }}>{subtotal}</div>,
            },
        ];

        const products = e.products;

        return (
            <Table
                columns={
                    user.stock_control
                        ? user.use_unit_portion
                            ? columns_with_stock_with_up
                            : columns_with_stock_witout_up
                        : columns_without_stock
                }
                dataSource={products}
                expandable={false}
                pagination={false}
            />
        );
    };

    function completeCeroLeft(value, length) {
        return value.toString().length < length ? completeCeroLeft('0' + value, length) : value;
    }

    function formatDateSearch(date) {
        const args = date.split('/');
        return args[2] + args[1] + args[0];
    }

    return (
        <div className="background_block">
            <h1>
                <b>Listado de compras</b>
            </h1>
            <Space size={20} direction="vertical">
                <div></div>
            </Space>
            <Table
                className="components-table-demo-nested"
                columns={columns}
                expandable={{ expandedRowRender }}
                dataSource={purchasesTale}
                pagination={{
                    onChange: (pagePagination) => {
                        setPagePagination(pagePagination);
                    },
                    current: pagePagination,
                    pageSize: limitPagination,
                    total: totalPagination,
                }}
                summary={(pageData) => {
                    let totalAffection_value = 0;
                    let totalIgv_value = 0;
                    let totalUnaffection_value = 0;

                    pageData.forEach(({ affection_value, igv_value, unaffection_value }) => {
                        totalAffection_value += parseFloat(affection_value);
                        totalIgv_value += parseFloat(igv_value);
                        totalUnaffection_value += parseFloat(unaffection_value);
                    });

                    return (
                        <>
                            <Table.Summary.Row>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <div style={{ textAlign: 'right' }}>
                                        <b>Total</b>
                                    </div>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <div style={{ textAlign: 'right' }}>
                                        <b>{totalAffection_value.toFixed(2)}</b>
                                    </div>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <div style={{ textAlign: 'right' }}>
                                        <b>{totalIgv_value.toFixed(2)}</b>
                                    </div>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <div style={{ textAlign: 'right' }}>
                                        <b>{totalUnaffection_value.toFixed(2)}</b>
                                    </div>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
                scroll={isMobile ? { x: '100vw' } : null}
            />
            <Modal
                title="Anular compra"
                open={visibleModal}
                onOk={confirmAnnular}
                onCancel={hideModal}
                okText="Sí"
                cancelText="No"
            >
                <p>¿Está seguro de anular la compra?</p>
            </Modal>
        </div>
    );
}
