import { basePath } from './config';

export function registerCategoryApi(token, data) {
    const url = `${basePath}/register-category`;
    const params = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(data),
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            if (result.category_id) {
                return { ok: true, category_id: result.category_id, message: result.message };
            }
            return { ok: false, message: result.message };
        })
        .catch((err) => {
            return err.message;
        });
}

export function updateCategoryApi(token, category, categoryId) {
    const url = `${basePath}/update-category/${categoryId}`;

    const parmas = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(category),
    };

    return fetch(url, parmas)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            if (result.category_id) {
                return { ok: true, category_id: result.category_id, message: result.message };
            }
            return { ok: false, message: result.message };
        })
        .catch((err) => {
            return err.message;
        });
}

export function getCategoryByIdApi(token, categoryId) {
    const url = `${basePath}/get-category-by-id/${categoryId}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function getAllCategoriesApi(token) {
    const url = `${basePath}/get-all-categories`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function getAllActiveCategoriesApi(token) {
    const url = `${basePath}/get-all-active-categories`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function activateCategoryApi(token, categoryId, status) {
    const url = `${basePath}/activate-category/${categoryId}`;

    const params = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify({
            active: status,
        }),
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}

export function getMenuCategoriesApi() {
    const url = `${basePath}/get-categories-menu`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err.message;
        });
}
