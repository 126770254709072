import React, { useState } from "react";
import { Row, Col, Button, notification, Input, Checkbox } from "antd";
import {
    PlusOutlined,
    FieldBinaryOutlined,
    PhoneOutlined,
    HomeOutlined,
    EnvironmentOutlined,
    ShopOutlined,
} from "@ant-design/icons";
import Modal from "../../../Modal";
import AddBranchOfficeForm from "../AddBranchOfficeForm/AddBranchOfficeForm";
import { getAccessTokenApi } from "../../../../api/auth";
import { updateBranchOfficeApi } from "../../../../api/branchoffice";

import "./ListBranchOffices.scss";

const { TextArea } = Input;

export default function ListBranchOffices(props) {
    const { branchOffices, setReloadBranchOffices } = props;
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    const addBranchOfficeModal = () => {
        setIsVisibleModal(true);
        setModalTitle("Nueva sucursal");
        setModalContent(
            <AddBranchOfficeForm
                setIsVisibleModal={setIsVisibleModal}
                setReloadBranchOffices={setReloadBranchOffices}
            />
        );
    };

    function onCloseModal() {}

    return (
        <div className="list-branch-office">
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <BranchOffices
                        branchOffices={branchOffices}
                        setIsVisibleModal={setIsVisibleModal}
                        setModalTitle={setModalTitle}
                        setModalContent={setModalContent}
                        setReloadBranchOffices={setReloadBranchOffices}
                        addBranchOfficeModal={addBranchOfficeModal}
                    />
                </Col>
            </Row>

            <Modal
                title={modalTitle}
                isVisible={isVisibleModal}
                setIsVisible={setIsVisibleModal}
                onCloseModal={onCloseModal}
            >
                {modalContent}
            </Modal>
        </div>
    );
}

function BranchOffices(props) {
    const { branchOffices, addBranchOfficeModal } = props;

    return (
        <div>
            <div className="list-branch-office_headers">
                <div>
                    <h1>
                        <b>Sucursales</b>
                    </h1>
                </div>
                <Button className="button-action" type="primary" onClick={addBranchOfficeModal}>
                    <PlusOutlined />
                </Button>
            </div>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    {branchOffices.map((branchOffice, i) => {
                        return <BranchOffice key={branchOffice._id} branchOffice={branchOffice} />;
                    })}
                </Col>
            </Row>
        </div>
    );
}

function BranchOffice(props) {
    const { branchOffice } = props;
    const [branchOfficeData, setBranchOfficeData] = useState(branchOffice);

    const token = getAccessTokenApi();

    function onSaveDataBranchOffice() {
        updateBranchOfficeApi(token, branchOfficeData, branchOfficeData._id)
            .then((response) => {
                if (response.success) {
                    setBranchOfficeData(response.branch_office);
                    notification["success"]({ message: "Datos actualizados correctamente" });
                } else {
                    notification["error"]({ message: response.message });
                }
            })
            .catch((err) => {
                notification["error"]({ message: err });
            });
    }

    return (
        <div className="background_block" style={{ marginBottom: 20 }}>
            <Row gutter={[16, 8]}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h1>
                        <b>{branchOfficeData.name}</b>
                    </h1>
                </Col>
            </Row>
            <br />
            <Row gutter={[16, 8]}>
                <Col xs={24} sm={8} md={6} lg={3}>
                    <span>Nombre comercial: *</span>
                </Col>
                <Col xs={24} sm={16} md={18} lg={8}>
                    <Input
                        prefix={<ShopOutlined />}
                        placeholder="Nombre comercial"
                        value={branchOfficeData.trade_name}
                        onChange={(e) =>
                            setBranchOfficeData({ ...branchOfficeData, trade_name: e.target.value.toUpperCase() })
                        }
                        className="form-add_input"
                        disabled={true}
                    />
                </Col>
                <Col xs={24} sm={0} md={0} lg={2} />
                <Col xs={24} sm={8} md={6} lg={3}>
                    <span>Código sucursal: *</span>
                </Col>
                <Col xs={24} sm={16} md={18} lg={8}>
                    <Input
                        prefix={<FieldBinaryOutlined />}
                        placeholder="Código sucursal"
                        value={branchOfficeData.code}
                        onChange={(e) => setBranchOfficeData({ ...branchOfficeData, code: e.target.value })}
                        className="form-add_input"
                        disabled={true}
                    />
                </Col>
            </Row>
            <br />
            <Row gutter={[16, 8]}>
                <Col xs={24} sm={8} md={6} lg={3}>
                    <span>Locación: *</span>
                </Col>
                <Col xs={24} sm={16} md={18} lg={8}>
                    <Input
                        prefix={<EnvironmentOutlined />}
                        placeholder="Locación"
                        value={branchOfficeData.location}
                        onChange={(e) => setBranchOfficeData({ ...branchOfficeData, location: e.target.value })}
                        className="form-add_input"
                    />
                </Col>
                <Col xs={24} sm={0} md={0} lg={2} />
                <Col xs={24} sm={8} md={6} lg={3}>
                    <span>Dirección: *</span>
                </Col>
                <Col xs={24} sm={16} md={18} lg={8}>
                    <Input
                        prefix={<HomeOutlined />}
                        placeholder="Dirección"
                        value={branchOfficeData.address}
                        onChange={(e) => setBranchOfficeData({ ...branchOfficeData, address: e.target.value })}
                        className="form-add_input"
                    />
                </Col>
            </Row>
            <br />
            <Row gutter={[16, 8]}>
                <Col xs={24} sm={8} md={6} lg={3}>
                    <span>Teléfono:</span>
                </Col>
                <Col xs={24} sm={16} md={18} lg={8}>
                    <Input
                        prefix={<PhoneOutlined />}
                        placeholder="Teléfono"
                        value={branchOfficeData.cellphone}
                        onChange={(e) => setBranchOfficeData({ ...branchOfficeData, cellphone: e.target.value })}
                        className="form-add_input"
                    />
                </Col>
                <Col xs={24} sm={0} md={0} lg={2} />
                <Col xs={24} sm={8} md={6} lg={3}>
                    <span></span>
                </Col>
                <Col xs={24} sm={16} md={18} lg={8}>
                    <Checkbox
                        checked={branchOfficeData.active}
                        onChange={(e) => setBranchOfficeData({ ...branchOfficeData, active: e.target.checked })}
                    >
                        ¿Está activo?
                    </Checkbox>
                </Col>
            </Row>
            <br />
            <Row gutter={[16, 8]} style={{ alignItems: "flex-start" }}>
                <Col xs={24} sm={8} md={6} lg={3}>
                    <span>Descripción del negocio:</span>
                </Col>
                <Col xs={24} sm={16} md={18} lg={21}>
                    <TextArea
                        showCount
                        maxLength={250}
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        placeholder="Descripción del negocio"
                        value={branchOfficeData.description}
                        onChange={(e) => setBranchOfficeData({ ...branchOfficeData, description: e.target.value })}
                        className="form-add_input"
                    />
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col xs={24} sm={12} md={15} lg={19} />
                <Col xs={24} sm={12} md={9} lg={5}>
                    <Button type="primary" className="btn-submit" onClick={onSaveDataBranchOffice}>
                        Modificar sucursal
                    </Button>
                </Col>
            </Row>
        </div>
    );
}
