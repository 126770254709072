import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import useAuth from '../hooks/useAuth';
import MenuTop from '../components/MenuTop';
import MenuSider from '../components/MenuSider';
import AdminSignIn from '../pages/SignIn/SignIn';
import { useMediaQuery } from 'react-responsive';

import './LayoutAdmin.scss';

export default function LayoutAdmin(props) {
    const { routes } = props;
    const { Header, Content, Footer } = Layout;
    const [menuCollapsed, setMenuCollapsed] = useState(false);

    const { user, isLoading } = useAuth();

    const isMobile = useMediaQuery({ maxWidth: 425 });

    useEffect(() => {
        setMenuCollapsed(isMobile);
    }, [isMobile]);

    if (!user && !isLoading) {
        return (
            <>
                <Route path="/login" component={AdminSignIn} />
                <Redirect to="/login" />
            </>
        );
    }

    if (user && !isLoading) {
        return (
            <Layout>
                {isMobile ? <div></div> : <MenuSider menuCollapsed={menuCollapsed} />}
                <Layout
                    className="layout-admin"
                    style={{ marginLeft: isMobile ? '0px' : menuCollapsed ? '80px' : '200px' }}
                >
                    <Header className="layout-admin_header">
                        <MenuTop menuCollapsed={menuCollapsed} setMenuCollapsed={setMenuCollapsed} />
                    </Header>
                    <Content className="layout-admin_content">
                        <LoadRoutes routes={routes} />
                    </Content>
                    <Footer className="layout-admin_footer" style={{ float: 'center', textAlign: 'right' }}>
                        <span style={{ fontSize: 12 }}>Versión: 1.4</span>
                    </Footer>
                </Layout>
            </Layout>
        );
    }

    return null;
}

function LoadRoutes({ routes }) {
    return (
        <Switch>
            {routes.map((route, index) => (
                <Route key={index} path={route.path} exact={route.exact} component={route.component} />
            ))}
        </Switch>
    );
}
