import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, notification, Divider, Checkbox, Select } from 'antd';
import { UnderlineOutlined } from '@ant-design/icons';
import { registerWarehouseApi } from '../../../../api/warehouse';
import { getAccessTokenApi } from '../../../../api/auth';
import { getAllBranchOfficesActivesApi } from '../../../../api/branchoffice';

import './AddWarehouseForm.scss';

export default function AddWarehouseForm(props) {
    const { setIsVisibleModal, setReloadWarehouses } = props;
    const [warehouseData, setWarehouseData] = useState({});
    const [disabledButton, setDisabledButton] = useState(false);
    const [branchOffices, setBranchOffices] = useState({});

    const accessToken = getAccessTokenApi();

    useEffect(() => {
        getAllBranchOfficesActivesApi(accessToken)
            .then((response) => {
                if (response.success) {
                    setBranchOffices(response.branch_offices);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });

        setWarehouseData({ ...warehouseData, name: '' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addWarehouse = (e) => {
        if (!warehouseData.name) {
            notification['error']({ message: 'El nombre es obligatorio' });
            return;
        }

        setDisabledButton(true);

        registerWarehouseApi(accessToken, warehouseData)
            .then((response) => {
                if (response.success) {
                    notification['success']({ message: response.message });
                    setIsVisibleModal(false);
                    setReloadWarehouses(true);
                    setWarehouseData({});
                } else {
                    notification['error']({ message: response.message });
                    setWarehouseData({});
                }
                setDisabledButton(false);
            })
            .catch((err) => {
                setDisabledButton(false);
                notification['error']({ message: err });
            });
    };

    return (
        <div className="add-warehouse-form">
            <AddForm
                warehouseData={warehouseData}
                setWarehouseData={setWarehouseData}
                addWarehouse={addWarehouse}
                disabledButton={disabledButton}
                branchOffices={branchOffices}
            />
        </div>
    );
}

function AddForm(props) {
    const { warehouseData, setWarehouseData, addWarehouse, disabledButton, branchOffices } = props;
    const { Option } = Select;

    const childrenBranchOffices = [];
    for (let i = 0; branchOffices && i < branchOffices.length; i++) {
        childrenBranchOffices.push(<Option key={branchOffices[i]._id}>{branchOffices[i].name}</Option>);
    }

    return (
        <Form className="form-add" onFinish={addWarehouse}>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item>
                        <Input
                            className="form-add_input"
                            prefix={<UnderlineOutlined />}
                            placeholder="Nombre"
                            value={warehouseData.name}
                            onChange={(e) => setWarehouseData({ ...warehouseData, name: e.target.value })}
                            ref={(input) => input && input.focus()}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item>
                        <Select
                            placeholder="Sucursal"
                            onChange={(e) => setWarehouseData({ ...warehouseData, branch_office_id: e })}
                            value={warehouseData.branch_office_id}
                            className="form-add_select"
                        >
                            {childrenBranchOffices}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item>
                        <Row style={{ textAlign: 'center' }}>
                            <Col span={24}>
                                <Checkbox
                                    checked={warehouseData.is_sale}
                                    onChange={(e) => setWarehouseData({ ...warehouseData, is_sale: e.target.checked })}
                                >
                                    ¿Es de ventas?
                                </Checkbox>
                                <Checkbox
                                    checked={warehouseData.is_purchase}
                                    onChange={(e) =>
                                        setWarehouseData({ ...warehouseData, is_purchase: e.target.checked })
                                    }
                                >
                                    ¿Es de compras?
                                </Checkbox>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <Form.Item>
                <Button type="primary" htmlType="submit" disabled={disabledButton} className="btn-submit">
                    Crear almacén
                </Button>
            </Form.Item>
        </Form>
    );
}
