import React, { useState, useEffect } from 'react';
import { Row, Col, Button, notification, Divider, Space, Radio, AutoComplete, Input, Tooltip, DatePicker, Select } from 'antd';
import { PlusOutlined, EditOutlined, IdcardOutlined, FieldNumberOutlined, LaptopOutlined } from '@ant-design/icons';
import { getAccessTokenApi } from '../../../api/auth';
import { registerPurchaseApi } from '../../../api/purchase';
import { getClientsByNameApi, getClientById, getClientsByNumberDocApi } from '../../../api/client';
import { getSalesDocumentsTypeSaleApi } from '../../../api/salesdocument';
import AddClientForm from '../../Clients/AddClientForm';
import EditCLientForm from '../../Clients/EditClientForm';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { getAllPaymentTypesApi } from '../../../api/paymenttype';

import './DetailsPurchases.scss';

export default function DetailsPurchases(props) {
    const {
        setModalTitle,
        setIsVisibleModal,
        setModalContent,
        products,
        reloadProducts,
        reloadPurchase,
        setReloadPurchase,
        observation,
        warehouse,
    } = props;

    const [clientSelected, setClientSelected] = useState({});
    const [optionsClients, setOptionsClients] = useState([]);
    const [textSearchClient, setTextSearchClient] = useState('');
    const [clientsResultSearch, setClientsResultSearch] = useState([]);
    const [clientFound, setClientFound] = useState({});
    const [paymentTypes, setPaymentTypes] = useState([]);

    const [salesDocuments, setSalesDocuments] = useState([]);
    const [salesDocumentSelected, setSalesDocumentSelected] = useState(null);
    const [serieDocument, setSerieDocument] = useState('');
    const [numberDocument, setNumberDocument] = useState('');
    const [dateRegistration, setDateRegistration] = useState('');
    const [dateIssue, setDateIssue] = useState(null);
    const [dateExpiration, setDateExpiration] = useState(null);
    const [affectionValue, setAffectionValue] = useState(0);
    const [unaffectionValue, setUnaffectionValue] = useState(0);
    const [igvValue, setIgvValue] = useState(0);
    const [disabledButton, setDisabledButton] = useState(false);
    const [typeMoney, setTypeMoney] = useState('Soles');
    const [paymentType, setPaymentType] = useState('1');

    const token = getAccessTokenApi();
    const user = jwtDecode(token);
    const dateFormat = 'DD/MM/YYYY';
    const { Option } = Select;

    useEffect(() => {
        getSalesDocumentsTypeSaleApi(token, user.id)
            .then((response) => {
                if (response.salesdocuments) {
                    setSalesDocuments(response.salesdocuments);
                    for (let i = 0; i < response.salesdocuments.length; i++) {
                        if (response.salesdocuments[i].code === '1') {
                            setSalesDocumentSelected(response.salesdocuments[i]._id);
                        }
                    }
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });

        getAllPaymentTypesApi(token)
            .then((response) => {
                if (response.paymentTypes) {
                    setPaymentTypes(response.paymentTypes);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });

        const now = new Date();
        const dateRegistration = convertDate(now);
        setDateRegistration(dateRegistration);
    }, [token, user.id, reloadPurchase]);

    useEffect(() => {
        var saleValue = 0;
        for (let i = 0; i < products.length; i++) {
            saleValue = saleValue + products[i].subtotal;
        }

        setAffectionValue(saleValue / (1 + 0.18));
        setIgvValue(saleValue - saleValue / (1 + 0.18));
        setUnaffectionValue(saleValue);
    }, [products, reloadProducts]);

    useEffect(() => {
        if (clientFound._id) {
            getClientById(token, clientFound._id)
                .then((response) => {
                    if (response && response.client) {
                        setClientSelected(response.client);
                        if (response.client.client_type === '1') {
                            setTextSearchClient(response.client.first_name + ' ' + response.client.last_name);
                        } else if (response.client.client_type === '2') {
                            setTextSearchClient(response.client.entity_name);
                        }
                    }
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });
        }
    }, [token, clientFound]);

    useEffect(() => {
        if (clientSelected.client_type === '1') {
            setTextSearchClient(clientSelected.first_name + ' ' + clientSelected.last_name);
        } else if (clientSelected.client_type === '2') {
            setTextSearchClient(clientSelected.entity_name);
        }
    }, [clientSelected]);

    useEffect(() => {
        setClientSelected({});
        setTextSearchClient('');
        setSerieDocument('');
        setNumberDocument('');
        setDateIssue(null);
        setDateExpiration(null);
        setTypeMoney('Soles');
        setPaymentType('1');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadPurchase]);

    function registerPurchase() {
        if (!salesDocumentSelected) {
            notification['error']({ message: `No seleccionó Documento compra` });
            return;
        }
        if (products.length === 0) {
            notification['error']({ message: `No se agregó productos` });
            return;
        }
        if (user.stock_control) {
            if (!warehouse) {
                notification['error']({ message: `Debe seleccionar almacén de destino` });
                return;
            }
        }

        for (let i = 0; i < products.length; i++) {
            if (user.stock_control) {
                if (!products[i].unit_portion || products[i].unit_portion === 1) {
                    if (products[i].price_purchase_presentation <= 0 || products[i].price_sale_presentation <= 0) {
                        notification['error']({ message: `${products[i].name} tiene precio no válido` });
                        return;
                    }
                    if (products[i].quantity_presentation <= 0) {
                        notification['error']({ message: `${products[i].name} tiene cantidad no válida` });
                        return;
                    }
                } else {
                    if (
                        (products[i].price_purchase_presentation <= 0 &&
                            products[i].price_purchase_unit_portion <= 0) ||
                        (products[i].price_sale_presentation <= 0 && products[i].price_sale_unit_portion <= 0)
                    ) {
                        notification['error']({ message: `${products[i].name} tiene precio no válido` });
                        return;
                    }
                    if (products[i].quantity_presentation <= 0 && products[i].quantity_unit_portion <= 0) {
                        notification['error']({ message: `${products[i].name} tiene cantidad no válida` });
                        return;
                    }
                }
            } else {
                if (products[i].price_purchase <= 0 || products[i].price_sale <= 0) {
                    notification['error']({ message: `${products[i].name} tiene precio no válido` });
                    return;
                }
                if (products[i].quantity <= 0) {
                    notification['error']({ message: `${products[i].name} tiene cantidad no válida` });
                    return;
                }
            }
        }

        let paymentTypeDescription;
        for (let i = 0; i < paymentTypes.length; i++) {
            if (paymentTypes[i].identifier === parseInt(paymentType)) {
                paymentTypeDescription = paymentTypes[i].description_real;
                break;
            }
        }

        const purchase = {
            provider: clientSelected._id,
            warehouse: warehouse,
            observation: observation,
            sales_document: salesDocumentSelected,
            date_registration: dateRegistration,
            date_issue: dateIssue,
            date_expiration: dateExpiration,
            serie: serieDocument,
            number: numberDocument,
            type_money: typeMoney,
            type_pay: paymentTypeDescription,
            affection_value: affectionValue,
            unaffection_value: unaffectionValue,
            igv_value: igvValue,
            products: products,
        };

        setDisabledButton(true);

        registerPurchaseApi(token, purchase)
            .then((response) => {
                if (response.success) {
                    notification['success']({ message: response.message });
                    setReloadPurchase(true);
                } else if (response.warning) {
                    notification['warning']({ message: response.message });
                    setReloadPurchase(true);
                } else {
                    notification['error']({ message: response.message });
                }

                setDisabledButton(false);
            })
            .catch((err) => {
                setDisabledButton(false);
                notification['error']({ message: err });
            });
    }

    function onChangeSalesDocument(e) {
        setSalesDocumentSelected(e.target.value);
    }

    const addClientModal = () => {
        setIsVisibleModal(true);
        setModalTitle('Nuevo cliente');
        setModalContent(
            <AddClientForm
                setIsVisibleModal={setIsVisibleModal}
                setClientSelected={setClientSelected}
                textSearchClient={textSearchClient}
            />
        );
    };

    const editClientModal = () => {
        setIsVisibleModal(true);
        setModalTitle('Modifica cliente');
        setModalContent(
            <EditCLientForm
                setIsVisibleModal={setIsVisibleModal}
                clientSelected={clientSelected}
                setClientSelected={setClientSelected}
            />
        );
    };

    const onSelectClient = (data) => {
        setClientFound(
            clientsResultSearch.find(
                (client) =>
                    `${client.first_name} ${client.last_name} | ${
                        client.document_number ? client.document_number : ''
                    }` === data ||
                    `${client.entity_name} | ${client.document_number ? client.document_number : ''}` === data
            )
        );
        setOptionsClients([]);
    };

    const onFocusClientSearch = (e) => {
        e.target.select();
    };

    const onSearchClient = (text) => {
        setTextSearchClient(text);
        if (text.length > 1) {
            const resultSearch = [];
            if (onlyNumbers(text) && (text.length === 8 || text.length === 11)) {
                getClientsByNumberDocApi(token, text)
                    .then((response) => {
                        setClientsResultSearch(response.clients);
                        for (let i = 0; response.clients && i < response.clients.length; i++) {
                            if (response.clients[i].first_name) {
                                resultSearch.push({
                                    value: `${response.clients[i].first_name} ${response.clients[i].last_name} | ${
                                        response.clients[i].document_number ? response.clients[i].document_number : ''
                                    }`,
                                });
                            } else if (response.clients[i].entity_name) {
                                resultSearch.push({
                                    value: `${response.clients[i].entity_name} | ${
                                        response.clients[i].document_number ? response.clients[i].document_number : ''
                                    }`,
                                });
                            }
                        }
                        setOptionsClients(!text ? [] : resultSearch);
                    })
                    .catch((err) => {
                        notification['error']({ message: err });
                    });
            } else {
                getClientsByNameApi(token, text)
                    .then((response) => {
                        setClientsResultSearch(response.clients);
                        for (let i = 0; response.clients && i < response.clients.length; i++) {
                            if (response.clients[i].first_name) {
                                resultSearch.push({
                                    value: `${response.clients[i].first_name} ${response.clients[i].last_name} | ${
                                        response.clients[i].document_number ? response.clients[i].document_number : ''
                                    }`,
                                });
                            } else if (response.clients[i].entity_name) {
                                resultSearch.push({
                                    value: `${response.clients[i].entity_name} | ${
                                        response.clients[i].document_number ? response.clients[i].document_number : ''
                                    }`,
                                });
                            }
                        }
                        setOptionsClients(!text ? [] : resultSearch);
                    })
                    .catch((err) => {
                        notification['error']({ message: err });
                    });
            }
        } else {
            setOptionsClients([]);
        }
    };

    function onChangeSerieDocument(e) {
        const serie = e.target.value;

        if (serie.length > 4) {
            return;
        }

        setSerieDocument(serie.toUpperCase());
    }

    function onChangeNumberDocument(e) {
        const number = e.target.value;

        if (number.length > 8 || !onlyNumbers(number)) {
            return;
        }

        setNumberDocument(number);
    }

    function onChangePaymentType(e) {
        setPaymentType(e);
    }

    const childrenPaymentType = [];
    for (let i = 0; i < paymentTypes.length; i++) {
        childrenPaymentType.push(<Option key={paymentTypes[i].identifier}>{paymentTypes[i].description}</Option>);
    }

    const getValidDateFormat = (date) => {
        var newDate = moment(date, dateFormat);
        return date && newDate.isValid() ? newDate : '';
    };

    function convertDate(date) {
        var dateFormat = '';

        if (date.getDate() > 9) {
            dateFormat = dateFormat + date.getDate() + '/';
        } else {
            dateFormat = dateFormat + '0' + date.getDate() + '/';
        }
        if (date.getMonth() + 1 > 9) {
            dateFormat = dateFormat + (date.getMonth() + 1) + '/';
        } else {
            dateFormat = dateFormat + '0' + (date.getMonth() + 1) + '/';
        }
        dateFormat = dateFormat + date.getFullYear();

        /*dateFormat = dateFormat + ' ';
        if (date.getHours() > 9) {
            dateFormat = dateFormat + date.getHours() + ':';
        } else {
            dateFormat = dateFormat + '0' + date.getHours() + ':';
        }
        if (date.getMinutes() > 9) {
            dateFormat = dateFormat + date.getMinutes();
        } else {
            dateFormat = dateFormat + '0' + date.getMinutes();
        }*/

        return dateFormat;
    }

    function onlyNumbers(e) {
        const regex = /^[0-9]*$/;
        const r = regex.test(e);
        return r;
    }

    return (
        <div>
            <Space size={20} direction="vertical" style={{ width: '100%' }}>
                <div className="background_block ">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <h1>
                                <b>Detalle de compra</b>
                            </h1>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={21} sm={22} md={22} lg={21}>
                            <AutoComplete
                                prefix={<IdcardOutlined />}
                                options={optionsClients}
                                onSelect={onSelectClient}
                                onSearch={onSearchClient}
                                placeholder="Ingresar nombre, RUC o DNI de proveedor"
                                className="search_input"
                                onFocus={onFocusClientSearch}
                                value={textSearchClient}
                            />
                        </Col>
                        <Col xs={3} sm={2} md={2} lg={3}>
                            <Tooltip title="Nuevo cliente">
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<PlusOutlined />}
                                    onClick={addClientModal}
                                    style={{ float: 'right' }}
                                />
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={21} sm={22} md={22} lg={21}>
                            <Input
                                prefix={<FieldNumberOutlined />}
                                placeholder="Número documento"
                                value={clientSelected.document_number}
                                className="form-add_input"
                                disabled={true}
                            />
                        </Col>
                        <Col xs={3} sm={2} md={2} lg={3}>
                            <Tooltip title="Nuevo cliente">
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<EditOutlined />}
                                    onClick={editClientModal}
                                    style={{ float: 'right' }}
                                />
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Divider />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} className="sales_row-document">
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Radio.Group onChange={onChangeSalesDocument} value={salesDocumentSelected}>
                                <Row gutter={[16, 16]}>
                                    {salesDocuments.map((salesDocument, index) => (
                                        <Col key={salesDocument._id} xs={24} sm={24} md={24} lg={24}>
                                            <Radio key={salesDocument._id} value={salesDocument._id}>
                                                {salesDocument.name}
                                            </Radio>
                                        </Col>
                                    ))}
                                </Row>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row gutter={[24, 24]}>
                        <Space size={10} direction="vertical">
                            <div></div>
                            <div></div>
                        </Space>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={8} sm={8} md={8} lg={8}>
                            <Input
                                prefix={<LaptopOutlined />}
                                placeholder="Serie"
                                value={serieDocument}
                                onChange={onChangeSerieDocument}
                                className="form-add_number"
                            />
                        </Col>
                        <Col xs={16} sm={16} md={16} lg={16}>
                            <Input
                                prefix={<FieldNumberOutlined />}
                                placeholder="Número documento"
                                value={numberDocument}
                                onChange={onChangeNumberDocument}
                                className="form-add_number"
                            />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Divider />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={8} sm={8} md={8} lg={8}>
                            {'Fecha registro:'}
                        </Col>
                        <Col xs={16} sm={16} md={16} lg={16}>
                            <div style={{ textAlign: 'right', fontSize: 'large' }}>{dateRegistration}</div>
                        </Col>
                    </Row>
                    <Row gutter={[24, 24]}>
                        <Space size={5} direction="vertical">
                            <div></div>
                            <div></div>
                        </Space>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={8} sm={8} md={8} lg={8}>
                            {'Fecha emisión:'}
                        </Col>
                        <Col xs={16} sm={16} md={16} lg={16}>
                            <div style={{ textAlign: 'right' }}>
                                <DatePicker
                                    placeholder="Seleccionar fecha emisión"
                                    format={dateFormat}
                                    value={getValidDateFormat(dateIssue)}
                                    onChange={(e) => setDateIssue(e.format('DD/MM/YYYY'))}
                                    style={{ textAlign: 'right' }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[24, 24]}>
                        <Space size={5} direction="vertical">
                            <div></div>
                            <div></div>
                        </Space>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={8} sm={8} md={8} lg={8}>
                            {'Fecha vencimiento:'}
                        </Col>
                        <Col xs={16} sm={16} md={16} lg={16}>
                            <div style={{ textAlign: 'right' }}>
                                <DatePicker
                                    placeholder="Seleccionar fecha vencimiento"
                                    format={dateFormat}
                                    value={getValidDateFormat(dateExpiration)}
                                    onChange={(e) => setDateExpiration(e.format('DD/MM/YYYY'))}
                                    style={{ textAlign: 'right' }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[24, 24]}>
                        <Space size={5} direction="vertical">
                            <div></div>
                            <div></div>
                        </Space>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={8} sm={8} md={8} lg={8}>
                            {'Forma de pago:'}
                        </Col>
                        <Col xs={16} sm={16} md={16} lg={16}>
                            <div style={{ textAlign: 'right' }}>
                                <Select
                                    placeholder="Forma de pago"
                                    onChange={onChangePaymentType}
                                    value={paymentType}
                                    style={{width: '130px'}}
                                    className="form-add_select"
                                >
                                    {childrenPaymentType}
                                </Select>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[24, 24]}>
                        <Space size={10} direction="vertical">
                            <div></div>
                            <div></div>
                        </Space>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={2} sm={2} md={2} lg={2}></Col>
                        <Col xs={22} sm={22} md={22} lg={22}>
                            <Radio.Group
                                value={typeMoney}
                                onChange={(e) => setTypeMoney(e.target.value)}
                                style={{ float: 'right' }}
                            >
                                <Radio key={'Soles'} value={'Soles'}>
                                    {'Soles'}
                                </Radio>
                                <Radio key={'Dólares'} value={'Dólares'}>
                                    {'Dólares'}
                                </Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Divider />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={8} sm={8} md={8} lg={8}>
                            <div style={{ textAlign: 'right', fontSize: 'large' }}>Valor venta</div>
                        </Col>
                        <Col xs={16} sm={16} md={16} lg={16}>
                            <div style={{ textAlign: 'right', fontSize: 'large' }}>{affectionValue.toFixed(2)}</div>
                        </Col>
                    </Row>
                    <Row gutter={[24, 24]}>
                        <Space size={10} direction="vertical">
                            <div></div>
                            <div></div>
                        </Space>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={8} sm={8} md={8} lg={8}>
                            <div style={{ textAlign: 'right', fontSize: 'large' }}>IGV (18%)</div>
                        </Col>
                        <Col xs={16} sm={16} md={16} lg={16}>
                            <div style={{ textAlign: 'right', fontSize: 'large' }}>{igvValue.toFixed(2)}</div>
                        </Col>
                    </Row>
                    <Row gutter={[24, 24]}>
                        <Space size={10} direction="vertical">
                            <div></div>
                            <div></div>
                        </Space>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={8} sm={8} md={8} lg={8}>
                            <div style={{ textAlign: 'right', fontSize: 'large' }}>Total</div>
                        </Col>
                        <Col xs={16} sm={16} md={16} lg={16}>
                            <div style={{ textAlign: 'right', fontSize: 'x-large' }}>{unaffectionValue.toFixed(2)}</div>
                        </Col>
                    </Row>
                </div>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Button
                            type="primary"
                            shape="round"
                            size="large"
                            onClick={registerPurchase}
                            disabled={disabledButton}
                            className="btn-submit"
                        >
                            Registrar compra
                        </Button>
                    </Col>
                </Row>
            </Space>
        </div>
    );
}
